import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import Cookies from "js-cookie";
import { IoLogOut, IoSettings } from "react-icons/io5"
import Modal from 'react-modal';
import { useAtom } from "jotai";
import projectAtom, { fetchProjectAtom } from "../atoms/projectAtom";
import userAtom from "../atoms/userAtom";
import themeAtom from "../atoms/themeAtom";
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import ProjectSelect from "./projectSelectBox";
import project_orgAtom, { fetchProjOrgAtom } from '../atoms/permissionAtom'
import { startCase } from "lodash";
import { mainNavBar } from "./constent";
import { checkHasRevisedProjectId } from "./shared/checkHasRevisedProjectId";

function Tab({ name, selected, logoDefault, logoClicked, to, flag }) {
    return (
        <NavLink to={to}>
            <div className={`float-left flex flex-col items-center cursor-pointer py-6 `}>
                <div className="flex">
                    {selected ?
                        <p className="mt-1 text-red-550">{logoClicked}</p> :
                        <p className="mt-1">{logoDefault}</p>
                    }
                    <p className={`font-medium text-base text-center ml-2 ${selected ? "text-red-550" : "test-black"}`}>{name}</p>
                </div>
                <div className={`h-2 rounded-full w-36 absolute ${selected ? "bg-red-550" : "border-r-solid border-r-gray-100"}`} style={{ marginTop: "41px" }}></div>
            </div>
        </NavLink>
    )

}

function Container(props) {

    const navigate = useNavigate()
    const location = useLocation();

    const [projectId, setProjectId] = useState(parseInt(localStorage.getItem("project_id")))
    const [navigatePath, setNavigatePath] = useState(mainNavBar)

    const [listProjects] = useAtom(projectAtom)
    const [theme] = useAtom(themeAtom)
    const [, fetchProjects] = useAtom(fetchProjectAtom)
    const [listUser] = useAtom(userAtom)
    const [getPermission] = useAtom(project_orgAtom)
    const [, fetchProjOrgPermission] = useAtom(fetchProjOrgAtom)
    const [loading, setLoading] = useState(true)
    const permission_reference = {
        "Dashboard": "Dashboard",
        "Setup": "mech-Construction Progress",
        "Reports": "Reports",
        "Bill Of Materials": "mech-Inventory management",
        "Quality": "mech-Quality Check",
        "Staff And Plant": "general-Staff and Plant",
        "Access Rights": "general-Edit access rights"
    }


    useEffect(() => {
        if (listProjects?.length < 1) {
            init()
        }
    }, [projectId, theme?.theme])

    const init_now = async () => {
        await fetchProjOrgPermission().then(() => setLoading(false))
    }

    useEffect(() => {
        init_now()
        tabsFlagCheck()
    }, [])

    function qcFlag() {
        return currentProj?.project_settings?.qc
    }

    function staffFlag() {
        return currentProj?.project_settings?.staff
    }

    function tabsFlagCheck() {
        let temp = mainNavBar
        if (!qcFlag()) {
            temp = temp.filter(o => o.key !== 3)
        }
        if (!staffFlag()) {
            temp = temp.filter(o => o.key !== 5)
        }
        setNavigatePath(temp)
    }

    const init = async () => {
        try {
            await fetchProjects()
        }
        catch (err) {
            console.log(err)
        }
    }

    const currentProj = listProjects.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    useEffect(() => {
        const fetchProjectId = async () => {
            if (listProjects) {
                const currentProjectDetails = listProjects.find(
                    (p) => p.id === parseInt(localStorage.getItem("project_id"))
                );
                if (currentProjectDetails) {
                    const projectId = await checkHasRevisedProjectId(currentProjectDetails);
                    setProjectId(projectId);
                    localStorage.setItem("project_id",projectId)
                }
            }
        };

        fetchProjectId();
    }, [listProjects,projectId]);



    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const afterOpenModal = () => {
        subtitle.style.color = '#f00'
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px",
            zIndex: 999,
        },
    };

    const logout = () => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        localStorage.clear()
        window.location.href = "/login"
    }

    const handleChangeProject = (e) => {
        navigate("/dashboard")
        localStorage.setItem("project_id", e);
        setProjectId(e);
        localStorage.setItem("selected-activity", "All")
        localStorage.removeItem("cp-checklist");
        localStorage.removeItem("qc-checklist")
        window.location.reload();
    }

    return (
        <div className="w-full h-full">
            <div className="w-full h-[100%] flex flex-col">
                <div className="flex justify-between border h-[9%]">
                    <div className="flex">
                        <div className="flex justify-center py-4 ml-5">
                            <img src="/suryalogo.svg" alt="" style={{ width: '175px' }} />
                        </div>
                        <div className="flex gap-12 px-4 pl-12">
                            {!(localStorage.getItem("project_id")) ? null : loading ?
                                <p className='w-4 h-4 b-3 loader' style={{ width: "25px", height: "25px", margin: "20px" }}></p> :
                                mainNavBar.map((item, index) => (
                                    !getPermission[permission_reference[item?.title]] ? null :
                                        <Tab
                                            key={index}
                                            name={item?.title}
                                            to={item.path}
                                            logoDefault={item?.default_icon}
                                            logoClicked={item?.icon}
                                            selected={startCase((location.pathname).split('/')?.[1]) === item?.title} />
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex space-x-5 justify-end items-center mr-4">
                        {localStorage.getItem("project_id") ?
                            <ProjectSelect placeholder="Select a project" onChange={e => handleChangeProject(e)} value={listProjects} />
                            :
                            null
                        }
                        <div className="dropdown dropdown-bottom dropdown-end mr-10 ml-6 ">
                            <label tabIndex={0} className="cursor-pointer">
                                <div className="avatar placeholder">
                                    <div className=" bg-red-550 text-neutral-content rounded-full w-10">
                                        <span className="text-base font-semibold">{listUser?.data?.profile_data?.first_name.charAt(0).toUpperCase()}</span>
                                    </div>
                                </div>
                            </label>
                            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-40">
                                <li onClick={() => navigate("/settings")}><a className="flex active:bg-red-500">
                                    <IoSettings className="text-lg" /><p>Settings</p></a></li>
                                <li onClick={openModal}><a className="flex active:bg-red-500">
                                    <IoLogOut className="text-lg rotate-180" /><p>Log out</p></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {localStorage.getItem("project_id") ?
                    <div className="w-[100%]">
                        {props.children}
                    </div>
                    :
                    <div className="m-auto flex flex-col space-y-5 justify-center" style={{ marginTop: "18%" }}>
                        <p className="text-xl ml-2 font-bold">Choose a project</p>
                        <ProjectSelect placeholder={"No project Selected"} onChange={e => handleChangeProject(e)} value={listProjects} />
                    </div>
                }
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Logout Modal"
            >
                <p className="text-lg font-bold my-2 mb-8 pr-24">Are you sure you want to logout ?</p>
                <div className="flex justify-end mt-4">
                    <button onClick={closeModal} className="border rounded-3xl mr-4 btn btn-outline text-gray-500" style={{ width: '100px' }}>Cancel</button>
                    <button onClick={logout} className='btn bg-red-550 border-none rounded-3xl'>Yes, Sure</button>
                </div>
            </Modal>
        </div>
    )
}

export default Container

{/* <div className="flex h-screen w-screen">
                    <div>
                        <div className={`${theme.theme === 'light' ? "bg-gray-100" : "border-r-px border-r-solid border-r-gray-100"} flex justify-center`} style={{ width: "250px", height: '80px' }}>
                            <img src="/suryalogo.svg" alt="" style={{ width: '175px' }} />
                        </div>
                        <div className={`${theme.theme === 'light' ? "bg-gray-100" : "border-r-px border-r-solid border-r-gray-100"}`} style={{ width: "250px", height: "calc(100vh - 80px)" }}>
                            <div className="flex ml-10">
                                <img src="/projects.svg" alt="" height="40px" width="40px" />
                                <h3 className="ml-2 font-bold text-xl">Projects</h3>
                            </div>
                            <ul className="mt-5">
                                <ProjectSelect onChange={e => {localStorage.setItem("project_id", e);setProjectId(e); window.location.reload(); localStorage.removeItem("cp-checklist"); localStorage.removeItem("qc-checklist")}} value={listProjects} />
                            </ul>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-between">
                            <div className="flex overflow-y-auto" style={{width: "calc(100vw - 250px - 250px)"}}>
                                <ul>
                                    <Tab name="Dashboard" to="/dashboard" logoDefault={<TiChartLineOutline />} logoClicked={<TiChartLine />} selected={location.pathname === "/dashboard" || location.pathname === `/dashboard/summary/${params.id}` ? true : false} />
                                    <Tab name="Set Up" to="/setup" logoDefault={<RiDatabase2Line />} logoClicked={<RiDatabase2Fill />} selected={location.pathname === "/setup" || location.pathname === "/setup/ConstructionProgress" || location.pathname === `/setup/ConstructionProgress/${params.id}` || location.pathname === "/setup/QCChecklist" || location.pathname === `/setup/QCChecklist/${params.id}` || location.pathname === "/setup/billOfMaterials" ? true : false} />
                                    <Tab name="Staff & Plant" to="/staff&plant" logoDefault={<FaRegUser />} logoClicked={<FaUser />} selected={location.pathname === "/staff" || location.pathname === "/staff&plant" || location.pathname === "/staff&plant/staffList" || location.pathname === "/staff&plant/workHour" || location.pathname === "/staff&plant/workHour/activity" || location.pathname === "/staff&plant/plantList" ? true : false} />
                                    <Tab name="Reports" to="/reports" logoDefault={<HiOutlineDocumentText />} logoClicked={<HiDocumentText />} selected={location.pathname === "/reports" || location.pathname === "/reports/summary" || location.pathname === "/reports/summary/constructionProgress" || location.pathname === "/reports/summary/qualityCheck" || location.pathname === "/reports/summary/workHours" || location.pathname === "/reports/initialStats" || location.pathname === "/reports/QCPunchlist" || location.pathname === "/reports/logsandchecklist" ? true : false} />
                                    <Tab name="Access Rights" to="/accessRights" logoDefault={<HiOutlineArrowRightOnRectangle />} logoClicked={<HiOutlineArrowRightOnRectangle />} selected={location.pathname === "/accessRights" ? true : false} flag={currentProj?.project_settings} />
                                </ul>
                            </div>
                            <div className="flex justify-end items-center">
                                <div className="dropdown dropdown-bottom dropdown-end mr-10 ml-6 ">
                                    <label tabIndex={0} className="cursor-pointer">
                                    <div className="avatar placeholder">
                                        <div className=" bg-red-550 text-neutral-content rounded-full w-10">
                                            <span className="text-base font-semibold">{listUser?.data?.profile_data?.first_name.charAt(0).toUpperCase()}</span> 
                                        </div>
                                    </div>
                                    </label>
                                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-40">
                                        <li onClick={() => navigate("/ProfileSettings")}><a className="flex active:bg-red-500">
                                            <BsPersonFill className="text-lg"/><p>Profile</p></a></li>
                                        <li onClick={openModal}><a className="flex active:bg-red-500">
                                            <IoLogOut className="text-lg rotate-180" /><p>Log out</p></a></li>
                                    </ul>
                                    <Modal
                                        isOpen={modalIsOpen}
                                        onAfterOpen={afterOpenModal}
                                        onRequestClose={closeModal}
                                        style={customStyles}
                                        contentLabel="Logout Modal"
                                    >
                                        <p className="text-lg font-bold my-2 mb-8 pr-24">Are you sure you want to logout ?</p>
                                        <div className="flex justify-end mt-4">
                                            <button onClick={closeModal} className="border rounded-3xl mr-4 btn btn-outline text-gray-500" style={{ width: '100px' }}>Cancel</button>
                                            <button onClick={logout} className='btn bg-red-550 border-none rounded-3xl'>Yes, Sure</button>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        {localStorage.getItem("project_id") ? 
                            <div style={{ height: "calc(100vh - 90px)", width: "calc(100vw - 250px)" }}>{props.children}</div>
                        :
                            <div className="m-auto flex justify-center font-semibold text-gray-400" style={{marginTop: "25%"}}>Please select a project</div>
                        }
                    </div>
                </div> */}