import axios from "axios";
import { atom } from "jotai";
import produce from "immer";

const punchListAtom = atom({
    data: [],
    details: [],
    photos: []
})

const handleImage = async (data) => {
    try {
        let res = await axios.get(`/file_upload/download?id=${data}`)
        return res?.data
    }
    catch (err) {
        console.log(err)
        return ""
    }
}

export const fetchPunchListAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/get_qc_punclist_internal_and_external`)

        let tempObj = []
        let resIndex = 0
        for (let blkId in res.data) {
            for (let type in res.data[blkId]) {
                for (let ids in res.data[blkId][type]) {
                    resIndex = resIndex + 1
                    if (ids.split("_").length === 3) {
                        res.data[blkId][type][ids]["checklistType"] = 1
                    }
                    else if (ids.split("_").length === 2) {
                        res.data[blkId][type][ids]["checklistType"] = 0
                    }
                    let pics = []
                    if (args?.loadImages && resIndex < 11) {
                        for (let key of res.data[blkId][type][ids]?.document) {
                            let tempRes = await handleImage(key)
                            pics.push(tempRes)
                        }
                    }
                    res.data[blkId][type][ids]["photos"] = pics
                    tempObj.push(res.data[blkId][type][ids])
                }
            }
        }
        // for(let data in res?.data){
        //     let pics = []
        //     for(let key of res?.data[data][punchlistType]?.QCProgress?.photos){
        //         let tempRes = await handleImage(key?.upload_id)
        //         pics.push(tempRes)
        //     }
        //     res.data[data]["photos"] = pics
        //     temp.push(res?.data[data])
        // }
        // temp.map((i) => {
        //     if(i?.QCProgress?.visibility === args?.visibility){
        //         finalTemp.push(i)
        //     }
        // })
        set(punchListAtom, produce((draft) => { draft.data = tempObj }))
    }
)

export const fetchQcDataAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/progress/get_qc_punchlist_kv`)
        ///progress/v2/get_qc_punchlist_kv?visibility=
        set(punchListAtom, produce((draft) => { draft.details = res.data }))
    }
)

export const movePunchListAtom = atom(
    null,
    async (get, set, args) => {
        for (let i of args.data.data) {
            await axios.patch(`/progress/update_qc_progress?block_id=${i.block_id}`, { data: [i] })
        }
        let res = await axios.get(`/progress/v2/get_qc_punchlist_kv?visibility=${args.visibility}`)
        let temp = []

        for (let data in res?.data) {
            let pics = []
            for (let key of res?.data[data]?.QCProgress?.photos) {
                let tempRes = await handleImage(key?.upload_id)
                pics.push(tempRes)
            }
            res.data[data]["photos"] = pics
            temp.push(res?.data[data])
        }
        let finalTemp = []
        temp.map((i) => {
            if (i?.QCProgress?.visibility === args?.visibility) {
                finalTemp.push(i)
            }
        })
        set(punchListAtom, produce((draft) => { draft.data = finalTemp }))
    }
)

export const deletePunchlistAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/progress/v2/delete_qc_punchlist?visibility=${args.visibility}`, args.id)
        let res = await axios.get(`/progress/v2/get_qc_punchlist_kv?visibility=${args.visibility}`)
        let temp = []
        for (let data in res?.data) {
            let pics = []
            for (let key of res?.data[data]?.QCProgress?.photos) {
                let tempRes = await handleImage(key?.upload_id)
                pics.push(tempRes)
            }
            res.data[data]["photos"] = pics
            temp.push(res?.data[data])
        }
        let finalTemp = []
        temp.map((i) => {
            if (i?.QCProgress?.visibility === args?.visibility) {
                finalTemp.push(i)
            }
        })
        set(punchListAtom, produce((draft) => { draft.data = finalTemp }))
    }
)

export const fetchQcPhotos = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/public/images/${args?.project_id}/${args?.id}`)
        // let pics = []
        // for(let data of res.data){
        //     let temp = await handleImage(data)
        //     pics.push(temp)
        // }
        set(punchListAtom, produce((draft) => { draft.photos = res.data }))
    }
)

export default punchListAtom
