import React, { useEffect, useState } from 'react'
import SuryaBlockMap from '../block'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import dashboardAtom, { fetchBulkDashboardDataGet, fetchListSections, fetchMapSize, scopeSelectionGlobal } from '../../../atoms/dashboardAtom'
import { BiArrowBack } from 'react-icons/bi'
import produce from 'immer'

function ScopeD3({
    listLaydown,
    currentLaydown,
    currentProject,
    handleBackBlockList,
    getBlockDetails,
    currentBlock,
    setSelectBlockTrackers,
    setIndividualBlockData,
    selectBlockTrackers,
    clearSt,
    currentProjectData,
    setViewSelectedTrackers,
    handleSelectAllBlocks,
    handleClearAllBlock,
    selectAllTrackers,
    scopeEditMode,
    finalData,
    setFinalData,
    finalDataRef,
    selectedRow,
    setSelectedRow,
    prevSection,
    setPrevSection,
    sectionSelect,
    setSectionSelect
}) {

    const params = useParams()

    const [loading, setLoading] = useState(false)
    const [d3xScaleyScale, setD3xScaleyScale] = useState({})

    const [listblocks] = useAtom(dashboardAtom)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
    const [, setSectionList] = useAtom(fetchListSections)

    useEffect(() => {
        init()
    }, [params?.block_id])

    useEffect(() => {
        if (listblocks.blockTtData && params?.block_id && currentLaydown) {
            sectionCallFn()
        }
    }, [listblocks.blockTtData, params?.block_id, currentLaydown])

    useEffect(() => {
        if (listblocks?.mapSize) {
            const d3xScaleyScaleHasNullValue = hasNullValues(listblocks?.mapSize)
            if (d3xScaleyScaleHasNullValue === true) {
                if (listblocks?.blockTtData) {
                    const piersMinMax = findMinMax(listblocks?.blockTtData?.piers);
                    const ttMinMax = findMinMax(listblocks?.blockTtData?.tt);
                    setD3xScaleyScale({ piers: piersMinMax, tt: ttMinMax })
                }
            }
            else {
                setD3xScaleyScale({ piers: listblocks.mapSize?.piers, tt: listblocks.mapSize?.tt })
            }
        }
    }, [listblocks?.mapSize])

    const init = async () => {
        try {
            setLoading(true)
            setFetchBulkGet({ id: params?.block_id })
            setMapSize({ id: params?.block_id })
            setSectionList()
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const sectionCallFn = async () => {
        try {
            if (params?.block_id) {
                setSectionSelect([])
                await setSectionList({ data: listblocks.blockTtData.piers })
                let temp = new Set()
                let tempSectionList = new Set()
                let laydownIncludesTracker = new Set()
                let trackerData = {}
                let initLoadSelectedTrackers = []
                let initLoadSelectedTrackers_ids = []
                for (let pr in listblocks?.blockTtData?.piers) {
                    for (let pierIndex in listblocks?.blockTtData?.piers[pr]) {
                        let pierData = listblocks?.blockTtData?.piers[pr][pierIndex]
                        tempSectionList.add(pierData.section)
                        if (pierData.laydown_id === currentLaydown) {
                            initLoadSelectedTrackers.push(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                            initLoadSelectedTrackers_ids.push(pr)
                        }
                        if (pierData.laydown_id === null || pierData.laydown_id === currentLaydown) {
                            if (trackerData[pierData.section]) {
                                let obj = trackerData[pierData.section]
                                obj.push(pr)
                                trackerData[pierData.section] = [...new Set(obj)]
                            }
                            else {
                                trackerData[pierData.section] = [pr]
                            }
                        }
                        else {
                            trackerData[pierData.section] = []
                        }
                        temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        if (pierData?.laydown_id === currentLaydown) {
                            laydownIncludesTracker.add(pierData)
                        }
                    }
                }
                let finalTemp = finalData
                finalTemp[parseInt(params?.block_id)] = finalDataRef[parseInt(params?.block_id)]?.length ? finalDataRef[parseInt(params?.block_id)] : initLoadSelectedTrackers_ids
                let tempSelectBlockTrackers = selectBlockTrackers
                tempSelectBlockTrackers[parseInt(params?.block_id)] = initLoadSelectedTrackers_ids
                setSelectBlockTrackers(tempSelectBlockTrackers);
                setFinalData(finalTemp)
                finalDataRef = finalTemp
                setIndividualBlockData(produce((draft) => {
                    draft.trackerData = trackerData;
                    draft.initLoadTracker = [...new Set(initLoadSelectedTrackers)]
                }))
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    console.log(params)
    console.log(finalData)

    //find funct
    function handleCheckZimmerman() {
        if (currentProject && currentProject?.type) {
            return true
        }
        else {
            return false
        }
    }

    function findMinMax(nestedData) {
        const allCoords = [];

        // Flattening the nested data
        Object.values(nestedData).forEach(coordsArray => {
            coordsArray.forEach(coord => {
                allCoords.push(coord);
            });
        });

        const latitudes = allCoords.map(coord => coord.latitude);
        const longitudes = allCoords.map(coord => coord.longitude);

        return {
            minimum_latitude: Math.min(...latitudes),
            maximum_latitude: Math.max(...latitudes),
            minimum_longitude: Math.min(...longitudes),
            maximum_longitude: Math.max(...longitudes)
        };
    }

    function hasNullValues(data) {
        if (data) {
            const values = [
                data?.piers?.minimum_latitude,
                data?.piers?.maximum_latitude,
                data?.piers?.minimum_longitude,
                data?.piers?.maximum_longitude,
                data?.tt?.minimum_latitude,
                data?.tt?.maximum_latitude,
                data?.tt?.minimum_longitude,
                data?.tt?.maximum_longitude
            ];

            return values.some(value => value === null);
        }
    }

    //onclick function 
    const handleSectionSelect = (data) => {
        if (sectionSelect.includes(data)) {
            setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
            setPrevSection(data)
        }
        else {
            setSectionSelect(produce((draft) => { draft.push(data) }))
            setPrevSection(null)
        }
    }

    return (
        <div>
            {loading ?
                <p>Loading...</p>
                :
                <div>
                    <div className='flex justify-between py-2'>
                        <div className='flex space-x-4 py-2 pl-5 items-center'>
                            <BiArrowBack className='cursor-pointer' onClick={handleBackBlockList} />
                            <p className='text-base font-medium'>{getBlockDetails(parseInt(params?.block_id))?.name}</p>
                        </div>
                        <div className='flex space-x-4 px-4'>
                            <div className=''>
                                <p className='text-gray-400 text-sm font-semibold ml-1'>{`Section(s)`}</p>
                                <div className='flex flex-wrap justify-left text-center max-w-[500px] overflow-y-auto'>
                                    {listblocks?.sectionList.map((section) => {
                                        return (
                                            <button className={`${scopeEditMode && "cursor-pointer"} m-1 w-10 py-1 text-xs ${sectionSelect?.includes(section) ? "bg-red-550 text-white border-red-550 border" : "text-red-550 border"}`} disabled={!scopeEditMode} onClick={() => handleSectionSelect(section)}>{section}</button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='flex mt-5 ml-2 mr-1'>
                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={() => setViewSelectedTrackers({ bool: true, mode: "tracker", expandedBlock: [], blockData: {} })}>View Selected scope</p>
                            </div>
                            <div className='flex mt-5 ml-2 mr-1'>
                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleSelectAllBlocks}>Select All</button>
                            </div>
                            <div className='flex mt-5 ml-2 mr-1'>
                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleClearAllBlock}>Clear Selection</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <SuryaBlockMap
                        zimmerMan={handleCheckZimmerman()}
                        deviceType="web"
                        loader={true}
                        bulkAllData={listblocks?.blockTtData}
                        pierLegends={listblocks?.pierLegends}
                        type="CP"
                        typeData={listblocks.cpTrackerRowWise}
                        userSelection={{ showType: "pier", selection: "None Selected" }}
                        scopeSelection={true}
                        showLaydownNameHover={true}
                        laydownData={listLaydown?.data}
                        currentLaydownData={currentLaydown}
                        initialLoadSelectedLaydownTracker={true}
                        currentBlock={currentBlock}
                        currentProjectData={currentProjectData}
                        disableSelection={!scopeEditMode}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        showLegend={false}
                        mapSize={d3xScaleyScale}
                        selectAll={selectAllTrackers}
                        section={sectionSelect}
                        prevSection={prevSection}
                        clearSelection={clearSt}
                        controls={true}
                    />
                </div>
            }
        </div>
    )
}

export default ScopeD3
