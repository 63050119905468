import React, { useCallback, useEffect, useRef, useState } from 'react'
import ViewByDropDown from '../components/viewByDropDown';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdModeEditOutline } from 'react-icons/md'
import toast from 'react-hot-toast';
import { BiArrowBack } from 'react-icons/bi';
import Modal from 'react-modal';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import dashboardAtom, { createUpdateScopeDataAtom, fetchBlocks, fetchBlockWiseTrackerCount, fetchBulkDashboardDataGet, fetchD3Data, fetchLaydownScopeSelection, fetchListSections, fetchMapRender, fetchMapSize, fetchTrackerName, scopeSelectionGlobal } from '../../../atoms/dashboardAtom';
import { useAtom } from 'jotai';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import produce from 'immer';
import { fetchGetLayout, getAllLaydownDataAtom } from '../../../atoms/layoutAtom';
import { VscClose } from 'react-icons/vsc';
import { BsChevronDown, BsChevronUp, BsFillFileEarmarkImageFill } from 'react-icons/bs';
import SuryaBlockMap from '../block';

const useLatest = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

function ScopeLaydown({
    listLaydown,
    currentLaydown,
    showRightPanel,
    currentProject,
    viewMode,
    viewToggle,
    setShowRightPanel,
    setScopeEditModeBool,
    handleChangeView
}) {

    const navigate = useNavigate()
    const _ = require("lodash")
    const params = useParams()

    const [loading, setLoading] = useState(false)
    const [scopeEditMode, setScopeEditMode] = useState(false)
    const [discardScope, setDiscardScope] = useState(false)
    const [saveScopeModal, setSaveScopeModal] = useState(false)
    const [saveScopeLoader, setSaveScopeLoader] = useState(false)
    const [selectBlockTrackers, setSelectBlockTrackers] = useState({})
    const [viewSelectedTrackers, setViewSelectedTrackers] = useState({ bool: false, mode: null, expandedBlock: [], blockData: {} })
    const [editInventoryNameModal, setEditInventoryNameModal] = useState({ boolean: false, data: {} })
    const [individualBlockData, setIndividualBlockData] = useState(
        {
            bool: params?.block_id ? true : false,
            block_id: parseInt(params?.block_id),
            loadAllTrackerSectionWise: {},
            loadSelectedTrackerSectionWise: {},
            loadOtherLaydownTrackerSectionWise: {},
            initLoadTracker: [],
            initLoadTracker_ids: []
        }
    )
    const [selectAllTrackers, setSelectAllTracker] = useState(false)
    const [viewSelectedScopeLoader, setViewSelectedScopeLoader] = useState(false)
    const [clearSt, setClearSt] = useState(false)
    const [finalData, setFinalData] = useState({})
    const [showScopeTracker, setShowScopeTracker] = useState("Block")
    const [selectedBlocks, setSelectedBlocks] = useState({ bool: true, data: [], initSelectedBlocks: {} })
    const [showSectionList, setShowSectionList] = useState([])
    const [d3xScaleyScale, setD3xScaleyScale] = useState({})
    const [sectionSelect, setSectionSelect] = useState([])
    const [disableSection, setDisableSection] = useState([])
    const [highlightSection, setHighlightSection] = useState([])
    const [prevSection, setPrevSection] = useState(null)
    const [selectedRow, setSelectedRow] = useState([])

    const scopeEditModeRef = useLatest(scopeEditMode)
    const selectedBlockIdsRef = useLatest(selectedBlocks?.data)
    const selectedRowRef = useLatest(selectedRow)
    const finalDataRef = useLatest(finalData)
    const viewToggleRef = useRef(viewToggle)

    const [listblocks] = useAtom(dashboardAtom)
    const [, setFetchLaydown] = useAtom(fetchGetLayout)
    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setFetchD3Data] = useAtom(fetchD3Data)
    const [, setFetchTrackerList] = useAtom(fetchTrackerName)
    const [, setSectionList] = useAtom(fetchListSections)
    const [, setGetAllScopeLaydownData] = useAtom(getAllLaydownDataAtom)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [, setFetchScopeSelection] = useAtom(fetchLaydownScopeSelection)
    const [, setUpdateCreateScopeTrackers] = useAtom(createUpdateScopeDataAtom)
    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setFetchBlockTrackerDatas] = useAtom(fetchBlockWiseTrackerCount)
    const [, setFetchGlobalScopeData] = useAtom(scopeSelectionGlobal)

    useEffect(() => {
        init()
    }, [currentLaydown])

    useEffect(() => {
        if (currentLaydown) {
            setFinalData({ ...listLaydown?.allScopeBlockData?.[currentLaydown] })
            finalDataRef.current = { ...listLaydown?.allScopeBlockData?.[currentLaydown] }
            let currentSelectedItems = { ...listLaydown?.allScopeBlockData?.[currentLaydown] }
            let currentLaydownCompletedBlocks = []
            if (Object.keys(currentSelectedItems).length) {
                Object.keys(currentSelectedItems).map((block_id) => {
                    if (currentSelectedItems[block_id] === -1) {
                        currentLaydownCompletedBlocks.push(parseInt(block_id))
                    }
                })
            }
            console.log("currentLaydownCompletedBlocks", currentLaydownCompletedBlocks)
            selectedBlockIdsRef.current = currentLaydownCompletedBlocks
        }
    }, [currentLaydown, listLaydown?.allScopeBlockData])

    useEffect(() => {
        if (individualBlockData?.bool) {
            handleLoadD3Data()
        }
    }, [individualBlockData?.block_id])

    useEffect(() => {
        let tempObj = finalData
        console.log(selectedRowRef.current)
        if (individualBlockData?.block_id) {
            tempObj[individualBlockData?.block_id] = selectedRowRef.current?.map(item => item?.split('-')?.[1]);
        }
        console.log(tempObj)
        setFinalData(tempObj)
        finalDataRef.current = tempObj
        let temp = selectBlockTrackers
        let tempTrackers = []
        let tempSectionSelect = {}
        selectedRowRef.current?.map((data) => {
            tempTrackers.push(data.split("-")[1])
            if (tempSectionSelect[data?.split("-")[4]]) {
                let obj = tempSectionSelect[data?.split("-")[4]]
                obj.push(data)
                tempSectionSelect[data?.split("-")[4]] = [...new Set(obj)]
            }
            else {
                tempSectionSelect[data?.split("-")[4]] = [data]
            }
        })
        temp[individualBlockData?.block_id] = tempTrackers
        setSelectBlockTrackers(temp)
        handleFindInitSectionHighlight(tempSectionSelect)
    }, [selectedRow])

    useEffect(() => {
        if (listblocks.blockTtData && individualBlockData?.block_id && currentLaydown) {
            sectionCallFn()
        }
    }, [listblocks.blockTtData, individualBlockData?.block_id, currentLaydown])

    useEffect(() => {
        let blockId = listblocks?.d3Data?.block_id
        let tempData = new Set()
        if (blockId && getSelectedScopes()[blockId]?.length) {
            getSelectedScopes()[blockId].map((tracker_id) => {
                let temp = listblocks?.d3Data?.data?.piers[tracker_id][0]
                tempData.add(`trow-${tracker_id}-${temp.tracker_type_id}-${temp.row}-${temp.section}`)
            })
        }
        let prevBlockData = viewSelectedTrackers?.blockData
        prevBlockData = { ...prevBlockData, [blockId]: Array.from(tempData) }
        setViewSelectedTrackers(produce((draft) => { draft.blockData = prevBlockData }))
    }, [listblocks?.d3Data])

    useEffect(() => {
        if (listblocks?.mapSize) {
            const d3xScaleyScaleHasNullValue = hasNullValues(listblocks?.mapSize)
            if (d3xScaleyScaleHasNullValue === true) {
                if (listblocks?.blockTtData) {
                    const piersMinMax = findMinMax(listblocks?.blockTtData?.piers);
                    const ttMinMax = findMinMax(listblocks?.blockTtData?.tt);
                    setD3xScaleyScale({ piers: piersMinMax, tt: ttMinMax })
                }
            }
            else {
                setD3xScaleyScale({ piers: listblocks.mapSize?.piers, tt: listblocks.mapSize?.tt })
            }
        }
    }, [listblocks?.mapSize])

    useEffect(() => {
        svgBlocksSelect();
        return () => {
            listblocks?.blocks?.forEach(block => {
                let block_svg_id;
                block_svg_id = document.getElementById(handleCheckZimmerman() ? block?.name : block?.block_svg_id);
                if (block_svg_id) {
                    const handleClick = () => { }; // Dummy function to remove actual listener
                    block_svg_id.removeEventListener('click', handleClick);
                }
            });
        };
    }, [listblocks?.blocks, listLaydown, listblocks?.map, selectedBlocks, viewMode, individualBlockData, viewToggle, scopeEditMode, currentLaydown, listblocks.scopeSelectionData])

    const svgBlocksSelect = useCallback(() => {
        if (listblocks?.blocks?.length > 0) {
            let block_svg_id;
            let block_progress_id;

            let nonCurrentLaydownBlockInprogress = []
            let nonCurrentLaydownBlockCompleted = []

            Object.keys(listLaydown?.allScopeBlockData).map((scope) => {
                if (scopeEditModeRef.current === true) {
                    if (parseInt(scope) !== currentLaydown) {
                        if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                            Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                                if (listLaydown?.allScopeBlockData[scope][block_id] === -1) {
                                    nonCurrentLaydownBlockCompleted.push(parseInt(block_id))
                                }
                            })
                        }
                    }
                }
                if (scopeEditModeRef.current === true) {
                    if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                        Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                            if (listLaydown?.allScopeBlockData[scope][block_id]?.length) {
                                nonCurrentLaydownBlockInprogress.push(parseInt(block_id))
                            }
                        })
                    }
                }
                else {
                    if (parseInt(scope) === currentLaydown) {
                        if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                            Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                                if (listLaydown?.allScopeBlockData[scope][block_id]?.length) {
                                    nonCurrentLaydownBlockInprogress.push(parseInt(block_id))
                                }
                            })
                        }
                    }
                }
            })
            listblocks?.blocks.forEach((block) => {
                block_svg_id = document.getElementById(handleCheckZimmerman() ? block?.name : block?.block_svg_id);
                block_progress_id = document.getElementById(block?.progress_svg_id);
                if (block_svg_id && viewToggleRef.current === "Scope") {
                    if (scopeEditModeRef.current === true) {
                        block_svg_id.removeEventListener('click', () => handleBlockClick(block, block_svg_id, nonCurrentLaydownBlockCompleted, nonCurrentLaydownBlockInprogress));
                        block_svg_id.addEventListener('click', () => handleBlockClick(block, block_svg_id, nonCurrentLaydownBlockCompleted, nonCurrentLaydownBlockInprogress));
                    }
                    block_svg_id.addEventListener('dblclick', () => handleDblClick(block));
                }
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = ``;
                }

                if (block_svg_id) {
                    if (selectedBlockIdsRef.current.includes(block.id)) {
                        block_svg_id.style.fill = "yellow";
                        block_svg_id.style.cursor = "pointer"
                    }
                    else {
                        if (nonCurrentLaydownBlockCompleted.includes(block?.id)) {
                            block_svg_id.style.fill = "green";
                            block_svg_id.style.cursor = "pointer";
                        } else if (nonCurrentLaydownBlockInprogress.includes(block?.id)) {
                            block_svg_id.style.fill = "pink"
                            block_svg_id.style.cursor = "pointer";
                        } else {
                            block_svg_id.style.fill = "white"
                            block_svg_id.style.cursor = "pointer"
                        }
                    }
                }
            });
        }
    }, [listblocks, listblocks.map, viewToggle, currentLaydown, scopeEditMode, viewMode]);

    const handleBlockClick = useCallback(_.debounce((block, block_svg_id, nonCurrentLaydownBlockCompleted, nonCurrentLaydownBlockInprogress) => {
        if (!(nonCurrentLaydownBlockCompleted.includes(block?.id)) && !((nonCurrentLaydownBlockInprogress.includes(block?.id)))) {
            console.log("Click block")
            setSelectedBlocks(() => {
                let newSelectedBlockIds = selectedBlockIdsRef.current;
                if (newSelectedBlockIds.includes(block?.id)) {
                    newSelectedBlockIds = newSelectedBlockIds.filter((item) => item !== block?.id)
                    block_svg_id.style.fill = "";
                } else {
                    newSelectedBlockIds.push(block?.id)
                    block_svg_id.style.fill = "yellow";
                }
                selectedBlockIdsRef.current = newSelectedBlockIds
                return { bool: true, data: newSelectedBlockIds, initSelectedBlocks: {} };
            });
        }
    }, 105), []);

    const handleDblClick = useCallback(_.debounce((block) => {
        console.log(finalDataRef.current)
        setIndividualBlockData(produce((draft) => {
            draft.bool = true;
            draft.block_id = block?.id
        }))
    }, 100), []);

    const init = async () => {
        try {
            setLoading(true)
            await setGetAllScopeLaydownData()
            await setFetchBlocks()
            await setFetchMap()
            await setFetchScopeSelection({ laydown_id: currentLaydown })
            setFetchLaydown()
            setFetchTrackerList()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    const handleLoadD3Data = async () => {
        try {
            setLoading(true)
            await setFetchBulkGet({ id: individualBlockData?.block_id })
            await setMapSize({ id: individualBlockData?.block_id })
            // await setGetAllScopeLaydownData()
            setFetchBlockTrackerDatas()
            navigate(`/dashboard/logistics-management/${currentLaydown}/scope/${individualBlockData?.block_id}`)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    //find function
    function getBlockDetails(block_id) {
        let temp
        temp = listblocks?.blocks.find(o => o?.id === block_id)
        return temp
    }

    const handleFindInitSectionHighlight = async (tempSectionSelect) => {
        console.log(tempSectionSelect)
        let selectedSections = new Set()
        let hightlightSections = new Set()
        if (Object?.keys(tempSectionSelect)?.length) {
            Object.keys(tempSectionSelect)?.map((section) => {
                console.log(tempSectionSelect[section]?.length, individualBlockData.loadAllTrackerSectionWise[section]?.length)
                if (tempSectionSelect[section]?.length === individualBlockData.loadAllTrackerSectionWise[section]?.length) {
                    selectedSections.add(parseInt(section))
                }
                if (tempSectionSelect[section]?.length > 0) {
                    hightlightSections.add(parseInt(section))
                }
            })
        }
        let tempSection = [...sectionSelect]
        // Update sectionSelect without triggering a re-render
        setSectionSelect(prevSections => {
            const newSections = Array.from(selectedSections);
            if (JSON.stringify(prevSections) !== JSON.stringify(newSections)) {
                return newSections;
            }
            return prevSections;
        });

        // Update selectedRow using a callback to avoid unnecessary re-renders
        setSelectedRow(prevSelectedRow => {
            const newSelectedRow = [...prevSelectedRow];
            // Add logic here to update newSelectedRow based on selectedSections
            // For example:
            // selectedSections.forEach(section => {
            //     // Add logic to update newSelectedRow based on each section
            // });
            if (JSON.stringify(prevSelectedRow) !== JSON.stringify(newSelectedRow)) {
                return newSelectedRow;
            }
            return prevSelectedRow;
        });
        setHighlightSection(Array.from(hightlightSections))
    }

    function findMinMax(nestedData) {
        const allCoords = [];

        // Flattening the nested data
        Object.values(nestedData).forEach(coordsArray => {
            coordsArray.forEach(coord => {
                allCoords.push(coord);
            });
        });

        const latitudes = allCoords.map(coord => coord.latitude);
        const longitudes = allCoords.map(coord => coord.longitude);

        return {
            minimum_latitude: Math.min(...latitudes),
            maximum_latitude: Math.max(...latitudes),
            minimum_longitude: Math.min(...longitudes),
            maximum_longitude: Math.max(...longitudes)
        };
    }

    function hasNullValues(data) {
        if (data) {
            const values = [
                data?.piers?.minimum_latitude,
                data?.piers?.maximum_latitude,
                data?.piers?.minimum_longitude,
                data?.piers?.maximum_longitude,
                data?.tt?.minimum_latitude,
                data?.tt?.maximum_latitude,
                data?.tt?.minimum_longitude,
                data?.tt?.maximum_longitude
            ];

            return values.some(value => value === null);
        }
    }

    function getTrackerWiseDatas() {
        let temp = {}
        selectedRow?.map((i) => {
            let tempKey = parseInt(i.split("-")[2])
            let obj = temp[findTrackerName(tempKey)]
            if (!obj) {
                temp[findTrackerName(tempKey)] = 1
            }
            if (obj) {
                temp[findTrackerName(tempKey)] = temp[findTrackerName(tempKey)] + 1
            }
        })
        return temp
    }

    const findTrackerName = (data) => {
        let name = listblocks?.trackerName.find(k => k?.id === data)
        return name?.tracker_type
    }

    function getSelectedScopes() {
        let scopeSelect = {}
        selectedBlockIdsRef.current.map((block_id) => {
            scopeSelect[block_id] = -1
        })
        Object.keys(finalData)?.map((block_id) => {
            if (finalData[block_id] !== -1 && finalData[block_id]?.length) {
                scopeSelect[block_id] = finalData[block_id]
            }
        })
        return scopeSelect
    }

    function handleCheckZimmerman() {
        if (currentProject && currentProject?.type) {
            return true
        }
        else {
            return false
        }
    }

    function handleFindCurrentLaydownName() {
        let temp
        temp = listLaydown?.data?.find(o => o?.id === currentLaydown)?.name
        return temp
    }

    //onclick function
    const handleOpenNestedScopeTable = async (data) => {
        try {
            setViewSelectedScopeLoader(true)
            if (!viewSelectedTrackers?.expandedBlock.includes(parseInt(data))) {
                setViewSelectedTrackers(produce((draft) => { draft.expandedBlock.push(parseInt(data)) }))
                await setFetchD3Data({ block_id: data })
            }
            else {
                let prevData = viewSelectedTrackers?.expandedBlock
                let temp = prevData.filter(item => item !== parseInt(data));
                setViewSelectedTrackers(produce((draft) => { draft.expandedBlock = temp }))
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setViewSelectedScopeLoader(false)
        }
    }

    const handleBackBlockList = () => {
        setIndividualBlockData(produce((draft) => {
            draft.bool = false;
            draft.block_id = null;
            draft.loadAllTrackerSectionWise = {};
            draft.loadSelectedTrackerSectionWise = {};
            draft.loadOtherLaydownTrackerSectionWise = {};
        }))
        setShowSectionList([])
        navigate(`/dashboard/logistics-management/${currentLaydown}/scope`)
    }

    const sectionCallFn = async () => {
        try {
            if (individualBlockData?.bool) {
                await setSectionList({ data: listblocks.blockTtData.piers })
                let loadAllTrackers = new Set()
                let loadListOfSections = new Set()
                let laydownIncludesTracker = new Set()
                let loadAllTrackerSectionWise = {}
                let loadSelectedTrackerSectionWise = {}
                let loadOtherLaydownTrackerSectionWise = {}
                let initLoadSelectedTrackers = new Set()
                let initLoadSelectedTrackers_ids = new Set()
                let temp = new Set()
                for (let pr in listblocks?.blockTtData?.piers) {
                    for (let pierIndex in listblocks?.blockTtData?.piers[pr]) {
                        let pierData = listblocks?.blockTtData?.piers[pr][pierIndex]
                        // if (finalDataRef.current[individualBlockData?.block_id] !== -1 && finalDataRef.current[individualBlockData?.block_id]?.includes(pr)) {
                        //     temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        // }
                        // if (finalDataRef.current[individualBlockData?.block_id] === -1) {
                        //     temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        // }
                        if (Array.isArray(finalDataRef.current[individualBlockData?.block_id]) && finalDataRef.current[individualBlockData?.block_id].includes(pr)) {
                            console.log(pr)
                            temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        }
                        loadAllTrackers.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        loadListOfSections.add(pierData.section)
                        if (pierData.laydown_id === currentLaydown) {
                            initLoadSelectedTrackers.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                            initLoadSelectedTrackers_ids.add(pr)
                        }
                        if (loadAllTrackerSectionWise[pierData?.section]) {
                            let obj = loadAllTrackerSectionWise[pierData?.section]
                            obj.push(pr)
                            loadAllTrackerSectionWise[pierData?.section] = [...new Set(obj)]
                        }
                        else {
                            loadAllTrackerSectionWise[pierData?.section] = [pr]
                        }
                        if (pierData?.laydown_id === currentLaydown) {
                            if (loadSelectedTrackerSectionWise[pierData?.section]) {
                                let obj = loadSelectedTrackerSectionWise[pierData?.section]
                                obj.push(pr)
                                loadSelectedTrackerSectionWise[pierData?.section] = [...new Set(obj)]
                            }
                            else {
                                loadSelectedTrackerSectionWise[pierData?.section] = [pr]
                            }
                        }
                        else {
                            loadSelectedTrackerSectionWise[pierData?.section] = []
                        }
                        if (pierData?.laydown_id !== currentLaydown && pierData?.laydown_id !== null) {
                            if (loadOtherLaydownTrackerSectionWise[pierData?.section]) {
                                let obj = loadOtherLaydownTrackerSectionWise[pierData?.section]
                                obj.push(pr)
                                loadOtherLaydownTrackerSectionWise[pierData?.section] = [...new Set(obj)]
                            }
                            else {
                                loadOtherLaydownTrackerSectionWise[pierData?.section] = [pr]
                            }
                        }
                        else {
                            loadOtherLaydownTrackerSectionWise[pierData?.section] = []
                        }
                        if (pierData?.laydown_id === currentLaydown) {
                            laydownIncludesTracker.add(pierData)
                        }
                    }
                }
                let tempSelectBlockTrackers = selectBlockTrackers
                tempSelectBlockTrackers[individualBlockData?.block_id] = Array.from(initLoadSelectedTrackers_ids)
                setSelectBlockTrackers(tempSelectBlockTrackers);
                console.log("finalDataRef.current", finalDataRef.current)
                setFinalData(finalDataRef.current)
                setIndividualBlockData(produce((draft) => {
                    draft.loadAllTrackerSectionWise = loadAllTrackerSectionWise;
                    draft.loadSelectedTrackerSectionWise = loadSelectedTrackerSectionWise;
                    draft.loadOtherLaydownTrackerSectionWise = loadOtherLaydownTrackerSectionWise;
                    draft.initLoadTracker = Array.from(initLoadSelectedTrackers);
                    draft.initLoadTracker_ids = Array.from(initLoadSelectedTrackers_ids)
                }))
                let selectedSections = new Set()
                let disableSections = new Set()
                Object.keys(loadAllTrackerSectionWise).map((section) => {
                    if (loadAllTrackerSectionWise[section]?.length === loadSelectedTrackerSectionWise[section]?.length) {
                        selectedSections.add(parseInt(section))
                    }
                    if (loadAllTrackerSectionWise[section]?.length === loadOtherLaydownTrackerSectionWise[section]?.length) {
                        disableSections.add(parseInt(section))
                    }
                })
                setSelectedRow(Array.from(temp))
                selectedRowRef.current = Array.from(temp)
                setSectionSelect(Array.from(selectedSections))
                setDisableSection(Array.from(disableSections))
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSectionSelect = (data) => {
        if (!disableSection.includes(data)) {
            if (sectionSelect.includes(data)) {
                setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
                setPrevSection(data)
            }
            else {
                setSectionSelect(produce((draft) => { draft.push(data) }))
                setPrevSection(null)
            }
        }
    }

    const handleSelectAllBlocks = () => {
        if (individualBlockData?.bool) {
            setSelectAllTracker(!selectAllTrackers)
            setSectionSelect(listblocks?.sectionList)
            setPrevSection(null)
        }
        else {
            let nonCurrentLaydownBlockCompleted = []
            let nonCurrentLaydownBlockInprogress = []
            Object.keys(listLaydown?.allScopeBlockData).map((scope) => {
                if (parseInt(scope) !== currentLaydown) {
                    if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                        Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                            if (listLaydown?.allScopeBlockData[scope][block_id] === -1) {
                                nonCurrentLaydownBlockCompleted.push(parseInt(block_id))
                            }
                        })
                    }
                }
                if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                    Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                        if (listLaydown?.allScopeBlockData[scope][block_id]?.length) {
                            nonCurrentLaydownBlockInprogress.push(parseInt(block_id))
                        }
                    })
                }
            })
            const allBlockIds = []
            let blockMinusOne = {}
            listblocks?.blocks.map((block) => {
                if (!nonCurrentLaydownBlockCompleted.includes(parseInt(block?.id)) && !nonCurrentLaydownBlockInprogress.includes(parseInt(block?.id))) {
                    allBlockIds.push(parseInt(block?.id))
                    blockMinusOne[block?.id] = -1
                }
                if (nonCurrentLaydownBlockInprogress.includes(parseInt(block?.id))) {
                    blockMinusOne[block?.id] = finalData[block?.id]
                }
            });
            selectedBlockIdsRef.current = allBlockIds
            setSelectedBlocks(produce((draft) => { draft.data = allBlockIds }));
            setFinalData(blockMinusOne)
            finalDataRef.current = blockMinusOne
        }
    }

    const handleCancel = () => {
        setDiscardScope(false)
    }

    const handleCancelScope = () => {
        setScopeEditMode(false)
        setScopeEditModeBool(false)
        setDiscardScope(false)
        scopeEditModeRef.current = false
        let currentLaydownScope = listLaydown?.data.find(o => o?.id === currentLaydown)?.scope
        let tempBlocks = []
        if (currentLaydownScope && Object.keys(currentLaydownScope).length) {
            Object.keys(currentLaydownScope).map((i) => {
                if (currentLaydownScope[i] === -1) {
                    tempBlocks.push(parseInt(i))
                }
            })
        }
        let selectedSections = new Set()
        Object.keys(individualBlockData?.loadAllTrackerSectionWise).map((section) => {
            if (individualBlockData?.loadAllTrackerSectionWise[section]?.length === individualBlockData?.loadSelectedTrackerSectionWise[section]?.length) {
                selectedSections.add(parseInt(section))
            }
        })
        setSectionSelect(Array.from(selectedSections))
        setSelectedBlocks({ bool: true, data: tempBlocks, initSelectedBlocks: {} })
        setSelectedRow(individualBlockData?.initLoadTracker ? individualBlockData?.initLoadTracker : [])
        selectedRowRef.current = individualBlockData?.initLoadTracker ? individualBlockData?.initLoadTracker : []
    }

    console.log("finalData", finalData)

    const handleSaveScope = async () => {
        setSaveScopeLoader(true)
        let prevValue = listLaydown?.allScopeBlockData?.[currentLaydown]
        let scopeSelect = {}
        selectedBlockIdsRef.current.map((block_id) => {
            scopeSelect[block_id] = -1
        })
        console.log("finalData", finalData)
        Object.keys(finalData)?.map((block_id) => {
            console.log("block_id", block_id)
            if (finalData[block_id] !== -1) {
                let currentBlockTrackerLength = listblocks?.blockTrackerCount.find(o => o?.block_id === parseInt(block_id))?.count
                if (currentBlockTrackerLength === finalData[block_id]?.length) {
                    scopeSelect[block_id] = -1
                }
                else {
                    scopeSelect[block_id] = finalData[block_id]
                }

            }
        })
        console.log("selectedBlockIdsRef.current", selectedBlockIdsRef.current)
        console.log("finalData", finalData)
        console.log("prevValue", prevValue)
        let missingKeys = {}
        if (prevValue) {
            let prevMissingData = Object.keys(prevValue)
            prevMissingData.map((data) => {
                console.log(data)
                if (prevValue[data] === -1) {
                    if (!selectedBlockIdsRef.current.includes(parseInt(data))) {
                        console.log("object", data)
                        missingKeys[data] = -1
                    }
                }
                else {
                    console.log(prevValue[data])
                    const missing = prevValue[parseInt(data)]?.filter(item => !finalData[parseInt(data)]?.includes(item))
                    console.log(missing)
                    if (missing?.length) {
                        missingKeys[parseInt(data)] = missing
                    }
                }
                // if (!selectedBlockIdsRef.current.includes(data)) {
                //     if (prevValue[parseInt(data)] === -1) {
                //         // if (finalData[parseInt(data)]) {
                //         //     const missing = individualBlockData.initLoadTracker_ids?.filter(item => !finalData[parseInt(data)]?.includes(item))
                //         //     missingKeys[parseInt(data)] = missing
                //         // }
                //         // missingKeys[parseInt(data)] = -1
                //         console.log(finalData)
                //         if (!finalData[parseInt(data)]) {
                //             missingKeys[parseInt(data)] = -1
                //         }
                //     }
                //     else {
                //         const missing = prevValue[parseInt(data)]?.filter(item => !finalData[parseInt(data)]?.includes(item))
                //         if (missing?.length) {
                //             missingKeys[parseInt(data)] = missing
                //         }
                //     }
                // }
            })
        };
        try {
            console.log("missingKeys", missingKeys)
            console.log("scopeSelect", scopeSelect)
            if (Object.keys(missingKeys)?.length) {
                await setUpdateCreateScopeTrackers({ removedTracker: true, block_id: individualBlockData?.block_id, scopeData: missingKeys })
                await setUpdateCreateScopeTrackers({ block_id: individualBlockData?.block_id, laydown_id: currentLaydown, scopeData: scopeSelect })
            } else {
                await setUpdateCreateScopeTrackers({ block_id: individualBlockData?.block_id, laydown_id: currentLaydown, scopeData: scopeSelect })
            }
            setFetchLaydown()
            // setGetAllScopeLaydownData({ currentLaydown: currentLaydown })
            setScopeEditMode(false)
            setScopeEditModeBool(false)
            scopeEditModeRef.current = false
            setSectionSelect([])
            setSaveScopeModal(false)
            toast.success("Successfully updated scope")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setSaveScopeLoader(false)
        }
    }

    const handleSaveScope02 = async () => {
        setSaveScopeLoader(true)
        let prevValue = listLaydown?.allScopeBlockData?.[currentLaydown]
        let scopeSelect = {}
        console.log("prevValue", prevValue)
        console.log("selectedBlockIdsRef.current", selectedBlockIdsRef.current)
        console.log("finalData", finalData)
        let missingKeys = {}
        //fully selected blocks
        selectedBlockIdsRef.current.map((block_id) => {
            if (block_id) {
                scopeSelect[block_id] = -1
            }
        })
        // selected trackers
        Object.keys(finalData)?.map((block_id) => {
            if (!prevValue[block_id] && !selectedBlockIdsRef.current.includes(block_id)) {
                console.log("block_id", block_id)
                let currentBlockTrackerLength = listblocks?.blockTrackerCount.find(o => o?.block_id === parseInt(block_id))?.count
                if (finalData[block_id]?.length === currentBlockTrackerLength) {
                    scopeSelect[block_id] = -1
                }
                else {
                    scopeSelect[block_id] = finalData[block_id]
                }
            }
        })
        //unselected blocks
        if (Object.keys(prevValue)?.length > 0) {
            Object.keys(prevValue)?.map((block_id) => {
                if (prevValue[block_id] === -1 && !selectedBlockIdsRef.current.includes(block_id)) {
                    console.log("block_id", block_id)
                    missingKeys[block_id] = -1
                }
            })
        }
        //unselected trackers
        if (Object.keys(prevValue)?.length) {
            Object.keys(prevValue)?.map((block_id) => {
                if (Array.isArray(prevValue[block_id])) {
                    const missing = prevValue[block_id]?.filter(item => !finalData[block_id]?.includes(item))
                    if (missing?.length) {
                        missingKeys[block_id] = missing
                    }
                }
            })
        }
        // if (Object.keys(prevValue)?.length) {
        //     let prevMissingData = Object.keys(prevValue)
        //     prevMissingData.map((data) => {
        //         if (!selectedBlockIdsRef.current.includes(data)) {
        //             if (prevValue[parseInt(data)] === -1) {
        //                 console.log(finalData)
        //             }
        //             else {
        //                 const missing = prevValue[parseInt(data)]?.filter(item => !finalData[parseInt(data)]?.includes(item))
        //                 if (missing?.length) {
        //                     missingKeys[parseInt(data)] = missing
        //                 }
        //             }
        //         }
        //     })
        // };
        try {
            console.log("missingKeys", missingKeys)
            console.log("scopeSelect", scopeSelect)
            if (Object.keys(missingKeys)?.length) {
                // await setUpdateCreateScopeTrackers({ removedTracker: true, block_id: individualBlockData?.block_id, scopeData: missingKeys })
                // await setUpdateCreateScopeTrackers({ block_id: individualBlockData?.block_id, laydown_id: currentLaydown, scopeData: scopeSelect })
            } else {
                // await setUpdateCreateScopeTrackers({ block_id: individualBlockData?.block_id, laydown_id: currentLaydown, scopeData: scopeSelect })
            }
            setFetchLaydown()
            setGetAllScopeLaydownData()
            setScopeEditMode(false)
            setScopeEditModeBool(false)
            scopeEditModeRef.current = false
            setSaveScopeModal(false)
            toast.success("Successfully updated scope")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setSaveScopeLoader(false)
        }
    }

    const handleClearAllBlock = () => {
        if (individualBlockData?.bool) {
            setClearSt(!clearSt)
            setSelectAllTracker(false)
            setSectionSelect([])
            setPrevSection(null)
            let tempFinalData = finalData
            tempFinalData[individualBlockData?.block_id] = []
            setFinalData(tempFinalData)
            finalDataRef.current = tempFinalData
        }
        else {
            setSelectedBlocks(produce((draft) => { draft.data = [] }))
            selectedBlockIdsRef.current = []
            setFinalData({})
            finalDataRef.current = {}
        }
    }

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    return (
        <div>
            <div>
                <div className='flex justify-between'>
                    <div className='flex space-x-4 items-center pl-5'>
                        <p className='my-2 py-2 font-bold text-xl'>Logistics Management - Scope</p>
                    </div>
                    <div className='flex items-center space-x-4'>
                        {scopeEditMode ?
                            <div className='flex space-x-4'>
                                <div>
                                    <button className='rounded-xl border-2 px-3 py-2 border-gray-500 bg-white font-semibold text-gray-500 hover:bg-gray-500 hover:text-white text-xs cursor-pointer' onClick={() => setDiscardScope(true)}>Cancel</button>
                                </div>
                                <div>
                                    <button className='cursor-pointer border-2 border-red-550 px-3 py-2 hover:bg-gray-700 hover:border-gray-700 text-xs font-semibold bg-red-550 text-white rounded-xl' onClick={() => setSaveScopeModal(true)}>Save Scope</button>
                                </div>
                            </div>
                            :
                            <div>
                                <button className='cursor-pointer border-none px-3 py-2 hover:bg-gray-700 text-xs font-semibold bg-red-550 text-white rounded-xl' onClick={() => { setScopeEditMode(true); setScopeEditModeBool(true); scopeEditModeRef.current = true }}>Edit Scope</button>
                            </div>
                        }
                        <div className='flex space-x-4 items-center mr-4 w-36'>
                            <ViewByDropDown onSelect={handleChangeView} defaultValue="Logistics Management" />
                        </div>
                        {showRightPanel ?
                            <div className='text-xl text-black border bg-gray-200 mx-2'>
                                <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowRightPanel(false)} />
                            </div>
                            :
                            <div className='text-xl text-black border bg-gray-200 mx-2'>
                                <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowRightPanel(true)} />
                            </div>
                        }
                    </div>
                </div>
                <hr />
            </div>
            <div style={{ height: "calc(100vh - 140px)" }}>
                {loading ?
                    <div className='absolute opacity-100 left-[35%] top-[50%] z-50'>
                        <p className='loader ml-12 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching Scope...</p>
                    </div>
                    :
                    <div>
                        <div style={{ height: "calc(100vh - 200px)" }}>
                            {viewMode === "Block Scope" ?
                                <div>
                                    <div className='flex justify-between py-2'>
                                        <div className='flex space-x-4 py-2 pl-5 items-center'>
                                            <BiArrowBack className='cursor-pointer' onClick={handleBackBlockList} />
                                            <p className='text-base font-medium'>{getBlockDetails(individualBlockData?.block_id)?.name}</p>
                                        </div>
                                        <div className='flex space-x-4 px-4'>
                                            <div>
                                                <p className='text-gray-400 text-sm font-semibold ml-1'>{`Section(s)`}</p>
                                                <div className='flex flex-wrap justify-left text-center max-w-[500px] overflow-y-auto'>
                                                    {listblocks?.sectionList.map((section) => {
                                                        if (disableSection?.includes(section)) {
                                                            return (
                                                                <button className='m-1 w-10 py-1 text-xs bg-gray-200 border cursor-auto'>
                                                                    {section}
                                                                </button>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <button
                                                                    className={
                                                                        `${scopeEditMode && "cursor-pointer"} 
                                                                    m-1 w-10 py-1 text-xs 
                                                                    ${sectionSelect?.includes(section) ? "bg-red-550 text-white border-red-550 border" : "text-red-550 border"}
                                                                    ${highlightSection?.includes(section) ? "border-red-550" : ""}`
                                                                    }
                                                                    disabled={!scopeEditMode}
                                                                    onClick={() => handleSectionSelect(section)}
                                                                >
                                                                    {section}
                                                                </button>
                                                            )
                                                        }

                                                    })}
                                                </div>
                                            </div>
                                            <div className='flex mt-5 ml-2 mr-1'>
                                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={() => setViewSelectedTrackers({ bool: true, mode: "tracker", expandedBlock: [], blockData: {} })}>View Selected scope</p>
                                            </div>
                                            <div className='flex mt-5 ml-2 mr-1'>
                                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleSelectAllBlocks}>Select All</button>
                                            </div>
                                            <div className='flex mt-5 ml-2 mr-1'>
                                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleClearAllBlock}>Clear Selection</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <SuryaBlockMap
                                        zimmerMan={handleCheckZimmerman()}
                                        deviceType="web"
                                        loader={true}
                                        bulkAllData={listblocks?.blockTtData}
                                        pierLegends={listblocks?.pierLegends}
                                        type="CP"
                                        typeData={listblocks.cpTrackerRowWise}
                                        userSelection={{ showType: "pier", selection: "None Selected" }}
                                        scopeSelection={true}
                                        showLaydownNameHover={true}
                                        laydownData={listLaydown?.data}
                                        currentLaydownData={currentLaydown}
                                        initialLoadSelectedLaydownTracker={true}
                                        currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(individualBlockData.block_id))}
                                        currentProjectData={currentProject}
                                        disableSelection={!scopeEditMode}
                                        selectedRow={selectedRow}
                                        setSelectedRow={e => { setSelectedRow(e); selectedRowRef.current = e }}
                                        showLegend={false}
                                        mapSize={d3xScaleyScale}
                                        selectAll={selectAllTrackers}
                                        section={sectionSelect}
                                        prevSection={prevSection}
                                        clearSelection={clearSt}
                                        controls={true}
                                    />
                                </div>
                                :
                                <div style={{ height: "calc(100vh - 40%)" }} >
                                    <div className='flex justify-between'>
                                        <div className='flex space-x-4 items-center pl-5'>
                                            <BiArrowBack className='cursor-pointer text-xl' onClick={() => scopeEditMode ? setDiscardScope(true) : navigate("/dashboard/logistics-management")} />
                                            <div className='flex space-x-4 items-center'>
                                                <p className='font-medium text-base border border-white py-2 px-1.5 my-2 font-bold text-xl'>{handleFindCurrentLaydownName(currentLaydown)}</p>
                                                <MdModeEditOutline className='cursor-pointer' onClick={() => setEditInventoryNameModal({ boolean: true, data: currentLaydown })} />
                                            </div>
                                        </div>
                                        <div className='flex space-x-4 items-center mr-5'>
                                            <div className='flex items-center ml-2 mr-1'>
                                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={() => setViewSelectedTrackers({ bool: true, mode: "blocks", expandedBlock: [], blockData: {} })}>View Selected Scope</p>
                                            </div>
                                            <div className='flex items-center ml-2 mr-1'>
                                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleSelectAllBlocks}>Select All</button>
                                            </div>
                                            <div className='flex items-center ml-2 mr-1'>
                                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleClearAllBlock}>Clear Selection</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <TransformWrapper
                                        centerZoomedOut
                                        minScale={parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale)}
                                        initialScale={1}
                                    // initialPositionX={((window.innerWidth - 760) - (parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale) * currentProject?.svg_dimension?.web?.web_svg_width)) / 2}
                                    // initialPositionY={((window.innerHeight - 350) - (parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale) * currentProject?.svg_dimension?.web?.web_svg_height) + 100) / 2}
                                    >
                                        <TransformComponent
                                            contentStyle={{ width: 'calc(100% - 450px)' }}
                                            wrapperStyle={{ height: "100%", width: "100%" }}
                                        >
                                            <div className='w-[100%] h-[100%] flex items-center justify-center'>
                                                <div className="svgmap scopeSelection flex items-center justify-center cursor-grab" style={{ height: `50%`, width: `50%`, position: "relative" }} dangerouslySetInnerHTML={{ __html: listblocks?.map }}></div>
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>
                                </div>
                            }
                        </div>
                        {!loading && (
                            <div className='relative w-[100%]'>
                                {viewToggle === "Scope" && !individualBlockData?.block_id && (
                                    <div className={`absolute animate-slide-up flex ${scopeEditMode ? "bottom-8" : "bottom-[-50px]"}`} style={{ left: "calc(50% - 150px)", display: scopeEditMode ? "" : "none" }}>
                                        <div className='text-white bg-black border px-4 py-1.5 rounded-2xl'>
                                            <div className='flex  justify-center items-center space-x-2'>
                                                <span data-tip data-for="svg" className='mt-1.5'>
                                                    <AiOutlineInfoCircle />
                                                </span>
                                                <div className='flex flex-col space-y-1 text-white'>
                                                    <p className='text-sm mt-0.5 ml-1'>
                                                        Click on any block to select
                                                    </p>
                                                    <p className='text-sm mt-0.5 ml-1'>
                                                        Double click on any block to dive in
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {viewToggle === "Scope" && (
                                    individualBlockData?.block_id ?
                                        <div className='flex space-x-5 justify-center'>
                                            <div className='flex justify-center justify-end pt-1'>
                                                <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "yellow" }}></p>
                                                <p className='mr-2'>Selected Scope</p>
                                            </div>
                                            <div className='flex justify-center justify-end pt-1'>
                                                <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "green" }}></p>
                                                <p className='mr-2'>Trackers assigned to other Laydowns</p>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex space-x-4 justify-center'>
                                            {scopeEditMode && (
                                                <div className='flex'>
                                                    <p className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: "green" }}></p>
                                                    <p className='mr-2'>Blocks fully assigned to other Laydowns</p>
                                                </div>
                                            )}
                                            <div className='flex'>
                                                <p className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: "pink" }}></p>
                                                <p className='mr-2'>Blocks partially assigned</p>
                                            </div>
                                            <div className='flex'>
                                                <p className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: "yellow" }}></p>
                                                <p className='mr-2'>Blocks fully assigned to current Laydown</p>
                                            </div>
                                        </div>
                                )}
                            </div>
                        )}
                    </div>
                }
            </div>
            <Modal
                isOpen={discardScope}
                style={customConfirmStyle}
                contentLabel="Discard Scope Alert Box"
            >
                <div className='rounded-xl flex flex-col space-y-4 px-6 py-4'>
                    <p className='text-xl font-medium'>Are you sure want to discard your changes?</p>
                    <hr />
                    <div className='flex justify-end space-x-4 text-sm font-medium'>
                        <button className='border rounded-xl border-2 border-gray-500 px-4 py-1.5 hover:text-white hover:bg-gray-500' onClick={handleCancel}>Cancel</button>
                        <button className='rounded-xl bg-red-550 text-white hover:bg-gray-700 px-4 py-1.5' onClick={handleCancelScope}>Proceed</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={saveScopeModal}
                style={customConfirmStyle}
                contentLabel="Save Scope Alert Box"
            >
                <div className='rounded-xl flex flex-col space-y-4 px-6 py-4'>
                    <p className='text-xl font-medium'>Are you sure want to Save your changes?</p>
                    <hr />
                    <div className='flex justify-end space-x-4 text-sm font-medium'>
                        <button className='border rounded-xl border-2 border-gray-500 px-4 py-1.5 hover:text-white hover:bg-gray-500' onClick={() => setSaveScopeModal(false)}>Cancel</button>
                        {saveScopeLoader ?
                            <button className='rounded-xl flex items-center space-x-2 bg-red-550 text-white hover:bg-gray-700 px-4 py-1.5'>
                                <p className='loader' style={{ width: "25px", height: "25px" }}></p>
                                <p>Proceed</p>
                            </button>
                            :
                            <button className='rounded-xl bg-red-550 text-white hover:bg-gray-700 px-4 py-1.5' onClick={handleSaveScope}>Proceed</button>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={viewSelectedTrackers?.bool && viewSelectedTrackers?.mode === "tracker"}
                onRequestClose={() => { setViewSelectedTrackers({ bool: false, mode: null, expandedBlock: [], blockData: {} }); setShowScopeTracker("Block") }}
                style={customConfirmStyle}
                contentLabel='Selected Scope Trackers'
            >
                <div>
                    <div className='flex items-center justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                        <div className='flex space-x-3 items-center'>
                            <p>{`Selected Scope`}</p>
                            {viewSelectedScopeLoader && <p className='loader' style={{ width: "25px", height: "25px" }}></p>}
                        </div>
                        <div className='flex space-x-3 items-center'>
                            <div className={`flex text-sm ${currentLaydown ? "cursor-pointer" : "cursor-auto"} border rounded-full font-medium p-1 bg-red-50 border-red-100 mr-4`}>
                                <div className={`flex items-center ${showScopeTracker === "Block" ? "bg-red-550 border-none rounded-full" : ""} px-1`} onClick={() => {
                                    setShowScopeTracker("Block")
                                }}>
                                    <p className={`${showScopeTracker === "Block" ? "text-white pr-3 pl-1.5 py-1" : "text-gray-400 pr-3 pl-1.5 py-1"} mr-1`}>
                                        <p>Block</p>
                                    </p>
                                </div>
                                <div className={`flex ${showScopeTracker === "Scope" ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => {
                                    setShowScopeTracker("Scope")
                                }}>
                                    <p className={`${showScopeTracker === "Scope" ? "text-white pr-3 pl-1.5 py-1" : "text-gray-400 pr-3 pl-1.5 py-1"} ml-1`}>
                                        <p>Scope</p>
                                    </p>
                                </div>
                            </div>
                            <div className='flex'>
                                <VscClose onClick={() => { setViewSelectedTrackers({ bool: false, mode: null, expandedBlock: [], blockData: {} }); setShowScopeTracker("Block") }} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                            </div>
                        </div>
                    </div>
                    {showScopeTracker === "Scope" ?
                        <div className='max-h-[80vh] min-h-[300px] w-[50vw] min-w-[500px] overflow-y-auto'>
                            <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
                                <thead>
                                    <tr className='sticky z-20' style={{ top: "0" }}>
                                        <th className='normal-case w-[60%] pl-5 text-left'>Block</th>
                                        <th className='normal-case w-[40%]'>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(getSelectedScopes())?.length === 0 ?
                                        <tr>
                                            <td colSpan={3} className='py-16'>
                                                <div className='m-auto'>
                                                    <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                    <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        Object.keys(getSelectedScopes()) && Object.keys(getSelectedScopes())?.map((block_id) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='text-left pl-5'>{getBlockDetails(parseInt(block_id))?.name}</td>
                                                        <td>{getSelectedScopes()[block_id] === -1 ?
                                                            <p className='text-white bg-green-400 text-center w-full'>Fully Selected</p> :
                                                            <p className='text-white bg-pink-200 text-center w-full'>Partially Selected</p>}</td>
                                                        <td>
                                                            {getSelectedScopes()[block_id] !== -1 ?
                                                                viewSelectedTrackers?.expandedBlock.includes(parseInt(block_id)) ?
                                                                    <BsChevronUp className='cursor-pointer' onClick={() => handleOpenNestedScopeTable(block_id)} />
                                                                    :
                                                                    <BsChevronDown className='cursor-pointer' onClick={() => handleOpenNestedScopeTable(block_id)} />
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {viewSelectedTrackers?.expandedBlock.includes(parseInt(block_id)) && Object.keys(viewSelectedTrackers?.blockData)?.length > 0 ?
                                                            <td colSpan={4}>
                                                                <div className='max-h-[200px] border w-[80%] px-5 w-full overflow-y-auto'>
                                                                    <table className='table table-compact z-0 relative overflow-auto w-full'>
                                                                        <thead>
                                                                            <tr className='sticky z-20' style={{ top: "0" }}>
                                                                                <td className='normal-case bg-red-200 w-[20%]'>Section</td>
                                                                                <td className='normal-case bg-red-200 w-[20%]'>Row</td>
                                                                                <td className='normal-case bg-red-200 w-[60%]'>Tracker Type</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Object.keys(viewSelectedTrackers?.blockData)?.length > 0 && viewSelectedTrackers?.blockData[block_id]?.map((trackerData) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{trackerData.split("-")[4]}</td>
                                                                                        <td>{trackerData.split("-")[3]}</td>
                                                                                        <td className={`"text-left text-xs`} style={{ textTransform: "capitalize" }}>{findTrackerName(parseInt(trackerData?.split("-")[2]))}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                            :
                                                            null
                                                        }
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className='max-h-[80vh] min-h-[300px] w-[50vw] min-w-[500px] overflow-y-auto'>
                            <div className='mb-3 w-[60%]'>
                                {Object.keys(getTrackerWiseDatas())?.length > 0 && Object.keys(getTrackerWiseDatas())?.map((i) => {
                                    return (
                                        <div className='flex items-center w-full justify-between text-sm'>
                                            <p className='text-base my-1 w-[85%]' style={{ textTransform: "capitalize" }}>{i}</p>
                                            <div className='flex space-x-5 w-[15%]'>
                                                <p className='text-base font-medium my-1'>-</p>
                                                <p className='text-sm my-1'>{getTrackerWiseDatas()[i]}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex'>
                                <div className='flex w-[60%]'>
                                    <p className='font-bold text-xl pb-2 w-[85%]'>Total</p>
                                    <div className='flex space-x-5 w-[15%]'>
                                        <p className='text-base font-medium'>-</p>
                                        <p className='text-base ml-2'>{selectedRow?.length}</p>
                                    </div>
                                </div>
                                <p>{`tracker(s)`}</p>
                            </div>
                            <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
                                <thead>
                                    <tr className='sticky z-20' style={{ top: "0" }}>
                                        <th className='normal-case w-[20%]'>Section</th>
                                        <th className='normal-case w-[20%]'>Tracker Row</th>
                                        <th className='normal-case w-[60%]'>Tracker Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedRow.length === 0 ?
                                        <tr>
                                            <td colSpan={3} className='py-16'>
                                                <div className='m-auto'>
                                                    <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                    <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        selectedRow.map((i) => {
                                            return (
                                                <tr>
                                                    <td>{i.split("-")[4]}</td>
                                                    <td>{i.split("-")[3]}</td>
                                                    <td className={`"text-left`} style={{ textTransform: "capitalize" }}>{findTrackerName(parseInt(i.split("-")[2]))}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Modal>
            <Modal
                isOpen={viewSelectedTrackers?.bool && viewSelectedTrackers?.mode === "blocks"}
                onRequestClose={() => setViewSelectedTrackers({ bool: false, mode: null, expandedBlock: [], blockData: {} })}
                style={customConfirmStyle}
                contentLabel='Selected Scope Trackers'
            >
                <div>
                    <div className='flex justify-between items-center font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                        <div className='flex space-x-3 items-center'>
                            <p>Selected Scope</p>
                            {viewSelectedScopeLoader && <p className='loader' style={{ width: "25px", height: "25px" }}></p>}
                        </div>
                        <div className='flex'>
                            <VscClose onClick={() => setViewSelectedTrackers({ bool: false, mode: null, expandedBlock: [], blockData: {} })} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                        </div>
                    </div>
                    <div className='max-h-[80vh] min-h-[300px] w-[40vw] min-w-[500px] overflow-y-auto'>
                        <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
                            <thead>
                                <tr className='sticky z-20' style={{ top: "0" }}>
                                    <th className='normal-case w-[60%] pl-5 text-left'>Block</th>
                                    <th className='normal-case w-[40%]'>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(getSelectedScopes())?.length === 0 ?
                                    <tr>
                                        <td colSpan={3} className='py-16'>
                                            <div className='m-auto'>
                                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    Object.keys(getSelectedScopes()) && Object.keys(getSelectedScopes())?.map((block_id) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td className='text-left pl-5'>{getBlockDetails(parseInt(block_id))?.name}</td>
                                                    <td>{getSelectedScopes()[block_id] === -1 ?
                                                        <p className='text-white bg-green-400 text-center w-full'>Fully Selected</p> :
                                                        <p className='text-white bg-pink-200 text-center w-full'>Partially Selected</p>}</td>
                                                    <td>
                                                        {getSelectedScopes()[block_id] !== -1 ?
                                                            viewSelectedTrackers?.expandedBlock.includes(parseInt(block_id)) ?
                                                                <BsChevronUp className='cursor-pointer' onClick={() => handleOpenNestedScopeTable(block_id)} />
                                                                :
                                                                <BsChevronDown className='cursor-pointer' onClick={() => handleOpenNestedScopeTable(block_id)} />
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {viewSelectedTrackers?.expandedBlock.includes(parseInt(block_id)) && Object.keys(viewSelectedTrackers?.blockData)?.length > 0 ?
                                                        <td colSpan={4}>
                                                            <div className='max-h-[200px] border w-[80%] px-5 w-full overflow-y-auto'>
                                                                <table className='table table-compact z-0 relative overflow-auto w-full'>
                                                                    <thead>
                                                                        <tr className='sticky z-20' style={{ top: "0" }}>
                                                                            <td className='normal-case bg-red-200 w-[20%]'>Section</td>
                                                                            <td className='normal-case bg-red-200 w-[20%]'>Row</td>
                                                                            <td className='normal-case bg-red-200 w-[60%]'>Tracker Type</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {Object.keys(viewSelectedTrackers?.blockData)?.length > 0 && viewSelectedTrackers?.blockData[block_id]?.map((trackerData) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{trackerData.split("-")[4]}</td>
                                                                                    <td>{trackerData.split("-")[3]}</td>
                                                                                    <td className={`"text-left text-xs`} style={{ textTransform: "capitalize" }}>{findTrackerName(parseInt(trackerData?.split("-")[2]))}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ScopeLaydown
