import React from 'react'
import { useState, useRef } from 'react'
import Container from '../../components/container'
import Modal from 'react-modal';
import { CgSoftwareDownload } from "react-icons/cg"
import { VscClose } from "react-icons/vsc"
import { TiTick } from "react-icons/ti"
import { BsBoxArrowUpRight } from "react-icons/bs"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import { useAtom } from 'jotai';
import dashboardAtom, { fetchBomBlock, fetchSelectedTrackerBOM, fetchBlocks, fetchCount, fetchCountOverall, fetchSelectedActId, fetchBulkDashboardDataGet, fetchBulkDashboardDataPost, fetchMapSize, fetchCpTrackerRowAtom, fetchListSections, fetchCoordinates, fetchoverallCP, handleLoadMarkProgressBulk, updateConstructionProgressStatus, getListOfPiersTorqueTubeIdsWithStatusAtom, updateInprogressDeployedCountAtom, updateDistributedAndInstalledQuantityAtom, getSelectedPiersTorqueTubesDataAtom } from '../../atoms/dashboardAtom';
import { useEffect } from 'react';
import activitiesAtom, { fetchActivitiesAtom } from '../../atoms/activitiesAtom';
import partsAtom, { fetchPartsAtom } from '../../atoms/partsAtom';
import { toast } from 'react-hot-toast';
import { Parser } from 'json2csv';
import projectAtom, { fetchProjectAtom } from '../../atoms/projectAtom';
import produce from 'immer';
import { BlockMap, ActivitySelectBox } from '@product-fusion/surya-fw';
import roundOff from '../../utils/roundOff';
import * as XLSX from 'xlsx'
import { get_distributed_and_installed_bom_for_activity, get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers, get_updated_bom_for_distributed_installed_for_selected_activity_trackerow, updated_progress_quantity_for_all_selected_trackerrows } from '../../dal/constructionMarkProgress/construction-progress-dal';
import InProgressstepperModal from './in-progress-stepper';

const SelectBoxView = ({ onChange, flag }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState("Layout")
  const dropdownRef = useRef(null)


  const handleFocus = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOnClick = (id) => {
    setSelectedItem(id)
    if (id === "Layout") {
      onChange("pier")
    }
    if (id === "Pier Map") {
      onChange("part number")
    }
    if (id === "TT Map") {
      onChange("torque tube")
    }
    if (id === "BHA & SG Map") {
      onChange("bha")
    }
    setIsOpen(false)
  }

  return (
    <div className='relative flex mt-[-2px]' ref={dropdownRef}>
      <div>
        <input
          type='text'
          className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white pr-4 pl-2 mt-[-5px] btn btn-sm focus:outline-none'
          style={{ textTransform: "capitalize", width: "120px" }}
          onClick={handleFocus}
          value={selectedItem}
          readOnly
        />
        {isOpen && (
          <div className='absolute z-10 bg-white shadow border border-gray-300 rounded-xl' style={{ width: "120px" }}>
            <ul className='py-2 max-h-60 overflow-y-auto'>
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("Layout")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>Layout</p>
                </span>
              </li>
              {/* {flag?.pier_map === true ? */}
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("Pier Map")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>Pier Map</p>
                </span>
              </li>
              {/* :
                null
              } */}
              {/* {flag?.tt_map === true ? */}
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("TT Map")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>TT Map</p>
                </span>
              </li>
              {/* :
                null
              } */}
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("BHA & SG Map")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>BHA & SG Map</p>
                </span>
              </li>
              {/* :
                null
              } */}
            </ul>
          </div>
        )}
      </div>
      {isOpen ?
        <MdKeyboardArrowUp className='absolute right-2 top-1.5 text-sm text-white cursor-pointer' onClick={handleFocus} />
        :
        <MdKeyboardArrowDown className='absolute right-2 top-1.5 text-sm text-white cursor-pointer' onClick={handleFocus} />
      }
    </div>
  )
}

function Dashboard2() {

  const [loading, setLoading] = useState(false)
  const [countLoading, setCountLoading] = useState(false)
  const [bomLoading, setBomLoading] = useState(false)
  const [inProgressSubmitLoader, setInProgressSubmitLoader] = useState(false)
  const [applyProgressLoader, setApplyProgressLoader] = useState(false)

  const [showDescription, setShowDescription] = useState()
  const [openTrackerModal, setTrackerModal] = useState(false)
  const [showTT, setShowTT] = useState("pier")
  const [selectAll, setSelectAll] = useState(false)
  const [clearSt, setClearSt] = useState(false)
  const [sectionSelect, setSectionSelect] = useState([])
  const [prevSection, setPrevSection] = useState(null)
  const [hightLightTracker, setHightLightTracker] = useState([])
  const [trackerSelect, setTrackerSelect] = useState([])
  const [prevTracker, setPrevTracker] = useState(null)
  const [showProgress, setShowProgress] = useState(true)
  const [trackerList, setTrackerList] = useState([])
  const [d3xScaleyScale, setD3xScaleyScale] = useState({})
  const [hoverActivity, setHoverActivity] = useState(-1)
  const [selectedActivity, setSelectedActivity] = useState("All")
  const [enableMarkProgress, setEnableMarkProgress] = useState(null)
  const [applyStatus, setApplyStatus] = useState(null)
  const [inprogressTrackerTypesModal, setInprogressTrackerTypesModal] = useState(false)
  const [trackerRows, setTrackerRows] = useState([])
  const [piersTorqueTubeIdsWithStatus, setPiersTorqueTubeIdsWithStatus] = useState({})
  const [piersTorqueTubesDetails, setPiersTorqueTubesDetails] = useState({})
  const [inprogressTotalPartCount, setInprogressTotalPartCount] = useState(0)
  const [inprogressDeployedPartCount, setInprogressDeployedPartCount] = useState({});
  const [selectedPiersTorqueTubes, setSelectedPiersTorqueTubes] = useState({ piers: [], torque_tubes: [] })
  const [updateQuantity, setUpdateQuantity] = useState({});
  const [isQtyExceeded, setIsQtyExceeded] = useState(false);
  const [individualTrackerAlertModal, setIndividualTrackerAlertModal] = useState(false)
  const [currentTrackerType, setCurrentTrackerType] = useState(null)
  const [inprogressPierTTStatusForAllSelectedTrackers, setInprogressPierTTStatusForAllSelectedTrackers] = useState({})
  const [saveBtnEnable, setSaveBtnEnable] = useState(false)
  const [btnEnable, setBtnEnable] = useState(false)
  const [listTrackerWiseSelectedTrackers, setListTrackerWiseSelectedTrackers] = useState([])
  const [inprogressIndividualTrackerModal, setInprogressIndividualTrackerModal] = useState({ bool: false, tracker_name: null, bomdata: {}, step: 1 })

  const _ = require("lodash")

  const [listblocks, setDashboard] = useAtom(dashboardAtom)
  const [listId] = useAtom(activitiesAtom)
  const [listPartsAtom] = useAtom(partsAtom)
  const [, setFetchoverallCP] = useAtom(fetchoverallCP)
  const [, setFetchParts] = useAtom(fetchPartsAtom)
  const [, setFetchBomBlock] = useAtom(fetchBomBlock)
  const [, setfetchActivitiesAtom] = useAtom(fetchActivitiesAtom)
  const [, setFetchSelectedTrackerBOM] = useAtom(fetchSelectedTrackerBOM)
  const [, setfetchBlocks] = useAtom(fetchBlocks)
  const [, setTrackerProgress] = useAtom(fetchCpTrackerRowAtom)
  const [, setDeployCount] = useAtom(fetchCount)
  const [, getSelectedPiersTorqueTubesData] = useAtom(getSelectedPiersTorqueTubesDataAtom)
  const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
  const [, setSectionList] = useAtom(fetchListSections)
  const [, setMapSize] = useAtom(fetchMapSize)
  const [, setDeployOverallCount] = useAtom(fetchCountOverall)
  const [, setActivityId] = useAtom(fetchSelectedActId)
  const [, getListOfPiersTorqueTubeIdsWithStatus] = useAtom(getListOfPiersTorqueTubeIdsWithStatusAtom)
  const [, setLoadBulkMarkProgressData] = useAtom(handleLoadMarkProgressBulk)
  const [, setUpdateConstructionProgress] = useAtom(updateConstructionProgressStatus)
  const [, updateInprogressDeployedCount] = useAtom(updateInprogressDeployedCountAtom)
  const [projectsList] = useAtom(projectAtom)
  const [, setFetchProjects] = useAtom(fetchProjectAtom)
  const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
  const [, setFetchBulkPost] = useAtom(fetchBulkDashboardDataPost)
  const params = useParams();

  const [overallProgress, setOverallProgress] = useState(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
  const [selectedRow, setSelectedRow] = useState([])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (overallProgress !== "All" && overallProgress !== "None Selected") {
      handleDeployCounts()
    }
  }, [listId?.construction])

  useEffect(() => {
    if (listblocks?.listOfPiersTorqueTubeIdsWithStatus && listblocks?.listOfPiersTorqueTubeIdsWithStatus[currentTrackerType?.tracker_type_id]) {
      const { piers, torque_tubes } = listblocks?.listOfPiersTorqueTubeIdsWithStatus[currentTrackerType?.tracker_type_id]; // Assuming "3104" is the key you're working with
      // Check if any piers or torque_tubes have status 1
      const isPiersStatusOne = piers.some(pier => pier.status === 1);
      const isTorqueTubesStatusOne = torque_tubes.some(tube => tube.status === 1);
      // Enable button if any status is 1
      if (isPiersStatusOne || isTorqueTubesStatusOne) {
        setSaveBtnEnable(true)
      }
      else {
        setSaveBtnEnable(false)
      }
    }

  }, [listblocks?.listOfPiersTorqueTubeIdsWithStatus])

  const handleDeployCounts = async () => {
    try {
      if (listId?.construction) {
        setLoading(true)
        setActivityId(localStorage.getItem("selected-activity"))
        await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
      }
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
    finally {
      setLoading(false)
    }
  }

  function handleCheckZimmerman() {
    if (projectsList?.type) {
      return true
    }
    else {
      return false
    }
  }

  function qcCheck() {
    let project_name = projectsList.find(p => p.id === parseInt(localStorage.getItem("project_id")))
    return project_name?.project_settings?.qc
  }

  let project_name = projectsList.find(p => p.id === parseInt(localStorage.getItem("project_id")))

  const init = async () => {
    try {
      setLoading(true)
      setFetchoverallCP()
      setCountLoading(true)
      setFetchBulkGet({ id: params.id })
      setfetchActivitiesAtom()
      setFetchBulkPost({ id: params.id })
      setfetchBlocks()
      setMapSize({ id: params.id })
      setFetchParts()
      setFetchProjects()
      setTrackerProgress({ block_id: params.id })
      setLoadBulkMarkProgressData()
      if (localStorage.getItem("selected-activity") !== "None Selected") {
        if (localStorage.getItem("selected-activity") !== "All" && localStorage.getItem("selected-activity") !== null) {
          // setActivityId(localStorage.getItem("selected-activity"))
          // await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
        }
        else {
          setActivityId("All")
          await setDeployOverallCount({ block_id: params.id, tracker: [] })
        }
      }
      if (localStorage.getItem("selected-activity") === "None Selected") {
        setActivityId("None Selected")
      }
      if (["All", "None Selected"].includes(localStorage.getItem("selected-activity"))) {
        setSelectedActivity(localStorage.getItem("selected-activity"))
      }
      else if (!isNaN(localStorage.getItem("selected-activity")) && localStorage.getItem("selected-activity")) {
        setSelectedActivity(parseInt(localStorage.getItem("selected-activity")))
      }
      else {
        setSelectedActivity("All")
      }
      setOverallProgress(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
    }
    catch (err) {
      toast.error("Something went wrong")
      console.log(err)
    } finally {
      setLoading(false)
      setCountLoading(false)
    }
  }

  function groupByTrackerType() {
    const groupedData = {};

    selectedRow?.forEach(row => {
      // Split the string to get the tracker type ID (second index in the array)
      const trackerTypeId = parseInt(row?.split('-')[2]);

      // If the key (trackerTypeId) already exists in the object, push the row string to the array
      if (groupedData[trackerTypeId]) {
        groupedData[trackerTypeId].push(row);
      } else {
        // Otherwise, create a new array with the row string
        groupedData[trackerTypeId] = [row];
      }
    });

    return groupedData;
  }

  const pierTtDetails = (trackerTypeId) => {
    if (listblocks?.blockTtData) {
      let pierTTObj = {}
      let groupedTrackerRows = groupByTrackerType()
      let selectedTrackerTypeTrackerRows = groupedTrackerRows[trackerTypeId]
      let trackerrowId = parseInt(selectedTrackerTypeTrackerRows[0]?.split("-")[1])
      let piersTorqueTubeData = listblocks?.blockTtData
      if (piersTorqueTubeData["piers"][trackerrowId] && piersTorqueTubeData["tt"][trackerrowId]) {
        pierTTObj["piers"] = piersTorqueTubeData["piers"][trackerrowId]
        pierTTObj["torque_tube"] = piersTorqueTubeData["tt"][trackerrowId]
        return { pierTTObj, selectedTrackerTypeTrackerRows };
      }
    }
    return null;
  };

  function findMinMax(nestedData) {
    const allCoords = [];

    // Flattening the nested data
    Object.values(nestedData).forEach(coordsArray => {
      coordsArray.forEach(coord => {
        allCoords.push(coord);
      });
    });

    const latitudes = allCoords.map(coord => coord.latitude);
    const longitudes = allCoords.map(coord => coord.longitude);

    return {
      minimum_latitude: Math.min(...latitudes),
      maximum_latitude: Math.max(...latitudes),
      minimum_longitude: Math.min(...longitudes),
      maximum_longitude: Math.max(...longitudes)
    };
  }

  function hasNullValues(data) {
    if (data) {
      const values = [
        data?.piers?.minimum_latitude,
        data?.piers?.maximum_latitude,
        data?.piers?.minimum_longitude,
        data?.piers?.maximum_longitude,
        data?.tt?.minimum_latitude,
        data?.tt?.maximum_latitude,
        data?.tt?.minimum_longitude,
        data?.tt?.maximum_longitude
      ];

      return values.some(value => value === null);
    }
  }

  useEffect(() => {
    if (listblocks?.mapSize) {
      const d3xScaleyScaleHasNullValue = hasNullValues(listblocks?.mapSize)
      if (d3xScaleyScaleHasNullValue === true) {
        if (listblocks?.blockTtData) {
          const piersMinMax = findMinMax(listblocks?.blockTtData?.piers);
          const ttMinMax = findMinMax(listblocks?.blockTtData?.tt);
          setD3xScaleyScale({ piers: piersMinMax, tt: ttMinMax })
        }
      }
      else {
        setD3xScaleyScale({ piers: listblocks.mapSize?.piers, tt: listblocks.mapSize?.tt })
      }
    }

  }, [listblocks?.mapSize])

  useEffect(() => {
    if (listblocks.blockTtData) {
      sectionCallFn()
    }
  }, [listblocks?.blockTtData])

  const sectionCallFn = async () => {
    try {
      await setSectionList({ data: listblocks.blockTtData.piers })
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    trackerSubmit()
  }, [selectedRow])

  const trackerSubmit = async () => {
    let temp = []
    let hightlightData = []
    selectedRow.map((i) => {
      temp.push(i.split("-")[1])
      hightlightData.push(parseInt(i.split("-")[2]))
    })
    setHightLightTracker(hightlightData.filter((item, index) => hightlightData.indexOf(item) === index))
  }

  useEffect(() => {
    let trackerSort = []
    let prt = []
    if (listblocks?.blocksTracker) {
      listblocks?.blocksTracker?.map((i) => {
        if (handleCheckZimmerman()) {
          prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}`)
        }
        else {
          prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]}`)
        }
      })

      prt = [...new Set(prt)]

      prt.map((i) => {
        let trName = {}
        trName.name = i
        let tcount = 0
        listblocks?.blocksTracker?.map((j) => {
          if (handleCheckZimmerman()) {
            if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
              tcount += j?.count
            }
          }
          else {
            if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
              tcount += j?.count
            }
          }
        })
        trName.count = tcount
        trackerSort.push(trName)
        listblocks?.blocksTracker?.map((j) => {
          let trackerName
          if (handleCheckZimmerman()) {
            trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`
          }
          else {
            trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`
          }
          if (`${i}` === trackerName) {
            let newtrName = {}
            newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
            newtrName.count = j?.count
            newtrName.id = j.tracker_type_id
            trackerSort.push(newtrName)
          }
        })
      })
    }
    setTrackerList(trackerSort)
  }, [listblocks?.blocksTracker])

  const navigate = useNavigate()

  const [modalSecTracIsOpen, setSecTracIsOpen] = useState(false)

  const openSecModal = async () => {
    try {
      setBomLoading(true)
      let tSelectedRow = selectedRow.map((row) => {
        return parseInt(row.split("-")[1])
      })
      let tSelectedRowNo = selectedRow.map((row) => {
        return parseInt(row.split("-")[3])
      })
      let tSelectedSec = selectedRow.map((row) => {
        return parseInt(row.split("-")[4])
      })
      if (selectedRow.length !== 0) {
        await setFetchSelectedTrackerBOM({ block_id: parseInt(params.id), selected_rows: tSelectedRow, tSelectedSec: tSelectedSec, selected_rowsNo: tSelectedRowNo });
      }
      setSecTracIsOpen(true)
      setBomLoading(false)
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
  }

  function afterOpenSecTracModal() {
  }

  function closeSecTracModal() {
    setSecTracIsOpen(false);
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = async () => {
    try {
      setBomLoading(true)
      await setFetchBomBlock({ block_id: params.id })
      setIsOpen(true);
      setBomLoading(false)
    }
    catch (err) {
      toast.error("Something went wrong")
      console.log(err)
    }
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeTrackerModal() {
    setTrackerModal(false)
  }

  function closeInprogressTrackerModal() {
    setInprogressTrackerTypesModal(false)
  }

  function closeInProgressTrackerBomModal() {
    setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 })
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80vw',
      maxHeight: "650px",
      padding: "0px 20px 20px",
      zIndex: 50
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const alertModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '800px',
      maxHeight: "650px",
      padding: "0px 20px 20px",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const trackersCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: "650px",
      padding: "0px 20px 20px"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const inProgressTrackersCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90vw',
      maxWidth: "1400px",
      height: "85vh",
      maxHeight: "1000px",
      padding: "0px 20px 20px"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const inProgressTrackersBomCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80vw',
      maxWidth: "1200px",
      height: "95vh",
      maxHeight: "1200px",
      padding: "0px 20px 20px"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.5)",
    }
  }

  const handleClear = () => {
    setClearSt(!clearSt)
    setSelectAll(false)
    setSectionSelect([])
    setTrackerSelect([])
    setPrevSection(null)
    setPrevTracker(null)
  }

  let block_name = listblocks?.blocks.find((i) => i.id === parseInt(params.id))

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const date = new Date();
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  const formattedDate = `${day}-${month}-${year}`;

  function handleCheckDownloadData() {
    let result = false
    let temp = new Set()
    for (let pr in listblocks?.blockTtData.piers) {
      for (let pierIndex in listblocks?.blockTtData.piers[pr]) {
        let pierData = listblocks?.blockTtData.piers[pr][pierIndex]
        temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
      }
    }
    if (selectedRow.length === Array.from(temp).length) {
      result = true
    }
    return result
  }

  const handleDownloadBlock = async () => {
    try {
      toast("Download queued")
      let arr = []
      listblocks?.bomBlock.map((i) => {
        let temp = {}
        temp["Part Name"] = i.part_name?.toUpperCase()
        temp["Description"] = i.description
        temp["Block Qty"] = i.project_quantity_calc
        temp["Distribution Progress %"] = `${roundOff(i.distribution_progress)}%`
        temp["Distributed Quantity"] = i.distributed_quantity
        temp["Installed Progress %"] = `${roundOff(i.installed_progress)}%`
        temp["Installed Quantity"] = i.installed_quantity
        arr.push(temp)
      })
      let newFields = [
        "Part Name",
        "Description",
        "Required Qty",
        "Distribution Progress %",
        "Distributed Quantity",
        "Installed Progress %",
        "Installed Quantity"
      ]
      let opts = { newFields }
      const parser = new Parser(opts)
      let csv = parser.parse(arr)
      csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", ${block_name?.name} BOM Status\n` + `"Report Date", ${formattedDate}\n` + `"Block", ${block_name?.name}\n` + `\n` + csv
      const element = document.createElement("a")
      const file = new Blob([csv], { type: 'text/csv' })
      element.href = URL.createObjectURL(file)
      element.download = `${project_name?.project_number}_BOM for ${block_name?.name}_${project_name?.name}`
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    catch (err) {
      console.log(err)
      toast.error("Download failed")
    }
  }

  const createWorksheetWithData = (data, customHeaders) => {
    // First, create arrays for custom content
    const headerRows = [
      [`Project Number`, `${project_name?.project_number}`],
      ["Project Name", `${project_name?.name}`],
      ["Report", "Selected Trackers BOM Status"],
      ["Report Date", `${formattedDate}`],
      ['']  // empty row
    ];

    // Convert the data array to array of arrays for easier manipulation
    const dataAsArray = [
      Object.keys(data[0]), // Column headers
      ...data.map(obj => Object.values(obj)) // Data rows
    ];

    // Combine custom headers and data
    const allRows = [...headerRows, ...dataAsArray];

    // Create worksheet from combined arrays
    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    return worksheet;
  };

  const handleDownloadSelectedTracker = async () => {
    try {
      toast("Download queued")
      let arr = []
      let selectedTrackerArr = []
      listblocks?.selectedTrackersBom.map((i) => {
        if (i?.project_quantity_calc) {
          let temp = {}
          let tempTrackers = {}
          tempTrackers["Section"] = i.section
          tempTrackers["Tracker Row"] = i.row_no
          temp["Part Name"] = i.part_name?.toUpperCase()
          temp["Description"] = i.description
          temp["Required Qty"] = i.project_quantity_calc
          temp["Distribution Progress %"] = `${roundOff(i.distribution_progress)}%`
          temp["Distributed Quantity"] = i.distributed_quantity
          temp["Installed Progress %"] = `${roundOff(i.installed_progress)}%`
          temp["Installed Quantity"] = i.installed_quantity
          arr.push(temp)
          selectedTrackerArr.push(tempTrackers)
        }
      })
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Create worksheets with proper data arrangement
      const worksheet1 = createWorksheetWithData(arr);
      const worksheet2 = createWorksheetWithData(selectedTrackerArr);

      // Add worksheets to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet1, 'BOM for selected trackers');
      XLSX.utils.book_append_sheet(workbook, worksheet2, 'Selected trackers');

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, `${project_name?.project_number}_Selected Trackers BOM_${project_name?.name}.xlsx`);
    } catch (error) {
      console.error('Error generating Excel file:', error);
      toast.error("Unable to download file");
    }
  }

  function getConstructionprogressTabActivityStatus() {
    let result = "Construction Progress"
    if (overallProgress !== "All" && overallProgress !== "None Selected") {
      if (listId?.construction) {
        if (listId?.construction[overallProgress] && listId?.construction[overallProgress]?.parent_activity_id) {
          let parent_activity_id = listId?.construction[overallProgress]?.parent_activity_id
          if (parent_activity_id && listId?.construction[parent_activity_id]) {
            result = listId?.construction[parent_activity_id]?.name
          }
        }
      }
    }
    return result
  }

  function checkSubmitBtnEnable() {
    let status = false
    Object.keys(getTrackerWiseDatas())?.map((tracker_name) => {
      if (isRowCompleted(tracker_name)) {
        status = true;
      }
    })
    return status
  }

  useEffect(() => {
    let temp = {}
    selectedRow.map((i) => {
      let tempKey = parseInt(i.split("-")[2])
      let obj = temp[findTrackerName(tempKey)]
      if (!obj) {
        temp[findTrackerName(tempKey)] = 1
      }
      if (obj) {
        temp[findTrackerName(tempKey)] = temp[findTrackerName(tempKey)] + 1
      }
    })
    setListTrackerWiseSelectedTrackers([temp])
  }, [selectedRow])

  function getTrackerWiseDatas() {
    let temp = {}
    selectedRow.map((i) => {
      let tempKey = parseInt(i.split("-")[2])
      let obj = temp[findTrackerName(tempKey)]
      if (!obj) {
        temp[findTrackerName(tempKey)] = 1
      }
      if (obj) {
        temp[findTrackerName(tempKey)] = temp[findTrackerName(tempKey)] + 1
      }
    })
    return temp
  }

  useEffect(() => {
    activityProgressUpdate(selectedActivity)
  }, [selectedActivity])

  const activityProgressUpdate = async (e) => {
    setCountLoading(true)
    try {
      await setActivityId(e)
      localStorage.setItem("selected-activity", e)
      setOverallProgress(e)
      if (listId?.construction && e !== "All" && e !== "None Selected") {
        await setDeployCount({ activity_id: e, part_id: listId?.construction[e]?.part_id, block_id: params.id, tracker: [] })
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setCountLoading(false)
    }
  }

  const handleChangeMarkProgressToggle = (value) => {
    console.log(value)
    if (enableMarkProgress === value) {
      setEnableMarkProgress(null)
    }
    else {
      setEnableMarkProgress(value)
    }
  }

  const handleClickActProgress = async (e) => {
    setSelectedActivity(e)
  }

  const handleSelectApplyStatus = (data) => {
    if (applyStatus === data) {
      setApplyStatus(null)
    }
    else {
      setApplyStatus(data)
    }
  }

  const handleClickMarkProgressApply = async () => {
    try {
      if (["Not Started", "Completed"].includes(applyStatus)) {
        setApplyProgressLoader(true)
        let checkActivityType
        let cpdata = []
        if (listId?.construction[selectedActivity]?.parent_activity_id) {
          checkActivityType = "sub_activity"
        }
        else {
          checkActivityType = "parent_activity"
        }
        for (let i of selectedRow) {
          if (checkActivityType === "parent_activity") {
            for (let actId in listId?.construction) {
              if (listId?.construction[actId].parent_activity_id === parseInt(selectedActivity) || parseInt(actId) === parseInt(selectedActivity)) {
                let temp = await UpdateCp(actId, i)
                cpdata.push(temp);
              }
            }
          }
          else {
            let parentActivityId = listId?.construction[selectedActivity]?.parent_activity_id
            let selectedActivityData = await UpdateCp(selectedActivity, i)
            cpdata.push(selectedActivityData);
            let parentActivityData = await UpdateCp(selectedActivity, i, checkActivityType, parentActivityId)
            cpdata.push(parentActivityData);
          }
        }
        await setUpdateConstructionProgress({ block_id: params.id, dataArr: cpdata })
        await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
        await setFetchBulkPost({ id: params.id })
        toast.success("Progress Updated")
        setSelectedRow([])
        setSectionSelect([])
        setPrevSection(null)
        setTrackerSelect([])
        setHightLightTracker([])
      }
      else {
        setInprogressTrackerTypesModal(true)
      }
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
    finally {
      setApplyProgressLoader(false)
    }
  }

  const UpdateCp = async (selected_activity_id, selectedTrackerrow, activityType, parentActivityId) => {
    let statusValue = 0
    if (applyStatus === "Not Started") {
      statusValue = 0
    }
    if (applyStatus === "In-Progress") {
      statusValue = 1
    }
    if (applyStatus === "Completed") {
      statusValue = 2
    }
    let updatedData = {}
    let bomData = await get_distributed_and_installed_bom_for_activity(selected_activity_id, selectedTrackerrow, listblocks?.distributedInstalledBom, listblocks?.total_installed_distributed_quantity, listblocks?.existing_distributed_installed_quantity, false, {})

    let foundType = ""
    if (bomData) {
      if (Object.keys(bomData).length === 1) {
        for (let i in bomData) {
          foundType = i

        }
      } else {
        foundType = "distributed_installed"
      }
    }
    console.log("bomData", bomData)
    console.log("parentActivityId", parentActivityId)
    console.log("selected_activity_id", selected_activity_id)
    updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(
      listblocks?.blockTtData,
      {
        block_id: params.id,
        block: block_name?.name
      },
      bomData,
      selected_activity_id,
      listblocks?.updateDistributedAndInstalledQuantity,
      parseInt(statusValue),
      foundType,
      listblocks?.part_per_tracker,
      listId?.construction,
      selectedTrackerrow,
      listblocks?.inprogressDeployedCount, //user entered value in-progress
      inprogressPierTTStatusForAllSelectedTrackers,
      false,
      // isZimmerman,
      activityType,
      parentActivityId,
      listblocks?.cpTrackerRowWise
    )

    console.log("updatedData", updatedData)

    // if (isZimmerman === true) {
    //   updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.zimmermanBulkPiers, blockDetails, bomData, selected_activity_id, dashboard?.updateDistributedAndInstalledQuantity, parseInt(status), foundType, dashboard?.part_per_tracker, listId?.construction, selectedTrackerrow, dashboard?.inprogressDeployedCount, dashboard?.listOfZimmermanPierIdsWithStatus, isZimmerman, activityType, parentActivityId, listblocks?.)
    // } else {
    //   updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.bulkPiersTorqueTubesBlockData, blockDetails, bomData, selected_activity_id, dashboard?.updateDistributedAndInstalledQuantity, parseInt(status), foundType, dashboard?.part_per_tracker, listId?.construction, selectedTrackerrow, dashboard?.inprogressDeployedCount, dashboard?.listOfPiersTorqueTubeIdsWithStatus, isZimmerman, activityType, parentActivityId, listblocks?.)
    // }
    return updatedData
  }

  useEffect(() => {
    if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus).length > 0)
      updateListOfPiersTorqueTubeIdsWithStatusForAllTrackers()
  }, [listblocks?.listOfPiersTorqueTubeIdsWithStatus])

  const updateListOfPiersTorqueTubeIdsWithStatusForAllTrackers = () => {
    let updatedListOfPiersTorqueTubeIdsWithStatusForAllTrackers = get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers(listblocks?.blockTtData, selectedRow, listblocks?.listOfPiersTorqueTubeIdsWithStatus)
    setInprogressPierTTStatusForAllSelectedTrackers(updatedListOfPiersTorqueTubeIdsWithStatusForAllTrackers)
  }

  const getDeployedPartCountOfSelectedTrackerrow = () => {
    let deployedCount = 0
    if (selectedRow?.length === 1 && selectedActivity !== "None Selected") {
      let trackerrowId_activityId_key = `${selectedRow[0].split("-")[1]}_${JSON.parse(selectedActivity)}`
      if (listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]) {
        deployedCount = listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["deployed_part_count"]
      }
    }
    return deployedCount
  }

  const getInprogressDefaultValue = () => {
    let deployedCount = 0
    if (Object.keys(listblocks?.inprogressDeployedCount).length > 0 && listblocks?.inprogressDeployedCount[currentTrackerType?.tracker_type_id]) {
      deployedCount = listblocks?.inprogressDeployedCount[currentTrackerType?.tracker_type_id]
    }
    if (Object.keys(listblocks?.inprogressDeployedCount).length === 0 && listblocks?.inprogressDeployedCount[currentTrackerType?.tracker_type_id] === undefined) {
      deployedCount = getDeployedPartCountOfSelectedTrackerrow()
    }
    console.log("deployedCount", deployedCount)
    return deployedCount
  }

  useEffect(() => {
    console.log("selectedPiersTorqueTubes", selectedPiersTorqueTubes)
    let selectedTrackerrow = trackerRows
    let activityId = selectedActivity
    let PiersTorqueTubes = {}
    let piers = []
    let torqueTubes = []
    let pierIds = []
    let torqueTubeIds = []
    let trackerTypeId = parseInt(selectedTrackerrow[0]?.split("-")[2])
    let trackerrowId_activityId_key = `${selectedTrackerrow[0]?.split("-")[1]}_${parseInt(activityId)}`
    if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus).length !== 0 && listblocks?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]) {
      let prData = listblocks?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]["piers"]
      PiersTorqueTubes["piers"] = prData
      if (prData !== undefined) {
        for (let i of prData) {
          if (i?.status === 1) {
            pierIds.push(i?.pier_id)
          }
        }
      }
      let ttData = listblocks?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]
      PiersTorqueTubes["torque_tubes"] = ttData
      if (ttData !== undefined) {
        for (let j of ttData) {
          if (j?.status === 1) {
            torqueTubeIds.push(j?.torque_tube_id)
          }
        }
      }

    } else {
      if (selectedTrackerrow?.length === 1 && listblocks?.cpTrackerRowWise !== undefined && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key] && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
        let pierData = listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["piers"]
        PiersTorqueTubes["piers"] = pierData
        for (let i of pierData) {
          if (i?.status === 1) {
            pierIds.push(i?.pier_id)
          }
        }
      }
      else {
        if (piersTorqueTubesDetails["piers"]) {
          for (let i of piersTorqueTubesDetails["piers"]) {
            piers.push({ pier_id: i?.id, status: 0 })

          }
          PiersTorqueTubes["piers"] = piers
        }
      }

      if (selectedTrackerrow?.length === 1 && listblocks?.cpTrackerRowWise !== undefined && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key] && listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["torque_tubes"]?.length > 0) {
        let ttData = listblocks?.cpTrackerRowWise[trackerrowId_activityId_key]["torque_tubes"]
        PiersTorqueTubes["torque_tubes"] = ttData
        for (let j of ttData) {
          if (j?.status === 1) {
            torqueTubeIds.push(j?.torque_tube_id)
          }
        }
      } else {
        if (piersTorqueTubesDetails["torque_tube"]) {
          for (let i of piersTorqueTubesDetails["torque_tube"]) {
            torqueTubes.push({ torque_tube_id: i?.id, status: 0 })
          }
          PiersTorqueTubes["torque_tubes"] = torqueTubes
        }
      }
    }
    let pierTTStatusObj = {}
    pierTTStatusObj[trackerTypeId] = PiersTorqueTubes
    setSelectedPiersTorqueTubes({ piers: pierIds, torque_tubes: torqueTubeIds })
    setPiersTorqueTubeIdsWithStatus(pierTTStatusObj)
  }, [trackerRows, listblocks?.cpTrackerRowWise])

  console.log("piersTorqueTubeIdsWithStatus", piersTorqueTubeIdsWithStatus)

  useEffect(() => {
    if (Object.keys(piersTorqueTubeIdsWithStatus) !== 0) {
      getCompletedPiersAndTorqueTubes()
    }
  }, [piersTorqueTubeIdsWithStatus])

  const getCompletedPiersAndTorqueTubes = async () => {
    let data = {}
    if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus)?.length === 0) {
      data = piersTorqueTubeIdsWithStatus
    } else {
      data = { ...listblocks?.listOfPiersTorqueTubeIdsWithStatus, ...piersTorqueTubeIdsWithStatus }
    }
    try {
      console.log("data", data)
      await getListOfPiersTorqueTubeIdsWithStatus(data)
    } catch (err) {
      console.log(err)
    }
  }

  const isRowCompleted = (tracker_name) => {
    let row = listblocks?.blocksTracker.find(o => o?.tracker_type === tracker_name)
    let hasDeployedCount = false;
    if (listblocks?.inprogressDeployedCount && listblocks?.inprogressDeployedCount[row?.tracker_type_id]) {
      hasDeployedCount = listblocks?.inprogressDeployedCount[row?.tracker_type_id] !== 0;
    }

    let hasColorsEntered = false;
    if (listblocks?.listOfPiersTorqueTubeIdsWithStatus &&
      listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id] &&
      listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id]?.piers &&
      listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id]?.torque_tubes) {

      const allPiersCompleted = listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id].piers.some(pier => pier.status === 1);
      const allTorqueTubesCompleted = listblocks?.listOfPiersTorqueTubeIdsWithStatus[row?.tracker_type_id].torque_tubes.some(tt => tt.status === 1);
      hasColorsEntered = allPiersCompleted || allTorqueTubesCompleted;
    }

    return hasDeployedCount && hasColorsEntered;
  };

  useEffect(() => {
    if (trackerRows.length !== 0) {
      let trackerTypeId = trackerRows[0]?.split("-")[2]
      if (Object.keys(listblocks?.selectedPiersTorqueTubesData).length > 0 && (listblocks?.selectedPiersTorqueTubesData["piers"] || listblocks?.selectedPiersTorqueTubesData["torque_tubes"])) {
        if (piersTorqueTubeIdsWithStatus[trackerTypeId]) {
          if ((piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"] && piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]) && (piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"].length > 0 && piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"].length > 0)) {
            setSelectedPiersTorqueTubes(listblocks?.selectedPiersTorqueTubesData)
            handleSelectPierAndTorqueTubeItem()
          }
        }
      }
    }
  }, [listblocks?.selectedPiersTorqueTubesData])

  useEffect(() => {
    getSelectedPiersTorqueTubesData(selectedPiersTorqueTubes)
  }, [selectedPiersTorqueTubes])

  const handleSelectPierAndTorqueTubeItem = async () => {
    let temp = {}
    let trackerTypeId = trackerRows[0]?.split("-")[2]
    if (listblocks?.selectedPiersTorqueTubesData["piers"]) {
      let piersDataWithStatus = piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"]
      let selectedPierData = listblocks?.selectedPiersTorqueTubesData["piers"]
      let updatedPiers = []
      for (let i of piersDataWithStatus) {
        if (selectedPierData.includes(i?.pier_id)) {
          updatedPiers.push({ ...i, status: 1 })
        } else {
          updatedPiers.push({ ...i, status: 0 })
        }
      }
      temp["piers"] = updatedPiers
    }

    if (listblocks?.selectedPiersTorqueTubesData["torque_tubes"]) {
      let torqueTubesDataWithStatus = piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]

      let selectedTorqueTubeData = listblocks?.selectedPiersTorqueTubesData["torque_tubes"]
      let updatedTorqueTubes = []
      for (let i of torqueTubesDataWithStatus) {
        if (selectedTorqueTubeData.includes(i?.torque_tube_id)) {
          updatedTorqueTubes.push({ ...i, status: 1 })
        } else {
          updatedTorqueTubes.push({ ...i, status: 0 })
        }
      }

      temp["torque_tubes"] = updatedTorqueTubes

    }
    let sortedData = sortPierAndTTData(temp)
    console.log(sortedData)
    let pierTTStatusObj = {}
    pierTTStatusObj[trackerTypeId] = sortedData
    console.log(pierTTStatusObj)
    setPiersTorqueTubeIdsWithStatus(pierTTStatusObj)
  }

  function sortPierAndTTData(data) {
    // Sort piers based on pier_id
    data.piers.sort((a, b) => a.pier_id - b.pier_id);

    // Sort torque_tubes based on torque_tube_id
    data.torque_tubes.sort((a, b) => a.torque_tube_id - b.torque_tube_id);

    return data;
  }

  const handleSaveInProgressSteps = () => {
    //selectedPiersTorqueTubes
    console.log("piersTorqueTubeIdsWithStatus", piersTorqueTubeIdsWithStatus)
    // getListOfPiersTorqueTubeIdsWithStatus(piersTorqueTubeIdsWithStatus)
    setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 })
  }

  const handleCloseWithoutSaving = () => {
    let trackerTypeId = currentTrackerType
    if (trackerTypeId) {

      // Update inprogressDeployedCount

      setDashboard(produce((draft) => {
        let existingInprogressDeployedCountData = { ...draft.inprogressDeployedCount }
        delete existingInprogressDeployedCountData[trackerTypeId]
        draft.inprogressDeployedCount = existingInprogressDeployedCountData
      }))

      // Update listOfPiersTorqueTubeIdsWithStatus

      setDashboard(produce((draft) => {
        let existingPiersTorqueTubeIdsWithStatusData = { ...draft.listOfPiersTorqueTubeIdsWithStatus }
        delete existingPiersTorqueTubeIdsWithStatusData[trackerTypeId]
        draft.listOfPiersTorqueTubeIdsWithStatus = existingPiersTorqueTubeIdsWithStatusData
      }))

      //Update DistributedInstalledQuantity


      setDashboard(produce((draft) => {
        let existingDistributedInstalledQuantityData = { ...draft.updateDistributedAndInstalledQuantity }

        // Get all tracker rows associated with this tracker type
        const associatedTrackerRows = selectedRow.filter(row => row.split('-')[2] === trackerTypeId.toString())
          .map(row => row.split('-')[1]);

        // Remove entries for all associated tracker rows
        Object.keys(existingDistributedInstalledQuantityData).forEach(key => {
          const [bomId, trackerRowId] = key.split('_');
          if (associatedTrackerRows.includes(trackerRowId)) {
            delete existingDistributedInstalledQuantityData[key];
          }
        });

        draft.updateDistributedAndInstalledQuantity = existingDistributedInstalledQuantityData
      }))

      // Update local states
      setInprogressDeployedPartCount((prev) => {
        const updated = { ...prev };
        delete updated[trackerTypeId];
        return updated;
      });

      setUpdateQuantity((prev) => {
        const updated = { ...prev };
        delete updated[trackerTypeId];
        return updated;
      });
    }
  }

  useEffect(() => {
    const updateQuantities = async () => {
      const groupedData = groupByTrackerType();
      let allUpdates = {};
      for (const [trackerTypeId, quantities] of Object.entries(updateQuantity)) {
        const updatedForTrackerType = await updated_progress_quantity_for_all_selected_trackerrows(
          quantities,
          groupedData[trackerTypeId]
        );
        allUpdates = { ...allUpdates, ...updatedForTrackerType };
      }
      if (Object.keys(allUpdates).length > 0) {
        await updateDistributedAndInstalledQuantity(allUpdates);
      }
    };

    updateQuantities();
  }, [updateQuantity]);

  const handleClickInProgressMarkProgress = async (tracker_name) => {
    let temp = listblocks?.blocksTracker.find(o => o?.tracker_type === tracker_name)
    setCurrentTrackerType(temp)
    let currentSelectedTrackerTypes
    selectedRow.map((i) => {
      let trackerTemp = i.split("-")[2]
      if (parseInt(trackerTemp) === temp?.tracker_type_id) {
        currentSelectedTrackerTypes = i
      }
    })
    let bomData = await get_distributed_and_installed_bom_for_activity(
      selectedActivity,
      currentSelectedTrackerTypes,
      listblocks?.distributedInstalledBom,
      listblocks?.total_installed_distributed_quantity,
      listblocks?.existing_distributed_installed_quantity,
      false,
      {}
    );
    console.log("bomData", bomData)

    // let existingDeployedCount = getInprogressDefaultValue()
    // let updatedObj = {
    //   ...listblocks.inprogressDeployedCount,
    //   [temp?.tracker_type_id]: existingDeployedCount
    // };

    // console.log("load existing deployed count", updatedObj)
    // updateInprogressDeployedCount(updatedObj);

    const result = pierTtDetails(temp?.tracker_type_id);
    if (result) {
      setPiersTorqueTubesDetails(result.pierTTObj);
      setTrackerRows(result.selectedTrackerTypeTrackerRows);
    }

    // Load the stored inprogressCount
    const storedCount = listblocks.inprogressDeployedCount[temp.tracker_type_id] || 0;
    setInprogressDeployedPartCount(prev => ({
      ...prev,
      [temp.tracker_type_id]: storedCount
    }));

    const isValid = storedCount > 0 && storedCount <= inprogressTotalPartCount;
    setBtnEnable(isValid);

    if (selectedActivity !== "All" && selectedActivity !== "None Selected") {
      let tracker_type_id = parseInt(temp?.tracker_type_id);
      let partId_trackertypeId_key = `${listId?.construction[selectedActivity]?.part_id}_${tracker_type_id}`
      let totalPartCount = 0
      if (listblocks?.part_per_tracker[partId_trackertypeId_key]) {
        totalPartCount = listblocks?.part_per_tracker[partId_trackertypeId_key]
      }
      setInprogressTotalPartCount(parseInt(totalPartCount))
    }
    setInprogressIndividualTrackerModal({ bool: true, tracker_name: tracker_name, bomdata: bomData, step: 1 })
  }

  const handleSubmitInProgress = async () => {
    try {
      setInProgressSubmitLoader(true)
      let cpdata = [];
      let checkActivityType = "";
      for (let i of selectedRow) {
        if (Object.keys(listblocks?.listOfPiersTorqueTubeIdsWithStatus)?.includes(i?.split("-")[2].toString())) {
          if (
            listId.construction[selectedActivity]?.parent_activity_id === null
          ) {
            checkActivityType = "parent_activity";
          } else {
            checkActivityType = "sub_activity";
          }
          if (checkActivityType === "parent_activity") {
            for (let actId in listId?.construction) {
              if (listId.construction[actId].parent_activity_id === parseInt(selectedActivity) || parseInt(actId) === parseInt(selectedActivity)) {
                let temp = await UpdateCp(actId, i)
                cpdata.push(temp);
              }
            }
          } else {
            let parentActivityId = listId?.construction[selectedActivity]?.parent_activity_id
            let selectedActivityData = await UpdateCp(selectedActivity, i)
            cpdata.push(selectedActivityData);
            let parentActivityData = await UpdateCp(selectedActivity, i, checkActivityType, parentActivityId)
            cpdata.push(parentActivityData);
          }
        }
      }
      console.log("cpdata", cpdata)
      await setUpdateConstructionProgress({ block_id: params.id, dataArr: cpdata })
      handleClickMarkProgressApply()
      await updateInprogressDeployedCount({})
      await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
      await setFetchBulkPost({ id: params.id })
      setInprogressTrackerTypesModal(false)
      setInprogressIndividualTrackerModal({ bool: false, tracker_name: null, bomdata: {}, step: 1 })
      setInprogressDeployedPartCount({})
      getListOfPiersTorqueTubeIdsWithStatus({})
      setSelectedRow([])
      setSectionSelect([])
      setPrevSection(null)
      setTrackerSelect([])
      setHightLightTracker([])
      toast.success("Progress Updated")
    }
    catch (err) {
      console.log(err);
      toast.error("Something went wrong")
    }
    finally {
      setInProgressSubmitLoader(false)
    }
  }

  function checkMarkProgressEnable() {
    let result = true
    if (selectedRow?.length > 0) {
      if (selectedActivity !== "All" && selectedActivity !== "None Selected") {
        if (applyStatus === "Not Started" || applyStatus === "Completed") {
          result = false
        }
        if (applyStatus === "In-Progress" && listId?.construction && listId?.construction[selectedActivity]?.parent_activity_id) {
          result = false
        }
      }
    }
    return result
  }

  const findTrackerName = (data) => {
    let name = listblocks?.trackerName.find(k => k?.id === data)
    return name?.tracker_type
  }

  let CPparActIds = []
  let QCparActIds = []

  if (listblocks?.overallCP) {
    for (let cp in listblocks?.overallCP) {
      if (listId?.construction && !listId.construction[cp]?.parent_activity_id) {
        CPparActIds.push(cp)
      }
    }
  }

  if (listblocks.blocksQC) {
    listblocks.blocksQC.map((qc) => {
      if (listId?.qc && !listId.qc[qc.activity_id]?.parent_activity_id) {
        QCparActIds.push(qc.activity_id)
      }
    })
  }

  let finalCPArr = []
  let finalQCArr = []

  CPparActIds.map((pt) => {
    finalCPArr.push(parseInt(pt))
    if (listId?.construction) {
      Object.values(listId?.construction)?.map((ij) => {
        if (ij.parent_activity_id === parseInt(pt)) {
          finalCPArr.push(ij.id)
        }
      })
    }
  })

  QCparActIds.map((pt) => {
    finalQCArr.push(parseInt(pt))
    Object.values(listId?.qc)?.map((ij) => {
      if (ij.parent_activity_id === parseInt(pt)) {
        finalQCArr.push(ij.id)
      }
    })
  })

  const findCPprogressCalc = (data) => {
    let temp = 0
    if (listblocks.blocksCP) {
      listblocks.blocksCP.map((i) => {
        if (i.activity_id === data) {
          temp = i.progress
        }
      })
    }
    return temp
  }

  const findCPprogress = (data) => {
    let temp = 0
    if (listblocks.blocksCP) {
      listblocks.blocksCP.map((i) => {
        if (i.activity_id === data) {
          temp = i.progress
        }
      })
    }
    if (listId?.construction && listId?.construction[data] && listId?.construction[data]?.parent_activity_id) {
      return temp
    }
    else {
      let sub_weight_progress = []
      let sub_weight = []
      if (listId?.construction) {
        for (let actKey in listId?.construction) {
          if (listId?.construction && listId?.construction[actKey]?.parent_activity_id === data) {
            sub_weight_progress.push(listId.construction[actKey]?.weight_decimal * findCPprogress(parseInt(actKey)))
            sub_weight.push(listId.construction[actKey]?.weight_decimal)
          }
        }
      }
      let progressData = sub_weight_progress.reduce((partialSum, a) => partialSum + a, 0) / sub_weight.reduce((partialSum, a) => partialSum + a, 0)
      return progressData
    }
  }

  const findQCprogress = (data) => {
    let temp = 0
    if (listblocks.blocksQC) {
      listblocks.blocksQC.map((i) => {
        if (i.activity_id === data) {
          temp = i.progress
        }
      })
    }
    return temp
  }

  const ttlOverAllPercent = () => {
    let subList = []
    let subWight = []
    if (listId?.construction) {
      Object?.keys(listId?.construction)?.map((i) => {
        if (listId?.construction[i]?.parent_activity_id) {
          subWight.push(listId?.construction[i]?.weight)
          subList.push(listId?.construction[i]?.weight * findCPprogressCalc(parseInt(i)))
        }
      })
    }
    let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
    if (isNaN(temp)) {
      return 0
    }
    else {
      return temp
    }
  }

  const ttlOverAllQCPercent = () => {
    let subList = []
    let subWight = []
    if (listId.qc) {
      Object?.keys(listId?.qc)?.map((i) => {
        if (listId?.qc[i]?.parent_activity_id) {
          subWight.push(listId?.qc[i]?.weight)
          subList.push(listId?.qc[i]?.weight * findQCprogress(parseInt(i)))
        }
      })
    }
    let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
    if (isNaN(temp)) {
      return 0
    }
    else {
      return temp
    }
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    if (listblocks?.sectionList) {
      setSectionSelect(listblocks?.sectionList)
      setPrevSection(null)
    }
    setPrevTracker(null)
    let temp = []
    trackerList.map((i) => {
      if (i?.id) {
        temp.push(i?.id)
      }
    })
    setTrackerSelect(temp)
  }

  useEffect(() => {
    let temp = {}
    selectedRow.map((i) => {
      if (isNaN(temp[i.split("-")[2]])) {
        temp[i.split("-")[2]] = 1
      }
      else {
        temp[i.split("-")[2]] = temp[i.split("-")[2]] + 1
      }
    })
    trackerList.map((i) => {
      if (temp[i?.id] === i?.count) {
        if (!trackerSelect.includes(i.id)) {
          setTrackerSelect(produce((draft) => { draft.push(i.id) }))
        }
      }
      else if (temp[i?.id] !== i?.count && trackerSelect.includes(i.id) && temp[i?.id]) {
        let index = trackerSelect.findIndex(o => o === i?.id)
        setTrackerSelect(produce((draft) => { draft.splice(index, 1) }))
      }
      else if (trackerSelect.includes(i?.id) && !temp[i?.id]) {
        let tempData = [...trackerSelect]
        let data = tempData.filter(o => o?.id === i?.id)
        setTrackerSelect(data)
      }
    })
  }, [selectedRow])

  const handleSectionSelect = (data) => {
    if (sectionSelect.includes(data)) {
      setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
      setPrevSection(data)
    }
    else {
      setSectionSelect(produce((draft) => { draft.push(data) }))
      setPrevSection(null)
    }
  }

  const handleTrackerRowSelection = (dataTT) => {
    if (trackerSelect.includes(dataTT.id)) {
      setTrackerSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === dataTT.id), 1) }))
      setPrevTracker(dataTT.id)
    }
    else {
      setTrackerSelect(produce((draft) => { draft.push(dataTT.id) }))
      setPrevTracker(null)
    }
  }

  return (
    <Container>
      <div className='flex justify-between overflow-auto w-full h-full'>
        <div className="mr-2 h-full" style={{ width: showProgress ? "calc(100vw - 620px)" : "100%" }}>
          <div className='my-4 py-0.5 flex justify-between'>
            <div className='font-semibold mx-5' style={{ textTransform: "capitalize" }}>
              Construction - {selectedActivity === "All" ?
                "Overall"
                :
                selectedActivity !== "None selected" ?
                  listId?.construction && listId?.construction[selectedActivity]?.name
                  :
                  null
              } Progress
            </div>
            <div className='flex items-center'>
              {!handleCheckZimmerman() &&
                <div className='flex items-center flex-wrap justify-end space-x-4'>
                  <SelectBoxView onChange={(e) => setShowTT(e)} flag={project_name?.project_settings} />
                  <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' style={{ marginTop: "-5px" }} id="download">
                    <CgSoftwareDownload className='mr-1' />Download
                  </button>
                </div>
              }
              {!showProgress && (
                <div className='text-xl text-black border bg-gray-200 mx-2'>
                  <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowProgress(true)} />
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className='flex justify-between items-center space-x-5'>
            <div className='flex pt-1.5'>
              <BiArrowBack className='cursor-pointer mx-5 mt-0.5' style={{ width: "20px", height: "20px" }} onClick={() => navigate("/dashboard")} />
              <p className='font-semibold'>{block_name?.name}</p>
            </div>
            <div className='flex space-x-5'>
              <div className='flex justify-end mb-5'>
                <div className='ml-5 mt-2'>
                  <p className='text-gray-400 text-sm font-semibold'>Section</p>
                  {loading ?
                    <p className='loader m-auto mt-2' style={{ width: "25px", height: "25px" }}></p>
                    :
                    <div className='flex flex-wrap justify-left text-center max-w-[500px] max-h-[70px] overflow-y-auto'>
                      {listblocks?.sectionList.map((section) => {
                        return (
                          <p className={`cursor-pointer m-1 w-10 py-1 text-xs ${sectionSelect?.includes(section) ? "bg-red-550 text-white border-red-550 border" : "bg-white text-red-550 border"}`} onClick={() => handleSectionSelect(section)}>{section}</p>
                        )
                      })}
                    </div>
                  }
                </div>
              </div>
              <div className='flex items-center ml-2 mr-1'>
                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleSelectAll}>Select All</p>
              </div>
              <div className='flex items-center ml-2 mr-1'>
                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedRow?.length > 0} onClick={handleClear}>Clear Selection</p>
              </div>
            </div>
          </div>
          <hr />
          {loading ?
            <div style={{ marginTop: "20%", paddingLeft: "50%", width: "calc(100vw - 666px)" }}>
              <p className='loader ml-5 mb-1'></p>
              <p className='font-bold text-xl'>Fetching...</p>
            </div>
            :
            <div className='cursor-grab m-auto' style={{ width: "98%", height: "66vh" }}>
              <BlockMap
                zimmerMan={handleCheckZimmerman()}
                deviceType="web"
                bulkAllData={listblocks?.blockTtData}
                pierLegends={listblocks?.pierLegends}
                type="CP"
                typeData={listblocks.cpTrackerRowWise}
                userSelection={{ showType: showTT, selection: listblocks.selectedActivity }}
                currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(params.id))}
                currentProjectData={projectsList.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                downloadD3={true}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                showLegend={true}
                mapSize={d3xScaleyScale}
                selectAll={selectAll}
                section={sectionSelect}
                prevSection={prevSection}
                trackerSelect={trackerSelect}
                prevTracker={prevTracker}
                clearSelection={clearSt}
                controls={true}
              />
            </div>
          }

        </div>
        {showProgress && <div className='border-x h-full'>
          <div className="overflow-auto relative h-full pr-2" style={{ height: "calc(100vh - 100px)", width: "600px" }}>
            <div>
              <div className='flex w-full justify-between items-center bg-white my-6 text-blue-500 font-semibold text-sm px-6'>
                <div className='flex space-x-2'>
                  <div className='text-xl text-black border bg-gray-200 mx-2'>
                    <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowProgress(false)} />
                  </div>
                  <div className='flex'>
                    {selectedRow && selectedRow?.length > 0 ?
                      <>
                        {handleCheckDownloadData() ?
                          <div className='mr-10' onClick={openModal}>
                            <div className='flex cursor-pointer'>
                              <p>BOM Status for {block_name?.name}</p>
                              {bomLoading ?
                                <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                :
                                <BsBoxArrowUpRight alt='' className='ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                              }
                            </div>
                          </div>
                          :
                          <div onClick={openSecModal}>
                            <div className='flex cursor-pointer'>
                              {handleCheckZimmerman() ?
                                <p>BOM Status for Selected Tables</p>
                                :
                                <p>BOM Status for Selected Trackers</p>
                              }
                              {bomLoading ?
                                <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                :
                                <BsBoxArrowUpRight className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                              }
                            </div>
                          </div>
                        }
                      </>
                      :
                      <div className='text-gray-300'>
                        <div className='flex'>
                          {handleCheckZimmerman() ?
                            <p>BOM Status for Selected Tables</p>
                            :
                            <p>BOM Status for Selected Trackers</p>
                          }
                          <BsBoxArrowUpRight className='ml-1' style={{ width: '17px', height: '17px' }} />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer py-0" onChange={e => handleChangeMarkProgressToggle(e.target.value)}>
                    <input type="checkbox" className="toggle toggle-xs toggle-error" />
                    <span className={`label-text text-sm text-gray-500`}>Mark Progress</span>
                  </label>
                </div>
              </div>

              <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '550px' }}>
                <div>
                  {hoverActivity === "All" && !loading ?
                    <div className='flex justify-between font-bold text-base w-[530px] cursor-pointer absolute z-10 bg-white py-1 px-1 border' style={{ textTransform: "capitalize" }} onMouseEnter={() => setHoverActivity("All")} onMouseLeave={() => setHoverActivity(-1)} onClick={() => handleClickActProgress("All")}>
                      <p>Construction Progress</p>
                      <p className='text-red-550'>
                        {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                          {ttlOverAllPercent()}%</>}
                      </p>
                    </div>
                    :
                    null
                  }
                  {selectedActivity === "All" && !loading ?
                    <div className='flex justify-between font-bold text-base w-[530px] cursor-pointer absolute z-10 bg-white py-1 px-1 border-2 border-red-550' style={{ textTransform: "capitalize", boxShadow: "2px 5px #f5a6a6" }} onClick={() => handleClickActProgress("None Selected")}>
                      <p>Construction Progress</p>
                      <p className='text-red-550'>
                        {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                          {ttlOverAllPercent()}%</>}
                      </p>
                    </div>
                    :
                    null
                  }
                  <div className='flex justify-between font-bold text-base' onMouseEnter={() => setHoverActivity("All")} onMouseLeave={() => setHoverActivity(-1)}>
                    <p>Construction Progress</p>
                    <p className='text-red-550'>
                      {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                        {ttlOverAllPercent()}%</>}
                    </p>
                  </div>
                  <div className='font-medium text-xs mt-3'>
                    {loading ?
                      <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                        <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                        <p className='font-medium text-sm'>Fetching...</p>
                      </div>
                      :
                      finalCPArr.map((i) => {
                        return (
                          <div className='relative' onMouseEnter={() => setHoverActivity(i)} onMouseLeave={() => setHoverActivity(-1)}>
                            {hoverActivity === i && selectedActivity !== i ?
                              <div className='flex justify-between cursor-pointer w-full absolute z-10 bg-white py-1 px-1 border' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }} onClick={() => handleClickActProgress(i)}>
                                {listId.construction[i]?.parent_activity_id ?
                                  <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                  :
                                  <p className='font-extrabold max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                }
                                <div className='flex justify-between w-[50%]'>
                                  <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                  <p>{roundOff(findCPprogress(i))}%</p>
                                </div>
                              </div>
                              :
                              null
                            }
                            {selectedActivity !== "All" && selectedActivity !== "None Selected" && selectedActivity === i ?
                              <div className='flex flex-col space-y-3 w-full absolute z-9 bg-white py-1 px-1 border-2 border-red-550' style={{ textTransform: "capitalize" }}>
                                <div className='flex cursor-pointer justify-between' onClick={() => { handleClickActProgress("None Selected"); setOverallProgress("None Selected"); setActivityId("None Selected"); localStorage.setItem("selected-activity", "None Selected") }}>
                                  {listId.construction[i]?.parent_activity_id ?
                                    <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                    :
                                    <p className='font-extrabold max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                  }
                                  <div className='flex justify-between w-[50%]'>
                                    <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                    <p>{roundOff(findCPprogress(i))}%</p>
                                  </div>
                                </div>
                                <div className='flex justify-end cursor-pointer' onClick={() => { handleClickActProgress("None Selected"); setOverallProgress("None Selected"); setActivityId("None Selected"); localStorage.setItem("selected-activity", "None Selected") }}>
                                  {countLoading ?
                                    <p className='loader' style={{ width: "15px", height: "15px" }}></p>
                                    :
                                    <div className='flex space-x-1'>
                                      <p className=''>{listblocks?.count?.deployed_part_count && listblocks?.count?.deployed_part_count[0]?.deployed_count !== null ? listblocks?.count?.deployed_part_count[0]?.deployed_count?.toLocaleString() : 0}</p>
                                      <p style={{ textTransform: "lowercase" }}>out of</p>
                                      <p className=''>{listblocks?.count?.total_part_count?.toLocaleString()}</p>
                                      <p className="pl-2">
                                        {listPartsAtom.map((j) => {
                                          return <>
                                            {j.id === listId?.construction[overallProgress]?.part_id ?
                                              j?.part_name
                                              : null}
                                          </>
                                        })}
                                      </p>
                                    </div>
                                  }
                                </div>
                                {enableMarkProgress === "on" ?
                                  <div>
                                    <div className='flex flex-col space-y-3 m-1'>
                                      {selectedRow?.length > 0 ?
                                        <div className='flex justify-around text-xs'>
                                          <p className={`border rounded-full cursor-pointer px-4 py-2 ${applyStatus === "Not Started" ? "bg-green-400 text-white" : ""}`} onClick={() => handleSelectApplyStatus("Not Started")}>Not Started</p>
                                          {listId?.construction && listId?.construction[selectedActivity]?.parent_activity_id ?
                                            <p className={`border rounded-full cursor-pointer px-4 py-2 ${applyStatus === "In-Progress" ? "bg-green-400 text-white" : ""}`} onClick={() => handleSelectApplyStatus("In-Progress")}>In-Progress</p>
                                            :
                                            <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>In-Progress</p>
                                          }
                                          <p className={`border rounded-full cursor-pointer px-4 py-2 ${applyStatus === "Completed" ? "bg-green-400 text-white" : ""}`} onClick={() => handleSelectApplyStatus("Completed")}>Completed</p>
                                        </div>
                                        :
                                        <div className='flex justify-around text-xs'>
                                          <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>Not Started</p>
                                          <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>In-Progress</p>
                                          <p className={`border rounded-full cursor-pointer px-4 py-2 text-gray-300`}>Completed</p>
                                        </div>
                                      }
                                    </div>
                                    {checkMarkProgressEnable() ?
                                      <p className='mt-5 bg-gray-300 rounded-full text-center mx-3 mb-2 py-2 text-gray-500 cursor-pointer text-white m-auto'>
                                        Apply
                                      </p>
                                      :
                                      <p className='mt-5 bg-red-550 rounded-full flex justify-center space-x-3 items-center mx-3 mb-2 py-1.5 cursor-pointer text-white m-auto' onClick={handleClickMarkProgressApply}>
                                        {applyProgressLoader ?
                                          <span className='loader' style={{ width: "20px", height: "20px" }}></span>
                                          :
                                          null
                                        }
                                        <p>Apply</p>
                                      </p>
                                    }
                                  </div>
                                  :
                                  null
                                }
                              </div>
                              :
                              null
                            }
                            <div className={`flex justify-between border py-1.5 my-1.5 w-full ${selectedActivity === i ? enableMarkProgress === "on" ? "pb-44" : "pb-8" : ""}`}>
                              {listId.construction[i]?.parent_activity_id ?
                                <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                :
                                <p className='font-extrabold max-w-[155px] w-[50%] pl-1'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                              }
                              {selectedActivity !== i ?
                                <div className='flex justify-between z-1 w-[50%]'>
                                  <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                  <p>{roundOff(findCPprogress(i))}%</p>
                                </div>
                                :
                                null
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

              <div className={`border rounded-xl ml-6 mt-6 p-3`} style={{ width: '550px' }}>
                <div>
                  <div className='flex justify-between font-bold text-base'>
                    <p>Quality Check Progress</p>
                    <p className='text-red-550'>
                      {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                        {qcCheck() ? ttlOverAllQCPercent() : 0}%</>}
                    </p>
                  </div>
                  {qcCheck() ?
                    <div className='font-medium text-xs'>
                      {loading ?
                        <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                          <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                          <p className='font-medium text-sm'>Fetching...</p>
                        </div>
                        :
                        finalQCArr.map((i) => {
                          return <>
                            <div className='flex justify-between mt-3'>
                              {listId.qc[i]?.parent_activity_id ?
                                <p className='flex pl-4 w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                :
                                <p className='font-extrabold w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                              }
                              <div className='flex justify-between' style={{ width: '215px' }}>
                                <progress className={`progress w-40 mt-1 ${findQCprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findQCprogress(i)} max="100"></progress>
                                <p>{roundOff(findQCprogress(i))}%</p>
                              </div>
                            </div>
                          </>
                        })
                      }
                    </div>
                    :
                    null
                  }
                </div>
              </div>
              <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '550px' }}>
                <div>
                  <div className='flex justify-between font-bold text-base'>
                    {handleCheckZimmerman() ?
                      <p>Table Type</p>
                      :
                      <p>Tracker Type</p>
                    }
                    <p>Quantities</p>
                  </div>
                  <div className='font-medium text-xs'>
                    {loading ?
                      <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                        <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                        <p className='font-medium text-sm'>Fetching...</p></div>
                      :
                      trackerList?.map((i, index) => {
                        return (
                          <div key={index} className={`cursor-pointer ${hightLightTracker.includes(i?.id) ? "border-red-550 border-2" : "border"} ${trackerSelect.includes(i?.id) ? "bg-red-550 text-white" : "border"} flex items-center justify-between mt-4`}>
                            {i?.name.split("+_+").length === 2 ?
                              <p className={`ml-4 px-4 py-1 `} onClick={() => handleTrackerRowSelection(i)}>
                                {i?.name.split("+_+")[0]}
                              </p>
                              :
                              <p className='font-extrabold py-1 pl-1'>{i?.name}</p>
                            }
                            <p className='mr-1'>{i?.count}</p>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <Modal
        isOpen={modalSecTracIsOpen}
        onAfterOpen={afterOpenSecTracModal}
        onRequestClose={closeSecTracModal}
        style={customStyles}
        contentLabel="BOM status For Selected Trackers"
      >
        <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
          {handleCheckZimmerman() ?
            <p className='mt-2'>{block_name?.name} - Selected Tables - Bill of Materials</p>
            :
            <p className='mt-2'>{block_name?.name} - Selected Trackers - Bill of Materials</p>
          }
          <div className='flex items-center space-x-5'>
            <p className='text-sm underline underline-offset-2 cursor-pointer hover:text-blue-500 text-blue-400' onClick={() => setTrackerModal(true)}>View Selected Trackers</p>
            <div className='flex font-semibold text-sm cursor-pointer text-gray-500 hover:text-gray-600' onClick={handleDownloadSelectedTracker}>
              <CgSoftwareDownload style={{ width: '20px', height: '20px' }} />
              <p className='ml-1.5'>Download</p>
            </div>
            <VscClose onClick={closeSecTracModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
          </div>
        </div>
        <table className="table table-compact z-0 overflow-auto w-full" >
          <thead>
            <tr className='sticky z-20' style={{ top: "76px" }}>
              {/* <th className='normal-case' colspan='2' style={{ width: "14%" }}>
                        {handleCheckZimmerman() ?
                          "Selected Tables"
                          :
                          "Selected Trackers"
                        }
                      </th> */}
              <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Part Number</th>
              <th className='normal-case' rowSpan='2' style={{ width: "50%" }}>Part Description</th>
              <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Required Qty</th>
              <th className='normal-case' colspan="2" style={{ width: "10%" }}>Distributed</th>
              <th className='normal-case' colspan="2" style={{ width: "10%" }}>Installed</th>
            </tr>
            <tr className='sticky z-20' style={{ top: "112.5px" }}>
              {/* <th className='normal-case'>
                        {handleCheckZimmerman() ?
                          "Row"
                          :
                          "Section"
                        }
                      </th>
                      <th className='normal-case'>
                        {handleCheckZimmerman() ?
                          "Table"
                          :
                          "Tracker Row"
                        }
                      </th> */}
              <th className='normal-case'>%</th>
              <th className='normal-case'>Qty</th>
              <th className='normal-case'>%</th>
              <th className='normal-case'>Qty</th>
            </tr>
          </thead>
          <tbody>
            {listblocks?.selectedTrackersBom.map((i) => {
              if (i?.project_quantity_calc) {
                return (
                  <tr className="hover">
                    {/* <td>{i?.section}</td> */}
                    {/* <td>{i?.row_no}</td> */}
                    <td style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                    <td className='pr-12 relative' style={{ width: "35vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                      {i?.bom_id && showDescription === i?.bom_id ?
                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                          {i?.description}
                        </p>
                        :
                        <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "35vw" }}>{i?.description}</p>
                      }
                    </td>
                    <td>{i?.project_quantity_calc}</td>
                    <td>
                      {i?.distribution_progress && roundOff(i?.distribution_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distribution_progress)}%</p>
                        :
                        roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                          <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress)}%</p>
                          :
                          <p style={{ color: "black" }}>{i?.distribution_progress ? roundOff(i?.distribution_progress) : 0}%</p>
                      }
                    </td>
                    <td>
                      {i?.distribution_progress && roundOff(i?.distribution_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distributed_quantity)}</p>
                        :
                        roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                          <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity)}</p>
                          :
                          <p style={{ color: "black" }}>{i?.distributed_quantity ? roundOff(i?.distributed_quantity) : 0}</p>
                      }
                    </td>
                    <td>
                      {i?.installed_progress && roundOff(i?.installed_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_progress)}%</p>
                        :
                        roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                          <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress)}%</p>
                          :
                          <p style={{ color: "black" }}>{i?.installed_progress ? roundOff(i?.installed_progress) : 0}%</p>
                      }
                    </td>
                    <td>
                      {i?.installed_progress && roundOff(i?.installed_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_quantity)}</p>
                        :
                        roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                          <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity)}</p>
                          :
                          <p style={{ color: "black" }}>{i?.installed_quantity ? roundOff(i?.installed_quantity) : 0}</p>
                      }
                    </td>
                  </tr>
                )
              }

            })}
          </tbody>
        </table>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="BOM status"
      >
        <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-30'>
          <p className='mt-2'>{block_name?.name} - Bill of Materials</p>
          <div className='flex'>
            <div className='flex font-semibold text-sm mr-10 mt-1 cursor-pointer' onClick={handleDownloadBlock}>
              <CgSoftwareDownload className='text-gray-500' style={{ width: '20px', height: '20px' }} />
              <p className='text-gray-500 ml-1.5'>Download</p>
            </div>
            <VscClose onClick={closeModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
          </div>
        </div>
        <div className='flex'>
          <table className={`table table-compact z-0 w-[100%]`}>
            <thead>
              <tr className='sticky z-20' style={{ top: "76px" }}>
                <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Part Number</th>
                <th className='normal-case' rowSpan='2' style={{ width: "45%" }}>Part Description</th>
                <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Block Qty</th>
                <th className='normal-case' colspan="2" style={{ width: "13%" }}>Distributed</th>
                <th className='normal-case' colspan="2" style={{ width: "13%" }}>Installed</th>
              </tr>
              <tr className='sticky z-20' style={{ top: "112.5px" }}>
                <th className='normal-case'>%</th>
                <th className='normal-case'>Qty</th>
                <th className='normal-case'>%</th>
                <th className='normal-case'>Qty</th>
              </tr>
            </thead>
            <tbody>
              {listblocks?.bomBlock.map((i) => {
                return (
                  <tr className='hover'>
                    <td style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                    <td className='pr-12 relative' style={{ width: "22vw" }} onMouseEnter={() => { setShowDescription(i.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                      {showDescription === i?.bom_id ?
                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                          {i?.description}
                        </p>
                        :
                        <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "22vw" }}>{i.description}</p>
                      }
                    </td>
                    <td>{i?.project_quantity_calc}</td>
                    <td>
                      {roundOff(i?.distribution_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distribution_progress)}%</p>
                        :
                        roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                          <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.distribution_progress)}%</p>
                          :
                          <p style={{ color: "black" }}>{roundOff(i?.distribution_progress)}%</p>
                      }
                    </td>
                    <td>
                      {roundOff(i?.distribution_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distributed_quantity)}</p>
                        :
                        roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                          <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.distributed_quantity)}</p>
                          :
                          <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity)}</p>
                      }
                    </td>
                    <td>
                      {roundOff(i?.installed_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_progress)}%</p>
                        :
                        roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                          <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.installed_progress)}%</p>
                          :
                          <p style={{ color: "black" }}>{roundOff(i?.installed_progress)}%</p>
                      }
                    </td>
                    <td>
                      {roundOff(i?.installed_progress) === 100 ?
                        <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_quantity)}</p>
                        :
                        roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                          <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.installed_quantity)}</p>
                          :
                          <p style={{ color: "black" }}>{roundOff(i?.installed_quantity)}</p>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        isOpen={openTrackerModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeTrackerModal}
        style={trackersCustomStyles}
        contentLabel='Tracker items'
      >
        <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-30'>
          <p className='mt-2'>
            {handleCheckZimmerman() ? `Selected Table(s)` : `Selected Tracker(s)`}
          </p>
          <div className='flex'>
            <VscClose onClick={closeTrackerModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
          </div>
        </div>
        <div>
          <div className='mb-3 w-max'>
            {Object.keys(getTrackerWiseDatas()).map((i) => {
              return (
                <div className='flex items-center justify-between text-sm'>
                  <p className='text-base my-1' style={{ textTransform: "capitalize" }}>{i}</p>
                  <div className='flex my-1 w-[50px] justify-between ml-5'>
                    <p className='text-base font-medium'>-</p>
                    <p className='text-sm'>{getTrackerWiseDatas()[i]}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='flex'>
            <p className='font-bold text-xl w-[36%] pb-2' style={{ top: "76px" }}>Total</p>
            <div className='flex space-x-5'>
              <p className='text-base font-medium'>-</p>
              <p className='text-base ml-2'>{selectedRow.length} {handleCheckZimmerman() ? `table(s)` : `tracker(s)`}</p>
            </div>
          </div>
          <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
            <thead>
              {handleCheckZimmerman() ?
                <tr className='sticky z-20' style={{ top: "75px" }}>
                  <th className='normal-case'>Row</th>
                  <th className='normal-case'>Table</th>
                  <th className='normal-case'>Table Type</th>
                </tr>
                :
                <tr className='sticky z-20' style={{ top: "75px" }}>
                  <th className='normal-case'>Section</th>
                  <th className='normal-case w-[60%]'>Tracker Row</th>
                  <th className='normal-case'>Tracker Type</th>
                </tr>
              }
            </thead>
            <tbody>
              {selectedRow.map((i) => {
                return (
                  <tr>
                    <td>{i.split("-")[4]}</td>
                    <td>{i.split("-")[3]}</td>
                    <td className={`${handleCheckZimmerman() ? "" : "text-left"}`} style={{ textTransform: "capitalize" }}>{findTrackerName(parseInt(i.split("-")[2]))}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        isOpen={inprogressTrackerTypesModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeInprogressTrackerModal}
        style={inProgressTrackersCustomStyles}
        contentLabel='In-Progress Tracker Types'
      >
        <div className='h-full'>
          <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-30'>
            <div className='flex flex-col'>
              <p className='my-2'>
                {block_name?.name}
              </p>
              <p className='text-gray-500 text-sm font-medium'>Number of Trackers selected: <span className='text-black font-semibold'>{selectedRow?.length}</span></p>
            </div>
            <div className='flex'>
              <VscClose onClick={closeInprogressTrackerModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
            </div>
          </div>
          <div className='w-full h-[70%] overflow-auto'>
            <table className='table table-compact z-0 overflow-auto w-full'>
              <thead>
                <tr className='sticky z-20 top-0'>
                  <th className='normal-case w-[60%]'>Tracker Type Name</th>
                  <th className='normal-case w-[20%]'>Quantities</th>
                  <th className='w-[10%]'></th>
                  <th className='w-[10%]'></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(getTrackerWiseDatas()).map((i) => {
                  const isTickMarkEnable = isRowCompleted(i)
                  return (
                    <tr>
                      <td className='pl-2'>
                        <p style={{ textTransform: "capitalize" }}>{i}</p>
                      </td>
                      <td>{getTrackerWiseDatas()[i]}</td>
                      <td>
                        <p className='bg-red-550 text-white px-4 py-1.5 cursor-pointer rounded-full' onClick={() => handleClickInProgressMarkProgress(i)}>
                          Mark Progress
                        </p>
                      </td>
                      <td>
                        {isTickMarkEnable ?
                          <div className='flex justify-center'>
                            <TiTick className='text-white text-xl w-5 h-5 rounded-full bg-green-500' />
                          </div>
                          :
                          null
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {console.log(checkSubmitBtnEnable())}
          <div className='flex justify-end my-6'>
            {inProgressSubmitLoader ?
              <div className='flex items-center space-x-3 bg-red-550 text-white rounded-full px-6 py-2'>
                <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                <p>Submit</p>
              </div>
              :
              checkSubmitBtnEnable() ?
                <p className='bg-red-550 text-white rounded-full px-6 py-2 cursor-pointer' onClick={handleSubmitInProgress}>Submit</p>
                :
                <p className='bg-gray-400 text-white rounded-full px-6 py-2'>Submit</p>
            }
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={inprogressIndividualTrackerModal.bool}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeInProgressTrackerBomModal}
        style={inProgressTrackersBomCustomStyles}
        contentLabel='In-Progress Individual Tracker Type BOM'
      >
        <InProgressstepperModal
          listblocks={listblocks}
          isQtyExceeded={isQtyExceeded}
          currentTrackerType={currentTrackerType}
          enableSaveButton={saveBtnEnable}
          enableNextButton={btnEnable}
          inprogressTotalPartCount={inprogressTotalPartCount}
          getInprogressDefaultValue={getInprogressDefaultValue}
          setBtnEnable={setBtnEnable}
          inprogressCount={inprogressDeployedPartCount[currentTrackerType?.tracker_type] || 0}
          setInprogressDeployedPartCount={setInprogressDeployedPartCount}
          setIsQtyExceeded={setIsQtyExceeded}
          updateInprogressDeployedCount={updateInprogressDeployedCount}
          setUpdateQuantity={setUpdateQuantity}
          handleCloseWithoutSaving={handleCloseWithoutSaving}
          handleSaveInProgressSteps={handleSaveInProgressSteps}
          piersTorqueTubesDetails={piersTorqueTubesDetails}
          inprogressIndividualTrackerModal={inprogressIndividualTrackerModal}
          setInprogressIndividualTrackerModal={setInprogressIndividualTrackerModal}
          selectedPiersTorqueTubes={selectedPiersTorqueTubes}
          setSelectedPiersTorqueTubes={setSelectedPiersTorqueTubes}
        />
      </Modal>

      <Modal
        isOpen={individualTrackerAlertModal}
        style={alertModalStyles}
        contentLabel='Individual Tracker alert modal'
      >
        <div className=''>
          <p className='text-base font-semibold my-5 pl-3 pr-16'>All the entered datas within this tracker type will be lost. Please Confirm</p>
          <div className='flex space-x-4 justify-end text-sm font-normla'>
            <button className='px-4 py-2 rounded-full text-gray-500 border' onClick={() => setIndividualTrackerAlertModal(false)}>Cancel</button>
            <button className='px-4 py-2 rounded-full bg-red-550 text-white' onClick={() => { setIndividualTrackerAlertModal(false); closeInProgressTrackerBomModal(); handleCloseWithoutSaving() }}>Confirm</button>
          </div>
        </div>
      </Modal>
    </Container >
  )
}

export default Dashboard2
