import { useAtom } from 'jotai'
import React, { useCallback, useRef } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dashboardAtom, { fetchBlockProgress, fetchBlocks, fetchBomBlocks, fetchBOMproject, fetchCount, fetchCountOverall, fetchMapRender, fetchoverallCP, fetchOverallCpSVG, fetchoverallQC, fetchoverallTracker, fetchTrackerName } from '../../atoms/dashboardAtom'
import Container from '../../components/container'
import { CgSoftwareDownload } from "react-icons/cg"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { BsBoxArrowUpRight } from "react-icons/bs"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { VscClose } from "react-icons/vsc"
import Modal from 'react-modal';
import activitiesAtom, { fetchActivitiesAtom } from '../../atoms/activitiesAtom'
import { useState } from 'react'
import partsAtom, { fetchPartsAtom } from '../../atoms/partsAtom'
import 'react-dropdown-tree-select/dist/styles.css'
import projectAtom from '../../atoms/projectAtom'
import { toast } from 'react-hot-toast'
import { Parser } from 'json2csv'
import produce from 'immer'
import mileStone, { fetchMileStone, fetchOnsiteQty, fetchOnsiteQtyAgg } from '../../atoms/mileStoneAtom'
import Svg from './svg'
import roundOff from '../../utils/roundOff'
import TableComp from '../../components/tableComp'
import { BOM_FOR_BLOCKS } from '../../components/BOM'
import ViewByDropDown from './components/viewByDropDown'
import * as XLSX from 'xlsx'

const useLatest = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

function BlockList() {

    const _ = require("lodash")
    const navigate = useNavigate()
    //svg

    const [loading, setLoading] = useState(false)
    const [countLoading, setCountLoading] = useState(false)
    const [bomLoading, setBomLoading] = useState(false)
    const [showQty, setShowQty] = useState(true)
    const [overallProgress, setOverallProgress] = useState("All")
    const [showDescription, setShowDescription] = useState()
    const [svgMap, setSvgMap] = useState()
    const [showProgress, setShowProgress] = useState(true)
    const [selectedBlocks, setSelectedBlocks] = useState({ bool: false, data: [] })
    const [hoverActivity, setHoverActivity] = useState(-1)
    const [selectedActivity, setSelectedActivity] = useState(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
    const [selectedBlockModal, setSelectedBlockModal] = useState(false)

    const isBlocksSelectEnabledRef = useLatest(selectedBlocks?.bool);

    const selectedBlockIdsRef = useLatest(selectedBlocks?.data);

    const [listblocks] = useAtom(dashboardAtom)
    const [listId] = useAtom(activitiesAtom)
    const [listPartsAtom] = useAtom(partsAtom)
    const [, setFetchMileStone] = useAtom(fetchMileStone)
    const [, setFetchParts] = useAtom(fetchPartsAtom)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setFetchoverallCP] = useAtom(fetchoverallCP)
    const [, setFetchoverallQC] = useAtom(fetchoverallQC)
    const [, setFetchoverallTracker] = useAtom(fetchoverallTracker)
    const [, setfetchActivitiesAtom] = useAtom(fetchActivitiesAtom)
    const [, setFetchTotalBOM] = useAtom(fetchBOMproject)
    const [, setBlocksBom] = useAtom(fetchBomBlocks)
    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setTrackerName] = useAtom(fetchTrackerName)
    const [, setDeployCount] = useAtom(fetchCount)
    const [, setDeployCountOverall] = useAtom(fetchCountOverall)
    const [, setFetchBlockProgress] = useAtom(fetchBlockProgress)
    const [, setFetchOverallSVG] = useAtom(fetchOverallCpSVG)
    const [projectsName] = useAtom(projectAtom)
    const [qtyModal, setQtyModal] = useState(false);
    const [qtyModalLoader, setQtyModalLoader] = useState(false);
    const [, setFetchQtyMileStone] = useAtom(fetchOnsiteQty)
    const [, setFetchQtyMileStoneAgg] = useAtom(fetchOnsiteQtyAgg);
    const [listMileStone] = useAtom(mileStone);
    const [mileStoneData, setMileStoneData] = useState([])

    useEffect(() => {
        if (parseInt(localStorage.getItem("project_id"))) {
            init()
        }
    }, [parseInt(localStorage.getItem("project_id"))])

    function handleCheckZimmerman() {
        let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))
        if (currentProject && currentProject?.type) {
            return true
        }
        else {
            return false
        }
    }

    function qcCheck() {
        let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        return project_name?.project_settings?.qc
    }

    const handleBlockClick = useCallback(_.debounce((block, block_svg_id) => {
        if (isBlocksSelectEnabledRef.current) {
            setSelectedBlocks((prevSelectedBlockIds) => {
                const newSelectedBlockIds = new Set(prevSelectedBlockIds?.data);
                if (newSelectedBlockIds.has(block.id)) {
                    newSelectedBlockIds.delete(block.id);
                    block_svg_id.style.fill = ""; // Reset the color if deselected
                } else {
                    newSelectedBlockIds.add(block.id);
                    block_svg_id.style.fill = "yellow"; // Set color if selected
                }
                return { bool: true, data: Array.from(newSelectedBlockIds) };
            });
        } else {
            block_svg_id.style.fill = "";
        }
    }, 100), []);

    const handleDblClick = (block) => {
        if (!isBlocksSelectEnabledRef.current && selectedBlockIdsRef.current.length === 0) {
            navigate(`/dashboard/summary/${block.id}`);
        }
    }

    const svgBlocksSelect = useCallback(() => {
        if (listblocks?.blocks?.length > 0) {
            let block_svg_id;
            let block_progress_id;
            let constructionProgressOfAllActivitiesInBlock = {}
            if (listblocks?.overAllSVG) {
                Object.keys(listblocks?.overAllSVG).map((i) => {
                    let subList = []
                    let subWight = []
                    listblocks?.overAllSVG[i].map((j) => {
                        if (listId?.construction[j?.activity_id]?.parent_activity_id) {
                            subWight.push(listId?.construction[j?.activity_id]?.weight)
                            subList.push(listId?.construction[j?.activity_id]?.weight * j?.progress)
                        }
                    })
                    constructionProgressOfAllActivitiesInBlock[i] = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
                })
            }

            listblocks?.blocks.forEach((block) => {
                block_svg_id = document.getElementById(handleCheckZimmerman() ? block?.name : block?.block_svg_id);
                block_progress_id = document.getElementById(block?.progress_svg_id);

                if (block_svg_id) {
                    block_svg_id.removeEventListener('click', () => handleBlockClick(block, block_svg_id));
                    block_svg_id.addEventListener('click', () => handleBlockClick(block, block_svg_id));
                    block_svg_id.addEventListener('dblclick', () => handleDblClick(block));
                }

                if (isBlocksSelectEnabledRef.current) {
                    if (selectedBlockIdsRef.current.includes(block.id)) {
                        block_svg_id.style.fill = "yellow";
                    }
                    else {
                        block_svg_id.style.fill = ""; // Reset the color if not selected
                    }
                } else {

                    if (block_svg_id) {

                        const updateProgress = (progress) => {
                            if (block_progress_id && overallProgress !== "None Selected") {
                                block_progress_id.querySelector("tspan").innerHTML = `${isNaN(progress) ? 0 : progress} %`;
                            }
                            if (isNaN(progress)) {
                                block_svg_id.style.fill = ""
                            }
                            else {
                                if (progress === 0) {
                                    block_svg_id.style.fill = "#FFFFFF";
                                } else if (progress === 100) {
                                    block_svg_id.style.fill = "#B4F8D7";
                                } else {
                                    block_svg_id.style.fill = "#FCDDD9";
                                }
                            }
                        };

                        if (overallProgress === "All" && constructionProgressOfAllActivitiesInBlock.length !== 0) {
                            const progress = constructionProgressOfAllActivitiesInBlock[block.id];
                            updateProgress(progress);
                        }
                        else {
                            if (overallProgress === "None Selected") {
                                if (block_progress_id) {
                                    block_progress_id.querySelector("tspan").innerHTML = ``;
                                }
                                block_svg_id.style.fill = ""
                            }
                            else {
                                const progress = handleFindProgress(block.id);
                                updateProgress(progress);
                            }
                        }
                    }
                }
            });
        }
    }, [listblocks, listId, overallProgress, navigate, localStorage.getItem("selected-activity")]);

    useEffect(() => {
        svgBlocksSelect();
        return () => {
            listblocks?.blocks?.forEach(block => {
                let block_svg_id;
                block_svg_id = document.getElementById(block?.block_svg_id);
                if (block_svg_id) {
                    const handleClick = () => { }; // Dummy function to remove actual listener
                    block_svg_id.removeEventListener('click', handleClick);
                }
            });
        };
    }, [listblocks?.blocks, listblocks?.blockProgress, listblocks?.overAllSVG, overallProgress, selectedBlocks]);

    const handleEnableSelectBlocks = async () => {
        setSelectedBlocks(produce((draft) => { draft.bool = !selectedBlocks?.bool }))
        if (selectedBlocks?.bool) {
            setSelectedBlocks(produce((draft) => { draft.data = [] }));
        }
    };

    const handleSelectAllBlocks = () => {
        const allBlockIds = listblocks?.blocks.map(block => block.id);
        setSelectedBlocks(produce((draft) => { draft.data = allBlockIds }));
    };

    useEffect(() => {
        if (listblocks.map) {
            setSvgMap(listblocks.map)
        }
    }, [listblocks.map])

    const init = async () => {
        let localStorageSelectedActivity = localStorage.getItem("selected-activity")
        try {
            setLoading(true)
            setCountLoading(true)
            setTrackerName()
            await setFetchMap()
            await setfetchActivitiesAtom()
            await setFetchBlocks()
            setFetchOverallSVG()
            setFetchoverallCP()
            setFetchoverallQC()
            setFetchoverallTracker()
            setFetchParts()
            await setFetchMileStone()
            if (["All", "None Selected"].includes(localStorageSelectedActivity)) {
                setSelectedActivity(localStorageSelectedActivity)
            }
            else if (!isNaN(localStorageSelectedActivity) && localStorageSelectedActivity) {
                setSelectedActivity(parseInt(localStorageSelectedActivity))
            }
            else {
                setSelectedActivity("All")
            }
            setOverallProgress(localStorageSelectedActivity ? localStorageSelectedActivity : "All")
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
            setCountLoading(false)
        }
    }

    useEffect(() => {
        handleFetchDeployCount()
    }, [listId?.construction])

    function showLaydownFlag() {
        let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        return project_name?.project_settings?.laydown_delivery
    }

    const handleFetchDeployCount = async () => {
        try {
            if (localStorage.getItem("selected-activity") !== "All" && localStorage.getItem("selected-activity") !== null && localStorage.getItem("selected-activity") !== "None Selected" && listId?.construction) {
                await setFetchBlockProgress({ act_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id })
                await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id })
            }
            else {
                await setDeployCountOverall()
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleFindProgress = (data) => {
        let temp = 0
        if (listblocks.blockProgress) {
            listblocks.blockProgress.map((i) => {
                if (i?.block_id === data) {
                    temp = roundOff(i?.progress)
                }
            })
        }
        return temp
    }

    const ttlOverAllPercent = () => {
        let subList = []
        let subWight = []
        if (listId.construction) {
            Object?.keys(listId?.construction)?.map((i) => {
                if (listId?.construction[i]?.parent_activity_id) {
                    subWight.push(listId?.construction[i]?.weight)
                    subList.push(listId?.construction[i]?.weight * findCPprogressCalc(parseInt(i)))
                }
            })
        }
        let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const ttlOverAllQCPercent = () => {
        let subList = []
        let subWight = []
        if (listId.qc) {
            Object?.keys(listId?.qc)?.map((i) => {
                if (listId?.qc[i]?.parent_activity_id) {
                    subWight.push(listId?.qc[i]?.weight)
                    subList.push(listId?.qc[i]?.weight * findQCprogress(parseInt(i)))
                }
            })
        }
        let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const handleChangeView = (value) => {
        if (value === "Mechanical Progress") {
            navigate("/dashboard")
        } else {
            navigate("/dashboard/logistics-management")
        }
    }

    const openModal = async () => {
        try {
            setBomLoading(true)
            await setFetchTotalBOM()
            setIsOpen(true);
            setBomLoading(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleSelectedBomBlock = async () => {
        try {
            setBomLoading(true)
            await setBlocksBom({ selectedBlocks: selectedBlocks?.data })
            setIsOpen(true);
            setBomLoading(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '95vw',
            height: "650px",
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const BlocksCustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '35vw',
            height: "650px",
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const data = []

    if (listId?.construction) {
        Object.keys(listId?.construction).map((i) => {
            let temp = {}
            if (listId?.construction[i]?.parent_activity_id) {
                temp.label = listId?.construction[listId?.construction[i]?.parent_activity_id].name
                temp.value = listId?.construction[listId?.construction[i]?.parent_activity_id].id
                temp.children = []
                temp.children.push({
                    label: listId?.construction[i].name,
                    value: listId?.construction[i].id
                })
                data.push(temp)
            }
            else {
                temp.label = listId?.construction[i].name
                temp.value = listId?.construction[i].id
                data.push(temp)
            }
        })
    }

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    const createWorksheetWithData = (data, customHeaders) => {
        // First, create arrays for custom content
        const headerRows = [
            [`Project Number`, `${project_name?.project_number}`],
            ["Project Name", `${project_name?.name}`],
            ["Report", "Selected Blocks BOM Status"],
            ["Report Date", `${formattedDate}`],
            ['']  // empty row
        ];

        // Convert the data array to array of arrays for easier manipulation
        const dataAsArray = [
            Object.keys(data[0]), // Column headers
            ...data.map(obj => Object.values(obj)) // Data rows
        ];

        // Combine custom headers and data
        const allRows = [...headerRows, ...dataAsArray];

        // Create worksheet from combined arrays
        const worksheet = XLSX.utils.aoa_to_sheet(allRows);

        return worksheet;
    };

    const handleDownloadSelectedBlockBom = () => {
        try {
            toast("Download queued")
            let arr = []
            let arr02 = []
            listblocks?.blocksBOM.map((i, index) => {
                let temp = {}
                let temp02 = {}
                temp02['Blocks'] = listblocks?.blocks?.find(o => o?.id === selectedBlocks?.data[index])?.name
                temp['Part Name'] = i?.part_name?.toUpperCase()
                temp["Description"] = i?.description
                temp["Required Quantity"] = i?.project_quantity_calc
                temp["Distribution Progress %"] = `${roundOff(i?.distribution_progress)}%`
                temp["Distributed Quantity"] = i?.distributed_quantity
                temp["Installed Progress %"] = `${roundOff(i?.installed_progress)}%`
                temp["Installed Quantity"] = i?.installed_quantity
                arr.push(temp)
                arr02.push(temp02)
            })
            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Create worksheets with proper data arrangement
            const worksheet1 = createWorksheetWithData(arr);
            const worksheet2 = createWorksheetWithData(arr02);

            // Add worksheets to workbook
            XLSX.utils.book_append_sheet(workbook, worksheet1, 'BOM for selected blocks');
            XLSX.utils.book_append_sheet(workbook, worksheet2, 'Selected blocks');

            // Generate Excel file and trigger download
            XLSX.writeFile(workbook, `${project_name?.project_number}_Selected Blocks BOM_${project_name?.name}.xlsx`);
        } catch (error) {
            console.error('Error generating Excel file:', error);
            toast.error("Unable to download file")
        }
    };

    const handleDownload = () => {
        try {
            toast("Download queued")
            let arr = []
            listblocks?.totalBOM.map((i) => {
                let temp = {}
                temp['Part Name'] = i?.part_name?.toUpperCase()
                temp["Description"] = i?.description
                temp["Project Quantity"] = i?.default_project_quantity
                temp["Construction Spares"] = i?.spare_count ? i?.spare_count : 0
                temp["Addl Qty Incl.O&M Spares"] = i?.additional_count ? i?.additional_count : 0
                temp["Total Quantity"] = i?.project_quantity
                temp["On site quantity"] = i?.received_quantity ? i?.received_quantity : 0
                temp["Stock quantity"] = i?.current_quantity ? i?.current_quantity : 0
                temp["Distribution Progress %"] = `${roundOff(i?.distribution_progress)}%`
                temp["Distributed Quantity"] = i?.distributed_quantity
                temp["Installed Progress %"] = `${roundOff(i?.installed_progress)}%`
                temp["Installed Quantity"] = i?.installed_quantity
                arr.push(temp)
            })
            let newFields = [
                "Part Name",
                "Description",
                "Project Quantity",
                "Construction Spares",
                "Addl Qty Incl.O&M Spares",
                "Total Quantity",
                "On site quantity",
                "Stock quantity",
                "Distribution Progress %",
                "Distributed Quantity",
                "Installed Progress %",
                "Installed Quantity"
            ]
            let opts = { newFields }
            const parser = new Parser(opts)
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", Project BOM Status\n` + `"Report Date", ${formattedDate}\n` + `\n` + csv
            const element = document.createElement("a")
            const file = new Blob([csv], { type: 'text/csv' })
            element.href = URL.createObjectURL(file)
            element.download = `${project_name?.project_number}_Project BOM_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            console.log(err)
            toast.error("Download failed")
        }
    }

    useEffect(() => {
        // if (selectedActivity !== "All") {
        activityProgressUpdate(selectedActivity)
        // }
    }, [selectedActivity])

    const activityProgressUpdate = async (e) => {
        try {
            localStorage.setItem("selected-activity", e)
            setOverallProgress(e)
            setCountLoading(true)
            if (e !== "All" && e.length !== 0 && e !== "None Selected") {
                await setFetchBlockProgress({ act_id: e, part_id: listId?.construction[e]?.part_id })
                await setDeployCount({ activity_id: e, part_id: listId?.construction[e]?.part_id })
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setCountLoading(false)
        }
    }

    const handleClickActProgress = async (e) => {
        setSelectedActivity(e)
    }

    const findTrackerName = (data) => {
        let name = listblocks?.trackerName.find(k => k.id === data)
        return name?.tracker_type
    }

    let trackerSort = []
    let prt = []
    listblocks?.overallTracker.map((i) => {
        if (handleCheckZimmerman()) {
            prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}`)
        }
        else {
            prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]}`)
        }
        return i
    })

    prt = [...new Set(prt)]

    prt.map((i) => {
        let trName = {}
        trName.name = i
        let tcount = 0
        listblocks.overallTracker.map((j) => {
            if (handleCheckZimmerman()) {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
                    tcount += j?.count
                }
            }
            else {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
                    tcount += j?.count
                }
            }
            return j
        })
        trName.count = tcount
        trackerSort.push(trName)
        listblocks.overallTracker.map((j) => {
            if (handleCheckZimmerman()) {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
                    let newtrName = {}
                    newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
                    newtrName.count = j?.count
                    trackerSort.push(newtrName)
                }
            }
            else {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
                    let newtrName = {}
                    newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
                    newtrName.count = j?.count
                    trackerSort.push(newtrName)
                }
            }
            return j
        })
        return i
    })

    let CPparActIds = []
    let QCparActIds = []

    if (listblocks.overallCP) {
        for (let cp in listblocks.overallCP) {
            if (!listId.construction[cp]?.parent_activity_id) {
                CPparActIds.push(cp)
            }
        }
    }

    if (listblocks.overallQC) {
        listblocks.overallQC.map((qc) => {
            if (!listId.qc[qc.activity_id]?.parent_activity_id) {
                QCparActIds.push(qc.activity_id)
            }
        })
    }

    let finalCPArr = []
    let finalQCArr = []

    CPparActIds.map((pt) => {
        finalCPArr.push(parseInt(pt))
        Object.values(listId?.construction)?.map((ij) => {
            if (ij.parent_activity_id === parseInt(pt)) {
                finalCPArr.push(ij.id)
            }
        })
    })

    QCparActIds.map((pt) => {
        finalQCArr.push(parseInt(pt))
        Object.values(listId?.qc)?.map((ij) => {
            if (ij.parent_activity_id === parseInt(pt)) {
                finalQCArr.push(ij.id)
            }
        })
    })

    const findCPprogressCalc = (data) => {
        let temp = 0
        if (listblocks.overallCP) {
            for (let i in listblocks.overallCP) {
                if (parseInt(i) === data) {
                    temp = listblocks.overallCP[i]
                }
            }
        }
        return temp
    }

    const findCPprogress = (data) => {
        let temp = 0
        if (listblocks.overallCP) {
            for (let i in listblocks.overallCP) {
                if (parseInt(i) === data) {
                    temp = listblocks.overallCP[i]
                }
            }
        }
        if (listId.construction[data]?.parent_activity_id) {
            return temp
        }
        else {
            let sub_weight_progress = []
            let sub_weight = []
            for (let actKey in listId.construction) {
                if (listId.construction[actKey]?.parent_activity_id === data) {
                    sub_weight_progress.push(listId.construction[actKey]?.weight_decimal * findCPprogress(parseInt(actKey)))
                    sub_weight.push(listId.construction[actKey]?.weight_decimal)
                }
            }
            let progressData = sub_weight_progress.reduce((partialSum, a) => partialSum + a, 0) / sub_weight.reduce((partialSum, a) => partialSum + a, 0)
            return progressData
        }
    }

    const findQCprogress = (data) => {
        let temp = 0
        if (listblocks.overallQC) {
            listblocks.overallQC.map((i) => {
                if (i?.activity_id === data) {
                    temp = i?.progress
                }
            })
        }
        return temp
    }

    useEffect(() => {
        if (listMileStone?.data) {
            setMileStoneData(listMileStone?.data)
        }
    }, [listMileStone.data])


    const handleOpenQtyModal = async (i) => {
        try {
            setQtyModal(true)
            setQtyModalLoader(true)
            await setFetchQtyMileStone({ bomId: i.bom_id })
            await setFetchQtyMileStoneAgg({ bomId: i.bom_id })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setQtyModalLoader(false)
        }
    }

    const customDeliveryModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // width: '1400px',
            // maxHeight: "80%",
            padding: "0px 30px 30px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleFindPartName = (data) => {
        let temp
        temp = mileStoneData?.find(o => o.bom_id === data)
        return temp?.part_name
    }


    return (
        <Container>
            <div className='flex justify-between overflow-auto w-full h-full'>
                <div className='h-full' style={{ width: showProgress ? "calc(100vw - 620px)" : "100%" }}>
                    <div className='flex justify-between space-x-5 my-4 px-3 items-center'>
                        <div className='font-semibold ml-2' style={{ textTransform: "capitalize" }}>
                            Construction - {selectedActivity === "All" ?
                                "Overall"
                                :
                                selectedActivity !== "None selected" ?
                                    listId?.construction && listId?.construction[selectedActivity]?.name
                                    :
                                    null
                            } Progress
                        </div>
                        <div className='flex items-center space-x-4 justify-end'>
                            <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleEnableSelectBlocks}>
                                {selectedBlocks.bool ? "Clear Blocks" : "Select Blocks"}
                            </button>
                            {selectedBlocks.bool &&
                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleSelectAllBlocks}>
                                    Select All
                                </button>
                            }
                            {showLaydownFlag() && !selectedBlocks.bool &&
                                <div className='flex items-center space-x-4 mr-4 w-36'>
                                    <ViewByDropDown onSelect={handleChangeView} defaultValue="Mechanical Progress" />
                                </div>
                            }
                            {!showProgress && (
                                <div className='text-xl text-black border bg-gray-200 mx-2'>
                                    <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowProgress(true)} />
                                </div>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div
                        style={{ height: "calc(100vh - 270px)" }}
                    >
                        <div className='cursor-grab w-full' style={{ height: "calc(100vh - 270px)" }}>
                            <Svg project_name={project_name} svgMap={svgMap} className="svgSiblingDiv" />
                        </div>
                    </div>
                    <div className='relative w-[100%]'>
                        {selectedBlocks?.bool &&
                            <div className={`absolute animate-slide-up flex ${selectedBlocks?.bool ? "bottom-14" : "bottom-[-150px]"}`} style={{ left: "calc(50% - 100px)", display: selectedBlocks?.bool ? "" : "none" }}>
                                <p className='text-white bg-black border px-4 py-2 rounded-2xl'>Click on any block to select</p>
                            </div>
                        }
                        <div className='flex justify-center mt-3'>
                            <span data-tip data-for="svg" className='mt-1 ml-4'>
                                <AiOutlineInfoCircle />
                            </span>
                            <p className='text-sm text-gray-400 mt-0.5 ml-2'>
                                <p className='text-sm text-gray-400 mt-0.5 ml-2'>
                                    Double click on any block to dive in
                                </p>
                            </p>
                        </div>
                        <div className='flex justify-center mt-1.5'>
                            <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#66f1ad" }}></p>
                            <p className='mr-2'>Completed</p>
                            <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#FCDDD9" }}></p>
                            <p className='mr-2'>In progress</p>
                            <p className='w-5 h-5 border border-1 border-black rounded-full mx-2 mt-0.5 bg-white' style={{ backgroundColor: "FFFFFF" }}></p>
                            <p>Not Started</p>
                        </div>
                    </div>
                </div>
                {showProgress && <div className='border-x h-full'>
                    <div className='overflow-auto relative h-full pr-2' style={{ height: "calc(100vh - 100px)", width: "600px" }} >
                        <div>
                            <div className='flex w-full bg-white my-6 text-blue-500 font-semibold text-sm px-6'>
                                <div className='text-xl text-black border bg-gray-200 mx-2'>
                                    <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowProgress(false)} />
                                </div>
                                <div className='mr-10'>
                                    {selectedBlocks?.bool ?
                                        <div className={`flex ${selectedBlocks?.data?.length ? "cursor-pointer" : "text-gray-400"}`} onClick={handleSelectedBomBlock}>
                                            <p>BOM Status for Selected Blocks</p>
                                            {bomLoading ?
                                                <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                                :
                                                <BsBoxArrowUpRight alt='' className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                                            }
                                        </div>
                                        :
                                        <div className='flex cursor-pointer' onClick={openModal}>
                                            <p>BOM Status for Project</p>
                                            {bomLoading ?
                                                <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                                :
                                                <BsBoxArrowUpRight alt='' className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="BOM status"
                            >
                                <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                                    <p className='mt-2'>Bill of Materials - {selectedBlocks?.bool ? "Selected Blocks" : "Project"}</p>
                                    <div className='flex items-center space-x-4'>
                                        {selectedBlocks?.bool ?
                                            <p className='text-blue-400 hover:text-blue-500 underline underline-offset-2 text-sm cursor-pointer' onClick={() => setSelectedBlockModal(true)}>View Selected Blocks</p>
                                            :
                                            null
                                        }
                                        <div className='flex font-semibold text-sm mr-10 text-gray-500 hover:text-gray-600 cursor-pointer' onClick={selectedBlocks?.bool ? handleDownloadSelectedBlockBom : handleDownload}>
                                            <CgSoftwareDownload className='' style={{ width: '20px', height: '20px' }} />
                                            <p className=' ml-1.5'>Download</p>
                                        </div>
                                        <VscClose onClick={closeModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                                    </div>
                                </div>
                                {/* <TableComp
                            heading={BOM_FOR_BLOCKS?.heading}
                            secondHeading={BOM_FOR_BLOCKS?.secondHeading}
                            className="z-0 overflow-auto w-full"
                            data={listblocks?.totalBOM}
                            dataKey={BOM_FOR_BLOCKS?.dataKey}
                            hover={true}
                        /> */}
                                <table className='z-0 overflow-auto w-full'>
                                    <thead>
                                        {selectedBlocks?.bool ?
                                            <tr className='sticky z-20' style={{ top: "76px" }}>
                                                {/* <th className='text-sm text-left py-2 pl-2 w-[14%]' rowSpan='2'>Selected Blocks</th> */}
                                                <th className='text-sm text-left py-2 w-[12%] pl-2' rowSpan='2'>Part Number</th>
                                                <th className='text-sm text-left py-2 w-[44%]' rowSpan='2'>Part Description</th>
                                                <th className='text-sm text-left py-2 w-[12%]' rowSpan='2'>Required Qty</th>
                                                <th className='text-sm text-left py-2 w-[16%]' colspan="2">Distributed</th>
                                                <th className='text-sm text-left py-2 w-[16%]' colspan="2">Installed</th>
                                            </tr>
                                            :
                                            <tr className='sticky z-20' style={{ top: "76px" }}>
                                                <th className='text-sm text-left py-2 pl-2 w-[8%]' rowSpan='2'>Part Number</th>
                                                <th className={`text-sm text-left py-2 ${showQty ? "w-[22%]" : "w-[14%]"}`} rowSpan='2'>Part Description</th>
                                                <th className='text-sm text-left py-2 cursor-pointer' rowSpan='2' onClick={() => setShowQty(!showQty)}>
                                                    <div className='flex space-x-3 items-center cursor-pointer'>
                                                        <p>Project Qty</p>
                                                        {showQty ? <MdKeyboardArrowLeft className='text-base border border-white' /> : <MdKeyboardArrowRight className='text-base border border-white' />}
                                                    </div>
                                                </th>
                                                {showQty && <th className='text-sm text-left py-2 w-[8%]' rowSpan='2'>Construction<br />Spares</th>}
                                                {showQty && <th className='text-sm text-left py-2 w-[10%]' rowSpan='2'>Addl Qty<br />Incl.O&M Spares</th>}
                                                {showQty && <th className='text-sm text-left py-2 w-[7%]' rowSpan='2'>Total<br />Quantity</th>}
                                                <th className='text-sm text-left py-2 w-[7%]' rowSpan='2'>On-site Qty</th>
                                                <th className='text-sm text-left py-2 w-[8%]' rowSpan='2'>Balance Qty</th>
                                                <th className='text-sm text-left py-2 w-[11%]' colspan="2">Distributed</th>
                                                <th className='text-sm text-left py-2 w-[11%]' colspan="2">Installed</th>
                                            </tr>
                                        }
                                        <tr className='sticky z-20 border-t border-white' style={{ top: "112.5px" }}>
                                            <th className='text-sm text-left py-2'>%</th>
                                            <th className='text-sm text-left py-2'>Qty</th>
                                            <th className='text-sm text-left py-2'>%</th>
                                            <th className='text-sm text-left py-2'>Qty</th>
                                        </tr>
                                    </thead>
                                    {selectedBlocks?.bool ?
                                        <tbody>
                                            {listblocks?.blocksBOM.map((i, index) => {
                                                return (
                                                    <tr className='hover:bg-gray-200 text-sm border-b-2 border-gray-100'>
                                                        {/* <td className='py-2 pl-2'>{listblocks?.blocks?.find(o => o?.id === selectedBlocks?.data[index])?.name}</td> */}
                                                        <td className='py-2 pl-2' style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                                                        <td className='pr-12 relative py-2' style={{ width: "30vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                            {showDescription === i?.bom_id ?
                                                                <p className='border px-5 absolute bg-white top-2 z-10' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                    {i?.description}
                                                                </p>
                                                                :
                                                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "30vw" }}>{i?.description}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">{i?.project_quantity_calc ? i?.project_quantity_calc : 0}</td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                :
                                                                roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distribution_progress)}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                :
                                                                roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity)}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_progress)}%</p>
                                                                :
                                                                roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress)}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_progress)}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_quantity)}</p>
                                                                :
                                                                roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity)}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_quantity)}</p>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        :
                                        <tbody>
                                            {listblocks?.totalBOM.map((i) => {
                                                return (
                                                    <tr className='hover:bg-gray-200 text-sm border-b-2 border-gray-100'>
                                                        <td className='py-2 pl-2' style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                                                        <td className='pr-12 relative py-2' style={{ width: showQty ? "17vw" : "38vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                            {showDescription === i?.bom_id ?
                                                                <p className='border px-5 absolute bg-white top-2 z-10' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                    {i?.description}
                                                                </p>
                                                                :
                                                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: showQty ? "17vw" : "38vw" }}>{i?.description}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">{i?.default_project_quantity ? i?.default_project_quantity : 0}</td>
                                                        {showQty && <td className="py-2">{i?.spare_count}</td>}
                                                        {showQty && <td className="py-2">{i?.additional_count ? i?.additional_count : 0}</td>}
                                                        {showQty && <td className="py-2">{i?.project_quantity}</td>}
                                                        <td>
                                                            <p className='cursor-pointer underline underline-offset-2 text-blue-400' onClick={() => handleOpenQtyModal(i)}>{i.received_quantity ? i.received_quantity : null}</p>
                                                        </td>
                                                        <td className="py-2">{(i.default_project_quantity + i.spare_count + i.additional_count) - i.received_quantity}</td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                :
                                                                roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distribution_progress)}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                :
                                                                roundOff(i?.distribution_progress) > 0 && roundOff(i?.distribution_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity)}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_progress)}%</p>
                                                                :
                                                                roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress)}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_progress)}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_progress)}</p>
                                                                :
                                                                roundOff(i?.installed_progress) > 0 && roundOff(i?.installed_progress) < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity)}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_quantity)}</p>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </Modal>
                            <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '550px' }}>
                                <div>
                                    {hoverActivity === "All" && !loading ?
                                        <div className='flex justify-between font-bold text-base w-[530px] cursor-pointer absolute z-10 bg-white py-1 px-1 border' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }} onMouseEnter={() => setHoverActivity("All")} onMouseLeave={() => setHoverActivity(-1)} onClick={() => handleClickActProgress("All")}>
                                            <p>Construction Progress</p>
                                            <p className='text-red-550'>
                                                {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                    {ttlOverAllPercent()}%</>}
                                            </p>
                                        </div>
                                        :
                                        null
                                    }
                                    {selectedActivity === "All" && !loading ?
                                        <div className='flex justify-between font-bold text-base w-[530px] cursor-pointer absolute z-10 bg-white py-1 px-1 border-2 border-red-550' style={{ textTransform: "capitalize", boxShadow: "2px 5px #f5a6a6" }} onClick={() => handleClickActProgress("None Selected")}>
                                            <p>Construction Progress</p>
                                            <p className='text-red-550'>
                                                {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                    {ttlOverAllPercent()}%</>}
                                            </p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className='flex justify-between font-bold text-base' onMouseEnter={() => setHoverActivity("All")} onMouseLeave={() => setHoverActivity(-1)}>
                                        <p>Construction Progress</p>
                                        <p className='text-red-550'>
                                            {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                {ttlOverAllPercent()}%</>}
                                        </p>
                                    </div>
                                    <div className='font-medium text-xs mt-3'>
                                        {loading ?
                                            <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                <p className='font-medium text-sm'>Fetching...</p>
                                            </div>
                                            :
                                            finalCPArr.map((i) => {
                                                return (
                                                    <div className='relative' onMouseEnter={() => setHoverActivity(i)} onMouseLeave={() => setHoverActivity(-1)}>
                                                        {hoverActivity === i && selectedActivity !== i ?
                                                            <div className='flex justify-between cursor-pointer w-full absolute z-10 bg-white py-1 px-1 border' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }} onClick={() => handleClickActProgress(i)}>
                                                                {listId.construction[i]?.parent_activity_id ?
                                                                    <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                    :
                                                                    <p className='font-extrabold max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                }
                                                                <div className='flex justify-between w-[50%]'>
                                                                    <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                                    <p>{roundOff(findCPprogress(i))}%</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {selectedActivity !== "All" && selectedActivity !== "None Selected" && selectedActivity === i ?
                                                            <div className='cursor-pointer flex flex-col space-y-3 w-full absolute z-9 bg-white py-1 px-1 border-2 border-red-550' style={{ textTransform: "capitalize" }} onClick={() => { handleClickActProgress("None Selected"); setOverallProgress("None Selected"); localStorage.setItem("selected-activity", "None Selected") }}>
                                                                <div className='flex justify-between'>
                                                                    {listId.construction[i]?.parent_activity_id ?
                                                                        <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                        :
                                                                        <p className='font-extrabold max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                    }
                                                                    <div className='flex justify-between w-[50%]'>
                                                                        <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                                        <p>{roundOff(findCPprogress(i))}%</p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex justify-end'>
                                                                    {countLoading ?
                                                                        <p className='loader' style={{ width: "15px", height: "15px" }}></p>
                                                                        :
                                                                        <div className='flex space-x-1'>
                                                                            <p className=''>{listblocks?.count?.deployed_part_count && listblocks?.count?.deployed_part_count[0]?.deployed_count !== null ? listblocks?.count?.deployed_part_count[0]?.deployed_count?.toLocaleString() : 0}</p>
                                                                            <p style={{ textTransform: "lowercase" }}>out of</p>
                                                                            <p className=''>{listblocks?.count?.total_part_count?.toLocaleString()}</p>
                                                                            <p className="pl-2">
                                                                                {listPartsAtom.map((j) => {
                                                                                    return <>
                                                                                        {j.id === listId?.construction[overallProgress]?.part_id ?
                                                                                            j?.part_name
                                                                                            : null}
                                                                                    </>
                                                                                })}
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        <div className={`flex justify-between border py-1.5 my-1.5 w-full ${selectedActivity === i ? "pb-8" : ""}`}>
                                                            {listId.construction[i]?.parent_activity_id ?
                                                                <p className='flex ml-4 max-w-[155px] w-[50%]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                                :
                                                                <p className='font-extrabold max-w-[155px] w-[50%] pl-1'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                            }
                                                            {selectedActivity !== i ?
                                                                <div className='flex justify-between w-[50%]'>
                                                                    <progress className={`progress w-[80%] mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                                    <p>{roundOff(findCPprogress(i))}%</p>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`border rounded-xl ml-6 mt-6 p-3`} style={{ width: '550px' }}>
                                <div>
                                    <div className='flex justify-between font-bold text-base'>
                                        <p>Quality Check Progress</p>
                                        <p className='text-red-550'>
                                            {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                {qcCheck() ? ttlOverAllQCPercent() : 0}%</>}
                                        </p>
                                    </div>
                                    {qcCheck() ?
                                        <div className='font-medium text-xs'>
                                            {loading ?
                                                <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                    <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                    <p className='font-medium text-sm'>Fetching...</p>
                                                </div>
                                                :
                                                finalQCArr.map((i) => {
                                                    return (
                                                        <div className='flex justify-between mt-4'>
                                                            {listId.qc[i]?.parent_activity_id ?
                                                                <p className='flex pl-4 w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                                                :
                                                                <p className='font-extrabold w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                                            }
                                                            <div className='flex justify-between' style={{ width: '215px' }}>
                                                                <progress className={`progress w-40 mt-1 ${findQCprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findQCprogress(i)} max="100"></progress>
                                                                <p>{roundOff(findQCprogress(i))}%</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '550px' }}>
                                <div>
                                    <div className='flex justify-between font-bold text-base'>
                                        <p>{handleCheckZimmerman() ? "Table Type" : "Tracker Type"}</p>
                                        <p>Quantities</p>
                                    </div>
                                    <div className='font-medium text-xs'>
                                        {loading ?
                                            <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                <p className='font-medium text-sm'>Fetching...</p>
                                            </div>
                                            :
                                            trackerSort.map((i) => {
                                                return (
                                                    <div className='flex justify-between mt-4'>
                                                        {i?.name.split("+_+").length === 2 ?
                                                            <p className='pl-4'>{i?.name.split("+_+")[0]}</p>
                                                            :
                                                            <p className='font-extrabold'>{i?.name}</p>
                                                        }
                                                        <p className='mr-1'>{i?.count}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <Modal
                    isOpen={qtyModal}
                    onAfterClose={() => setQtyModal(false)}
                    style={customDeliveryModal}
                    contentLabel='Qty Modal'
                >
                    {qtyModalLoader ?
                        <div className='flex justify-center space-x-4 items-center' style={{ height: '40vh', width: '40vw' }}>
                            <p className='loader ml-5 mb-1' style={{ width: "25px", height: "25px" }}></p>
                            <p className='font-bold text-xl'>Fetching...</p>
                        </div>
                        :
                        <div>
                            <div className='flex justify-between items-center font-bold text-xl pt-3 bg-white sticky top-0 border-none'>
                                <p className='mt-4'>
                                    {handleFindPartName(listMileStone?.qtyData?.bom_id)}
                                </p>
                                <div className='flex itmes-center mt-3 space-x-4'>
                                    {/* {editOnSiteQty ?
                                                <div className='flex space-x-4 items-center'>
                                                    <button className='bg-red-550 text-white border-none rounded-3xl btn drawer-button px-6' onClick={handleSaveDamagedQty}>Save</button>
                                                    <button className='bg-white text-gray-500 border-2 rounded-3xl btn drawer-button' onClick={() => setEditOnSiteQty(false)}>Cancel</button>
                                                </div>
                                                :
                                                <button className='bg-red-550 border-none btn rounded-full text-white px-6' onClick={() => setEditOnSiteQty(true)}>Edit</button>
                                            } */}
                                    <VscClose onClick={() => { setQtyModal(false) }} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full mt-3" style={{ width: "25px", height: '25px' }} />
                                </div>
                            </div>
                            <div className='flex flex-col space-y-2 mb-3'>
                                <p className='text-sm font-normal'>{mileStoneData?.find(o => o.bom_id === listMileStone?.qtyData?.bom_id)?.description}</p>
                            </div>
                            <div className='w-[40vw] overflow-auto' style={{ maxHeight: "60vw" }}>

                                <div className='max-h-[70vh]'>
                                    <table className='table table-compact w-full overflow-auto z-0 h-full'>
                                        <thead>
                                            <tr className='sticky z-20 top-0'>
                                                <th className='normal-case w-[39%]'>Laydown</th>
                                                <th className='normal-case w-[15%]'>Delivered Qty</th>
                                                <th className='normal-case w-[15%]'>Damaged Qty</th>
                                                <th className='normal-case w-[15%]'>Remediated Qty</th>
                                                <th className='normal-case w-[15%]'>Current Qty</th>

                                                {/* <th className='w-[15px]'></th> */}
                                            </tr>
                                        </thead>
                                        {Object.keys(listMileStone?.qtyDataArr).map((i) => {
                                            return (
                                                <tbody>
                                                    <tr>
                                                        <td>{listMileStone?.qtyDataArr[i]?.name}</td>
                                                        <td>{listMileStone?.qtyDataArr[i]?.total_received_quantity}</td>
                                                        <td>{listMileStone?.qtyDataArr[i]?.damaged_quantity}</td>
                                                        <td>{listMileStone?.qtyDataArr[i]?.remediated_quantity === null ? 0 : listMileStone?.qtyDataArr[i]?.remediated_quantity}</td>
                                                        <td>{listMileStone?.qtyDataArr[i]?.current_quantity}</td>
                                                        {/* <td>
                                                                        {nestedOnsiteQtyIds.includes(i) ?
                                                                            <BsChevronUp className='cursor-pointer' onClick={() => handleOpenCloseNestedTable(i)} />
                                                                            :
                                                                            <BsChevronDown className='cursor-pointer' onClick={() => handleOpenCloseNestedTable(i)} />
                                                                        }
                                                                    </td> */}
                                                    </tr>
                                                    {/* {nestedOnsiteQtyIds.includes(i) ?
                                                                    <td colSpan="4">
                                                                        <table className='table table-compact w-full overflow-auto z-0 h-full'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='normal-case bg-red-200 w-[50%]'>Delivery Reference</th>
                                                                                    <th className='normal-case bg-red-200 w-[20%]'>Delivered Qty</th>
                                                                                    <th className='normal-case bg-red-200 w-[20%]'>Damaged Qty</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {onSiteData.map((deliveryData, index) => {
                                                                                    if (deliveryData?.laydown_id === parseInt(i)) {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{deliveryData?.delivery_name}</td>
                                                                                                <td>{deliveryData?.count}</td>
                                                                                                <td>
                                                                                                    {editOnSiteQty ?
                                                                                                        <input className='border w-[98%]' placeholder='Damaged Qty' defaultValue={deliveryData?.damaged ? deliveryData?.damaged : 0} onChange={e => handleChangeDamagedQty(e, index)} />
                                                                                                        :
                                                                                                        <p className='border border-white'>{deliveryData?.damaged ? deliveryData?.damaged : 0}</p>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                    :
                                                                    null
                                                                } */}
                                                </tbody>
                                            )
                                        })}
                                    </table>

                                </div>

                            </div>
                        </div>}
                </Modal>
                <Modal
                    isOpen={selectedBlockModal}
                    onRequestClose={() => setSelectedBlockModal(false)}
                    style={BlocksCustomStyles}
                    contentLabel='Selected Blocks'
                >
                    <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                        <p className='mt-2'>
                            Selected Blocks
                        </p>
                        <div className='flex'>
                            <VscClose onClick={() => setSelectedBlockModal(false)} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                        </div>
                    </div>
                    <div className='relative'>
                        <div className='flex space-x-4 sticky z-30 top-[76px] bg-white'>
                            <p className='font-bold text-xl pb-2'>Total Selected Blocks</p>
                            <div className='flex space-x-5 mt-1'>
                                <p className='text-base font-medium'>-</p>
                                <p className='text-base ml-2'>{selectedBlocks?.data.length} </p>
                            </div>
                        </div>
                        <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
                            <thead>
                                <tr className='sticky z-30' style={{ top: "112px" }}>
                                    <th className='normal-case w-full'>Block Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedBlocks?.data.map((i) => {
                                    return (
                                        <tr>
                                            <td>{listblocks?.blocks?.find(o => o?.id === i)?.name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Modal>
            </div>
        </Container>
    )
}

export default BlockList