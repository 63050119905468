import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useAtom } from 'jotai'
import Modal from 'react-modal';
import qcAtom, { createUpdateDeleteQcChecklist, fetchChecklist, fetchTypeTracker, } from '../../../atoms/QcChecklistAtom/qcAtom'
import toast from 'react-hot-toast'
import { unstable_usePrompt } from 'react-router-dom/dist';
import { RiEdit2Fill } from 'react-icons/ri';
import MultipleValueTextInput from 'react-multivalue-text-input'
import { toLower } from 'lodash';
import { create_update_checklist } from '../../../dal/qcCheckpoints/qc-dal';

function Tab({ currentTab, text, editMode, onClick }) {
    return (
        <div className='w-1/3 text-center h-full'>
            {editMode ?
                <p className={`text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`}>{text}</p>
                :
                <p className={`cursor-pointer text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`} onClick={() => onClick(text)}>{text}</p>
            }
        </div>
    )
}

function QcChecklists() {


    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState({ boolean: false, type: null })
    const [checklistLoading, setChecklistoading] = useState({ boolean: false, data: "Fetching..." })
    const [editMode, setEditMode] = useState(false)
    const [checklistData, setChecklistData] = useState({ tab: "Piers", status: "", type: 1, lable: "", checklist_id: null, design_pier_ht: "", custom_inputs: [], checkpoints: [{ name: "", acceptance_criteria: "", inspection_type: "" }] })
    const [dropdownModal, setDropdownModal] = useState({ boolean: false, index: null, values: { values: [] } })
    const [fieldConfModal, SetFieldConfModal] = useState({ boolean: false, index: null, changedValue: null })

    const [listQc] = useAtom(qcAtom)
    const [, setMapType] = useAtom(fetchTypeTracker);
    const [, setFetchChecklist] = useAtom(fetchChecklist);
    const [, setUpdateCreatecheckpoints] = useAtom(createUpdateDeleteQcChecklist)

    const QcTabs = ["Piers", "Structure", "Module"]

    useEffect(() => {
        init()
    }, [checklistData?.tab])

    const init = async () => {
        try {
            setLoading(true)
            await setMapType()
            setChecklistData(produce((draft) => { draft.tab = checklistData?.tab; draft.status = '0' }))
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
            setChecklistoading({ boolean: !checklistLoading?.boolean, data: "Fetching..." })
        }
    }

    unstable_usePrompt({
        when: editMode,
        message: "You are leaving the page without completing the edits, the Checkpoint will revert to last saved version"
    })

    useEffect(() => {
        const fetchChecklistFn = async (data) => {
            try {
                if (data) {
                    setChecklistoading({ boolean: true, data: "Fetching..." })
                    await setFetchChecklist({ checklistId: data })
                }
                else {
                    setChecklistData(produce((draft) => {
                        draft.id = null;
                        draft.checkpoints = [{ name: "", acceptance_criteria: "", inspection_type: "" }];
                        draft.custom_inputs = []
                    }))
                }
            } catch (err) {
                console.log(err)
            } finally {
                setChecklistoading({ boolean: false, data: "Fetching..." })
            }
        }

        if (listQc?.mappedChecklist) {
            const value = toLower(checklistData?.tab)
            fetchChecklistFn(listQc?.mappedChecklist?.[`${value}_${checklistData?.type}`])
        }

    }, [listQc?.mappedChecklist, checklistData?.tab, checklistData?.type])

    useEffect(() => {
        if (listQc?.checklistDeatils) {
            const sortedCustomInputs = [...(listQc?.checklistDeatils?.custom_inputs || [])].sort((a, b) => a.id - b.id);
            const temp = {
                tab: checklistData?.tab,
                name: listQc?.checklistDeatils?.name,
                custom_inputs: sortedCustomInputs,
                checkpoints: listQc?.checklistDeatils?.checkpoints,
                id: listQc?.checklistDeatils?.id,
                type: checklistData?.type
            }
            setChecklistData(temp)
        }

    }, [listQc?.checklistDeatils, checklistData?.tab])

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleCancel = () => {
        setEditMode(false)
    }

    const handleToggle = (data) => {
        if (!editMode) {
            setChecklistData(produce((draft) => { draft.type = parseInt(data) }))
        }
    }

    const handleSaveManageChecklist = () => {
        if (checklistData?.type === 0) {
            onChecklistSubmit(false)
        }
        if (checklistData?.type === 1) {
            onChecklistSubmit(true)
        }
    }

    const onChecklistSubmit = async (itrValue) => {
        try {
            setBtnLoader(({ boolean: true, type: itrValue ? "ITR" : "save" }))
            await setUpdateCreatecheckpoints({ data: create_update_checklist(checklistData), tab: toLower(checklistData?.tab), itr: itrValue, projectLevel: true })
            toast.success("Updated Checklist")
            setEditMode(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to update Checklist")
        }
        finally {
            setBtnLoader(({ boolean: false, type: null }))
        }
    }

    const handleAddCheckPoint = () => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.checkpoints.push({ name: "", acceptance_criteria: "", inspection_type: "" }) }))
        }
    }

    const handleRemoveCheckpoint = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.checkpoints[index]["delete"] = true }))
        }
    }

    const handleAddCustom = () => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                draft.custom_inputs.push({
                    type: 0,
                    values: {},
                    name: "Custom Label"
                })
            }))
        }
    }

    const handleDeleteCustomFields = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                draft.custom_inputs[index]["delete"] = true
            }))
        }
    }

    const handleCheckCountCustomFields = () => {
        let temp = []
        checklistData?.custom_inputs?.length && checklistData?.custom_inputs.map((i) => {
            if (!i?.delete) {
                temp.push(i)
            }
        })
        return temp
    }

    const handleChangeFields = (e, index) => {
        if (parseInt(e.target.value) === 3) {
            setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.values?.values ? checklistData.custom_inputs[index]?.values?.values : [] } })
        }
        setChecklistData(produce((draft) => { draft.custom_inputs[index].type = parseInt(e.target.value) }))
    }

    const handleFieldConfirmCancel = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].type = 3 }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    const handleFieldConfirm = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].type = fieldConfModal.changedValue }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    const handleMultiInputSubmit = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[dropdownModal.index].values = dropdownModal.values }))
        setDropdownModal({ boolean: false, index: null, values: { values: [] } })
    }

    if (loading.boolean || checklistLoading.boolean) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full' style={{ height: "calc(100vh - 200px)" }}>
                <p className='loader mb-1'></p>
                <p className='font-bold text-lg ml-3'>Fetching...</p>
            </div>
        )
    }

    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center border-b-2'>
                <div className='flex space-x-4 ml-4 items-center'>
                    <div className={`flex text-sm cursor-pointer border rounded-full font-medium p-1 ${!editMode ? "border-gray-100 bg-gray-50" : "border-red-100 bg-red-50"} mr-4`}>
                        <div className={`flex ${!checklistData.type ? "" : `${editMode ? "bg-gray-500" : "bg-red-550"} border-none rounded-full`}`} onClick={() => handleToggle(1)}>
                            <p className={`${!checklistData.type ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} >ITR Checklist</p>
                        </div>
                        <div className={`flex ${!checklistData.type ? `${editMode ? "bg-gray-500" : "bg-red-550"} border-none rounded-full` : ""}`} onClick={() => handleToggle(0)}>
                            <p className={`${!checklistData.type ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} >Template Checklist</p>
                        </div>
                    </div>
                </div>
                <div className='py-3 flex items-center space-x-5 px-5'>
                    {checklistData?.type === 1 ?
                        <p className='text-gray-500 font-medium text-base flex items-center'>
                            <AiOutlineInfoCircle className='mr-2' />
                            Note - This will be set as ITR Checklist
                        </p>
                        :
                        null
                    }
                    {editMode ?
                        <div className='flex space-x-5'>
                            <button className='border-none flex space-x-3 items-center btn bg-red-550 text-white rounded-full px-4' onClick={() => handleSaveManageChecklist(true)}>
                                {btnLoader?.boolean ?
                                    <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                    :
                                    null
                                }
                                {checklistData?.type === 1 ?
                                    <p>Save</p>
                                    :
                                    <div className='text-xs px-1'>
                                        <p>Save and Apply to all {checklistData?.tab === QcTabs[0] ? "Piers" : "Bays"}</p>
                                        <p>across all Tracker types</p>
                                    </div>
                                }
                            </button>
                            <button className='border btn border-2 cursor-pointer px-4 rounded-full bg-white text-gray-500 hover:text-white' onClick={() => handleCancel()}>Cancel</button>
                        </div>
                        :
                        <button className='border btn border-2 cursor-pointer px-5 rounded-full bg-white text-gray-500 hover:text-white' onClick={() => setEditMode(true)}>Edit</button>
                    }
                </div>
            </div>
            <div className={`flex justify-around border-b w-full h-[40px]`}>
                {QcTabs.map((tab) => {
                    return (
                        <Tab currentTab={checklistData?.tab} text={tab} editMode={editMode} onClick={e => {
                            if (!editMode) {
                                setChecklistData(produce((draft) => { draft.tab = e }))
                            }
                        }} />
                    )
                })}
            </div>
            <div className={`w-full overflow-auto border ${editMode ? "" : "bg-gray-100 cursor-auto"}`} style={{ height: "calc(100vh - 195px)" }}>
                <div className='w-full'>
                    <div className='mt-7'>
                        {checklistData?.custom_inputs?.length > 0 && checklistData?.custom_inputs.map((i, index) => {
                            if (!i?.delete) {
                                return (
                                    <div className='flex space-x-8 px-5 items-center space-y-3'>
                                        <div className='w-[50%] flex items-center space-x-8 space-y-3 justify-between'>
                                            <div className='flex space-x-2 items-center w-[45%]'>
                                                <input className={`text-base font-medium border-2 rounded-xl px-4 py-2 mt-2 flex items-center w-full focus:outline-none ${!editMode && "cursor-pointer"}`} disabled={!editMode} value={i?.name} name="label_01" onChange={e => setChecklistData(produce((draft) => { draft.custom_inputs[index].name = e.target.value }))} />
                                                <RiEdit2Fill className='text-xl mt-1' />
                                            </div>
                                            <select required disabled={!editMode} className={`px-4 py-2 rounded-xl select-bordered border-2 w-[40%] ${editMode ? "cursor-pointer" : "bg-gray-100"}`} onChange={e => handleChangeFields(e, index)}>
                                                <option selected={checklistData.custom_inputs[index]?.type === 0} value={0}>Integer</option>
                                                <option selected={checklistData.custom_inputs[index]?.type === 1} value={1}>Float</option>
                                                <option selected={checklistData.custom_inputs[index]?.type === 2} value={2}>Free Text</option>
                                                <option selected={checklistData.custom_inputs[index]?.type === 3} value={3}>Dropdown</option>
                                            </select>
                                            <MdDelete className='cursor-pointer' onClick={() => handleDeleteCustomFields(index)} />
                                        </div>
                                        <div style={{ display: checklistData.custom_inputs[index]?.type === 3 ? "" : "none" }}>
                                            <p className={`${editMode ? "cursor-pointer underline underline-offset-2 text-blue-500" : "text-gray-500"} text-sm`} onClick={() => { editMode && setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.values ? checklistData.custom_inputs[index]?.values : [] } }) }}>View & edit dropdown options</p>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        {handleCheckCountCustomFields().length < 3 && <p className='cursor-pointer ml-5 flex w-[40%] my-3 font-semibold' onClick={handleAddCustom}>+ Add Custom Fields</p>}
                    </div>
                    <Modal
                        isOpen={fieldConfModal.boolean}
                        style={customConfirmStyle}
                        contentLabel='Type Confirmation'
                    >
                        <div className='p-2'>
                            <p className='mb-4'>The dropdown options added already will get deleted, please confirm</p>
                            <div className='flex justify-end space-x-5 font-semibold'>
                                <p className='cursor-pointer text-gray-500' onClick={handleFieldConfirmCancel}>Cancel</p>
                                <p className='cursor-pointer text-red-550' onClick={handleFieldConfirm}>Confirm</p>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={dropdownModal.boolean}
                        style={customConfirmStyle}
                        contentLabel='MultipleValueTextInput'
                    >
                        <div className='p-3 w-[500px]'>
                            <p className='font-medium text-base mb-2'>{checklistData?.custom_inputs?.[dropdownModal.index]?.name}</p>
                            <div className='flex space-x-3 items-center text-xs mb-3'>
                                <AiOutlineInfoCircle className='text-gray-400' />
                                <p className='text-gray-400'>Add required drop down options and press Enter</p>
                            </div>
                            <MultipleValueTextInput
                                onItemAdded={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                onItemDeleted={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                name="item-input"
                                className={"w-[40%] p-1 mt-5 border-b-2"}
                                placeholder=""
                                values={checklistData?.custom_inputs?.[dropdownModal.index]?.values?.values ? checklistData?.custom_inputs?.[dropdownModal.index]?.values?.values : []}
                            />
                            <div className='flex space-x-5 justify-end my-3'>
                                <p className='cursor-pointer font-medium text-gray-500' onClick={() => setDropdownModal({ boolean: false, index: null, values: { values: [] } })}>Cancel</p>
                                <button className='cursor-pointer font-medium text-red-550' onClick={handleMultiInputSubmit}>Save</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className='w-full mt-6'>
                    <div className='flex justify-between px-5 mb-2'>
                        <p className='font-bold w-[30%]'>Checkpoint</p>
                        <p className='font-bold w-[30%]'>Acceptance Criteria</p>
                        <p className='font-bold w-[30%]'>Inspection Type</p>
                        <p></p>
                    </div>
                    <hr />
                    <div className='w-full'>
                        {checklistData?.checkpoints?.length && checklistData.checkpoints.map((formData, formIndex) => {
                            if (!formData.delete) {
                                return (
                                    <div className='w-full flex justify-between px-5 my-4' key={`${formIndex}`}>
                                        <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' value={formData.name} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.checkpoints[formIndex].name = e.target.value }))} />
                                        <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' value={formData.acceptance_criteria} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.checkpoints[formIndex].acceptance_criteria = e.target.value }))} />
                                        <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' value={formData.inspection_type} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.checkpoints[formIndex].inspection_type = e.target.value }))} />
                                        {/* <p className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)}>D</p> */}
                                        <p><MdDelete className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)} /></p>
                                    </div>
                                )
                            }
                        })}
                        <p className='cursor-pointer mx-5 text-base font-bold' onClick={handleAddCheckPoint}>+ Add More</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QcChecklists
