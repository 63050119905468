import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import projectAtom, { fetchProjectAtom } from '../atoms/projectAtom'
import userAtom from '../atoms/userAtom'

function ProjectProvider(props) {

    const [loading, setLoading] = useState(false)
    const [projects] = useAtom(projectAtom);
    const [user] = useAtom(userAtom);
    const [, fetchProject] = useAtom(fetchProjectAtom)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            if (user.loggedIn) {
                await fetchProject()
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    // useEffect (() => {
    //     if(projects.length > 0 && !localStorage.getItem("project_id")) {
    //         localStorage.setItem("project_id",projects[0].id)
    //     }
    // },[projects])

    if (loading) {
        return <p className='loader m-auto' style={{ marginTop: '22vw' }}></p>
    }

    if (projects.length < 1 && user.loggedIn) {
        return (
            <div className="w-full h-full">
                <div className="w-full h-[100%] flex flex-col">
                    <div className="flex justify-between border h-[9%]">
                        <div className="flex">
                            <div className="flex justify-center py-4 ml-5">
                                <img src="/suryalogo.svg" alt="" style={{ width: '175px' }} />
                            </div>
                            {/* <div className="flex gap-12 px-4 pl-12">
                                {!(localStorage.getItem("project_id")) ? null : loading ?
                                    <p className='w-4 h-4 b-3 loader' style={{ width: "25px", height: "25px", margin: "20px" }}></p> :
                                    mainNavBar.map((item, index) => (
                                        !getPermission[permission_reference[item?.title]] ? null :
                                            <Tab
                                                key={index}
                                                name={item?.title}
                                                to={item.path}
                                                logoDefault={item?.default_icon}
                                                logoClicked={item?.icon}
                                                selected={startCase((location.pathname).split('/')?.[1]) === item?.title} />
                                    ))
                                }
                            </div> */}
                        </div>
                        <div className="flex space-x-5 justify-end items-center mr-4">
                            {/* <ProjectSelect onChange={e => handleChangeProject(e)} value={listProjects} /> */}
                            <div className="dropdown dropdown-bottom dropdown-end mr-10 ml-6 ">
                                <label tabIndex={0} className="cursor-pointer">
                                    <div className="avatar placeholder">
                                        <div className=" bg-red-550 text-neutral-content rounded-full w-10">
                                            <span className="text-base font-semibold">{user?.data?.profile_data?.first_name.charAt(0).toUpperCase()}</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="m-auto flex flex-col space-y-3 justify-center font-semibold text-gray-400" style={{ marginTop: "20%" }}>
                        <BsFillFileEarmarkImageFill className='text-6xl text-gray-300 m-auto' />
                        <p>No project available</p>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div>{props.children}</div>
    )
}

export default ProjectProvider
